import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@mui/material/TextField";
import {InputAdornment, MenuItem, Select} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import axios from "axios";
import {Pagination} from "@mui/lab";
import CircularProgress from "@mui/material/CircularProgress";
import {ShippingOrderListLTL} from "./ShippingOrderListLTL";
import intl from "react-intl-universal";
import {ShippingOrderList} from "./ShippingOrderList";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import {PAGE_SIZE} from "../../../Utils/consInfo";
import {LatestMovingOrderTable} from "../../LatestShippingOrder/LatestMovingOrderTable";

const styles = {
    ShippingOrderRoot: {
        gap: '15px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingOrderContent: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #D1D1D1',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
    },
}

const useStyles = makeStyles({
    customTextField: {
        "& input::placeholder": {
            fontSize: "14px"
        }
    }
});

export const ShippingOrder = ({shippingOrderData}) => {

    const storedToken = getAccessToken("access_token");

    const {firstname, id, isLoading} = useSelector((state) => state.user);

    const [type, setType] = useState('package');
    const [searchString, setSearchString] = useState(null);
    const [orderData, setOrderData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const classes = useStyles();

    const handleChange = (event) => {
        console.log(event.target.value);
        setType(event.target.value);
    };

    const handleSearchString = (event) => {
        setSearchString(event.target.value);
    }

    const getPackageOrderList = async () => {
        setLoading(true);
        // const requestURL = `${PARTNER_URI}/elasticsearch/shippingPackageOrder/searchShippingPackageOrder`;
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingPackageOrder/searchBackendPackageOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || "",
                    page: 0,
                    pageSize: PAGE_SIZE,
                    statusArray: [0, 1, 200, 2, 3]
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getPackageOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingPackageOrder/searchBackendPackageOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || "",
                    page: page,
                    pageSize: PAGE_SIZE,
                    statusArray: [0, 1, 200, 2, 3]
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getLTLOrderList = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingLTL/searchShippingLTLOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || '',
                    page: 0,
                    pageSize: PAGE_SIZE,
                }
            });
            console.log('[shipping order list ltl] - result', result);
            setOrderData(result?.data?.result);
            setCurrentPage(result?.data?.pagination?.currentPage + 1);
            setLastPage(result?.data?.pagination?.lastPage + 1);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getLTLOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/elasticsearch/shippingLTL/searchShippingLTLOrder`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString || '',
                    page: page,
                    pageSize: PAGE_SIZE,
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.result);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const getMovingOrderList = async () => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/movingService/getMovingOrderList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString,
                    page: 0,
                    pageSize: 20,
                    statusId: [0, 100, 1],
                    // shippingStatusId
                }
            });
            console.log('[getMovingOrderList] - result', result);
            setOrderData(result?.data?.data);
            setError(false);
            setCurrentPage(result?.data?.pagination?.currentPage);
            setLastPage(result?.data?.pagination?.lastPage);
        } catch (e) {
            console.log('error', e?.response);
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    const getMovingOrderListByPage = async (page) => {
        setLoading(true);
        const requestURL = `${PARTNER_URI}/movingService/getMovingOrderList`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    queryString: searchString,
                    page: page,
                    pageSize: 20,
                    statusId: [0, 100, 1],
                    // shippingStatusId
                }
            });
            console.log('[shipping order list package] - result', result);
            setOrderData(result?.data?.data);
            setError(false);
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            setError(true);
            setLoading(false);
        }
    }

    const handleSearchShippingOrder = () => {
        // setOrderData([]);
        if (type === 'package') {
            getPackageOrderList();
        } else if (type === 'ltl') {
            getLTLOrderList();
        } else {
            getMovingOrderList();
        }
    }

    const handleSearchShippingOrderByKeyDown = (event) => {
        if (event.key === 'Enter') {
            // setOrderData([]);
            if (type === 'package') {
                getPackageOrderList();
            } else if (type === 'ltl') {
                getLTLOrderList();
            } else {
                getMovingOrderList();
            }
        }
    }

    const handlePageChange = (event, value) => {
        console.log('page change', value);
        setCurrentPage(value);
        const page = value;
        if (type === 'package') {
            getPackageOrderListByPage(page);
        } else if (type === 'ltl') {
            getLTLOrderListByPage(page - 1);
        } else {
            getMovingOrderListByPage(page);
        }
    };

    useEffect(() => {
        if (type === 'package') {
            if (id) {
                (async () => {
                    await getPackageOrderList();
                })()
            }
        } else if (type === 'ltl') {
            (async () => {
                await getLTLOrderList();
            })()
        } else {
            (async () => {
                await getMovingOrderList();
            })()
        }
    }, [id, type])

    return (
        <Box sx={styles.ShippingOrderRoot}>
            <Typography style={{fontSize: '16px', fontWeight: 600, textAlign: 'left'}}>
                {intl.get('HOME_PAGE.SHIPPING_ORDER.SHIPPING_ORDER')}
            </Typography>
            <Box sx={styles.ShippingOrderContent}>
                <Grid container spacing={2}>
                    <Grid item md={10} xs={12}>
                        <Grid container spacing={0}>
                            <Grid item md={10} xs={8}>
                                <TextField
                                    value={searchString}
                                    fullWidth
                                    size='small'
                                    onInput={handleSearchString}
                                    onKeyDown={handleSearchShippingOrderByKeyDown}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                    classes={{root: classes.customTextField}}
                                    // label='Order# / REF# / Tracking# / Name / Email / Address / Phone'
                                    placeholder={intl.get('HOME_PAGE.SHIPPING_ORDER.SEARCH_PLACEHOLDER')}
                                />
                            </Grid>
                            <Grid item md={2} xs={4}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                }}>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: '#1D8B45',
                                            height: '100%',
                                            "&:hover": {
                                                backgroundColor: '#1D8B45',
                                                filter: 'brightness(0.9)'
                                            },
                                        }}
                                        // size='small'
                                        onClick={handleSearchShippingOrder}
                                    >
                                        <Typography
                                            style={{textTransform: 'none', fontSize: '16px'}}
                                        >
                                            {intl.get('HOME_PAGE.SHIPPING_ORDER.SEARCH')}
                                        </Typography>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <FormControl fullWidth>
                            <Select
                                value={type}
                                size='small'
                                placeholder='Status'
                                onChange={handleChange}
                            >
                                <MenuItem disabled value='type' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>
                                    <em>{intl.get('HOME_PAGE.SHIPPING_ORDER.TYPE')}</em>
                                </MenuItem>
                                <MenuItem value='package' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('HOME_PAGE.SHIPPING_ORDER.PACKAGE')}</MenuItem>
                                <MenuItem value='ltl' sx={{
                                    fontSize: '14px',    // Adjust the desired text size
                                }}>{intl.get('HOME_PAGE.SHIPPING_ORDER.LTL')}</MenuItem>
                                {/*<MenuItem value='transportation' sx={{*/}
                                {/*    fontSize: '14px',    // Adjust the desired text size*/}
                                {/*}}>Moving</MenuItem>*/}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    loading ?
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <CircularProgress/>
                        </Box>
                        :
                        error ?
                            <Box sx={styles.ShippingOrderContent}>
                                <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                    {intl.get('HOME_PAGE.ERROR')}
                                </Typography>
                            </Box>
                            :
                            orderData?.length === 0 ?
                                <Box sx={styles.ShippingOrderContent}>
                                    <Typography sx={{fontSize: '16px', fontWeight: '600'}}>
                                        {intl.get('HOME_PAGE.SHIPPING_ORDER.NO_RESULTS')}
                                    </Typography>
                                </Box>
                                :
                                type === 'package' ?
                                    <Box sx={styles.ShippingOrderContent}>
                                        <ShippingOrderList order={orderData}/>
                                        <Pagination
                                            count={lastPage}
                                            page={+currentPage}
                                            size='small'
                                            // defaultPage={6}
                                            onChange={handlePageChange}
                                            // boundaryCount={2}
                                        />
                                    </Box>
                                    :
                                    type === 'ltl' ?
                                        <Box sx={styles.ShippingOrderContent}>
                                            <ShippingOrderListLTL order={orderData}/>
                                            <Pagination
                                                count={lastPage}
                                                page={+currentPage}
                                                size='small'
                                                // defaultPage={6}
                                                onChange={handlePageChange}
                                                // boundaryCount={2}
                                            />
                                        </Box>
                                        :
                                        <Box sx={styles.ShippingOrderContent}>
                                            <LatestMovingOrderTable order={orderData}
                                                                    getMovingOrderList={getMovingOrderList}/>
                                            <Pagination
                                                count={lastPage}
                                                page={+currentPage}
                                                size='small'
                                                // defaultPage={6}
                                                onChange={handlePageChange}
                                                // boundaryCount={2}
                                            />
                                        </Box>

                }
            </Box>
        </Box>
    )
}