import {
    Button,
    Container,
    Divider,
    IconButton,
    MenuItem,
    Select,
    Snackbar,
    Step,
    StepButton,
    StepLabel,
    Stepper
} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import {ShippingPackageCreateLabelPackage} from "./ShippingPackageCreateLabelPackage";
import {useDispatch, useSelector} from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useHistory} from "react-router-dom";
import {
    clearCreateLabelShippingPackageRateList,
    isCallAllPackageCarrier,
    updateCreateLabelShippingPackageCrossBorder,
    updateCreateLabelShippingPackageRateList,
    updateCreateLabelShippingPackageReferenceOrderNumber,
    updateCreateLabelShippingPackageShipFrom,
    updateCreateLabelShippingPackageShipTo,
    updateCreateLabelShippingPackageValidation, updateShippingPackageReturnLabelStatus
} from "../../actions/shippingPackageAction";
import {ShippingPackageCreateLabelShipFrom} from "./ShippingPackageCreateLabelShipFrom";
import {ShippingPackageCreateLabelShipTo} from "./ShippingPackageCreateLabelShipTo";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import AccordionSummary from "@mui/material/AccordionSummary";
import HelpIcon from "@material-ui/icons/Help";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControl from "@mui/material/FormControl";
import {
    filterRateList,
    getMissingKeysForCreateLabelLocation, getMissingKeysFromObject,
    internationalShipmentTypeList, isValidatePhone,
    transportationList
} from "../../Utils/Helper";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {ShippingPackageCreateLabelShippingOptions} from "./ShippingPackageCreateLabelShippingOptions";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {ReactComponent as USAIcon} from "../../images/homepageIcon/USA.svg";
import {ReactComponent as CanadaIcon} from "../../images/homepageIcon/CA.svg";
import PublicIcon from "@mui/icons-material/Public";
import FormControlLabel from "@mui/material/FormControlLabel";
import {ReactComponent as NorthAmericanIcon} from "../../images/icons/Canada&USA.svg";
import {ReactComponent as InternationalIcon} from "../../images/icons/International.svg";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../Utils/CustomComponent";
import {ReactComponent as SchedulePickupIcon} from "../../images/NewDesignIcon/SchedulePickup.svg";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import intl from "react-intl-universal";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import {defaultText, green, light_orange, orange} from "../../Utils/styleConfig";
import {VALIDATION_PROVIDER_ALL} from "../../Utils/config";
import WarningIcon from "@mui/icons-material/Warning";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0 100px'
    },
    ShippingPackageCreateLabelRow: {
        display: 'flex',
        alignItems: 'center'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelRowRight: {
        display: 'flex',
        justifyContent: 'flex-end'
        // justifyContent: 'flex-end',
    },
    ShippingPackageCreateLabelSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        // border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelContent: {
        backgroundColor: '#FFFFFF',
        padding: '0 40px',
        marginBottom: '40px',
        borderRadius: '10px',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelContainer: {
        backgroundColor: '#FFFFFF',
        // padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0',
        minHeight: '40vh'
    },
    ShippingPackageCreateLabelHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
}

const requiredProperties = ["description", "length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight", "quantity"];
const crossRequiredProperties = ["UOM", "currency", "priceEach", "hscode", "originCountryCode"];

export const ShippingPackageCreateLabel = (props) => {

    const shipFromHistory = props?.location?.state?.shipFrom;
    const shipToHistory = props?.location?.state?.shipTo;
    const packageListHistory = props?.location?.state?.packageList;
    const batteryDetailsHistory = props?.location?.state?.batteryDetails;
    const signatureOptionsHistory = props?.location?.state?.signatureOptions;

    const dispatch = useDispatch();
    const history = useHistory();
    const {id: partnerId, email} = useSelector((state) => state.user);
    const token = getAccessToken("access_token");

    let signatureOptions = useSelector(state => state?.shippingPackage?.createLabelSignatureOptions);
    let batteryDetails = useSelector(state => state?.shippingPackage?.createLabelBatteryDetails);
    let packageListRedux = useSelector(state => state?.shippingPackage?.createLabelPackageList);
    let referenceOrderNumberRedux = useSelector(state => state?.shippingPackage?.createLabelReferenceOrderNumber);
    let returnLabelRedux = useSelector(state => state?.shippingPackage?.returnLabel);
    let shipFrom = useSelector(state => state?.shippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.createLabelShipTo);
    const isMobile = useMediaQuery('(max-width: 600px)');

    const [accordionOpen, setAccordionOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [crossBorder, setCrossBorder] = useState({
        tranMode: "INT_TRUCK",
        shipmentType: "COMMERCIAL",
    });

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [referenceOrderNumber, setReferenceOrderNumber] = useState('');
    // const [isCalledAllCarrier, setIsCalledAllCarrier] = useState(0);
    const [addressVerifyDialog, setAddressVerifyDialog] = useState(false);
    const [addressErrorMessageShipFrom, setAddressErrorMessageShiFrom] = useState('');
    const [addressErrorMessageShipTo, setAddressErrorMessageShipTo] = useState('');
    const [verifyAddressShipFrom, setVerifyAddressShipFrom] = useState(null);
    const [verifyAddressShipTo, setVerifyAddressShipTo] = useState(null);
    const [shipFromOriginal, setShipFromOriginal] = useState();
    const [shipToOriginal, setShipToOriginal] = useState();
    const [returnLabel, setReturnLabel] = useState(false);
    const [refError, setRefError] = useState(false);
    const [recommendEmail, setRecommendEmail] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [swapShipFrom, setSwapShipFrom] = useState(null);
    const [swapShipTo, setSwapShipTo] = useState(null);
    const [shipFromAddressValidation, setShipFromAddressValidation] = useState(null);
    const [shipToAddressValidation, setShipToAddressValidation] = useState(null);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const toggleOpen = () => {
        setAccordionOpen(!accordionOpen);
    }

    const handleTranMode = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            tranMode: event.target.value
        }))
    }

    const handleShipmentType = (event) => {
        setCrossBorder(prevState => ({
            ...prevState,
            shipmentType: event.target.value
        }))
    }

    const getAllPackageAccountBaseList = async () => {
        let requestURL = `${NODE_ROUTE_URI}/looseItem/getAllPackageAccountBaseList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            console.log('[getAllPackageAccountBaseList] result', result);

            return result?.data;

        } catch (e) {
            console.log('error', e.response);
        }
    }

    const handleCallCarrier = (availableService) => {
        if (availableService?.length > 0) {
            for (const service of availableService) {
                getShippingPackageRate(service?.account_base);
            }
        } else {
            history.push('/shipping-package/create-label/rate-list');
        }
    }

    const handleCallCarrierRecommend = (availableService) => {
        if (availableService?.length > 0) {
            for (const service of availableService) {
                getShippingPackageRateRecommend(service?.account_base);
            }
        } else {
            history.push('/shipping-package/create-label/rate-list');
        }
    }

    const handleReferenceOrderNumber = (event) => {
        const value = event?.target?.value;
        const regex = /^[\w\s\-\(\)\._]{1,30}$/;

        if (regex.test(value) || value === '') {
            setReferenceOrderNumber(event?.target?.value);
            dispatch(updateCreateLabelShippingPackageReferenceOrderNumber(event?.target?.value));
            setRefError(false);
        } else {
            setRefError(true)
        }
    }

    const handleReturnLabel = event => {
        setReturnLabel(event?.target?.checked);
        dispatch(updateShippingPackageReturnLabelStatus(event?.target?.checked));
    }

    const CrossBorderHasMissingValues = () => {

        if (shipFrom?.country === shipTo?.country) {
            return false
        } else {
            const result = packageListRedux.some(object => crossRequiredProperties.some((property) => !object[property]));
            console.log('result', result);
            return result
        }
    }

    const getShippingPackageRate = async (selectedAccountBase) => {
        setLoading(true);

        let requestURL = `${NODE_ROUTE_URI}/looseItem/ratesByAccountBase`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            rateData: {
                shipFromAddress: shipFrom?.displayAddress?.text,
                shipFromAddressTwo: shipFrom?.address2,
                shipFromCity: shipFrom?.city,
                shipFromProvince: shipFrom?.province?.code,
                shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: shipFrom?.country,
                shipFromName: shipFrom?.name,
                shipFromPhone: shipFrom?.phone?.replace(/\D/g, ''),
                shipToAddress: shipTo?.displayAddress?.text,
                shipToAddressTwo: shipTo?.address2,
                shipToCity: shipTo?.city,
                shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: shipTo?.province?.code,
                shipToCountry: shipTo?.country,
                shipToName: shipTo?.name,
                shipToPhone: shipTo?.phone?.replace(/\D/g, ''),
                packageData: packageData,
                shipmentSpecialServices: {
                    returnShipment: returnLabel
                },
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            const rateData = result?.data?.result;
            console.log('getShippingPackageRate rateData', rateData);
            if (rateData?.length > 0) {

                const updatedRateData = rateData.map((each) => ({
                    ...each,
                    errorAccountBase: selectedAccountBase?.toUpperCase()
                }));

                setLoading(false);

                console.log('[getShippingPackageRate] updatedRateData', updatedRateData);

                const filteredRateData = filterRateList(updatedRateData);

                console.log('[getShippingPackageRate] filteredRateData', filteredRateData);

                dispatch(updateCreateLabelShippingPackageRateList(filteredRateData));

                history.push('/shipping-package/create-label/rate-list');

                setErrorMessage('');
            }

        } catch (e) {
            setLoading(false);
            console.log('error', e.response);
            if (e.response.status === 403) {
                setErrorMessage(`${e.response.data.error}. Please contact op@uucargo.ca.`);
            } else {
                setErrorMessage('Fail to get rate.');
            }
            handleErrorMessage();
        } finally {
            dispatch(isCallAllPackageCarrier(selectedAccountBase));
        }
    }

    const getShippingPackageRateRecommend = async (selectedAccountBase) => {
        setLoading(true);

        let requestURL = `${NODE_ROUTE_URI}/looseItem/ratesByAccountBase`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            rateData: {
                shipFromAddress: shipFromAddressValidation?.validateAddress?.address || shipFrom?.displayAddress?.text,
                shipFromAddressTwo: shipFromAddressValidation?.validateAddress?.address2 || shipFrom?.address2,
                shipFromCity: shipFromAddressValidation?.validateAddress?.city || shipFrom?.city,
                shipFromProvince: shipFromAddressValidation?.validateAddress?.province || shipFrom?.province?.code,
                shipFromPostalCode: shipFromAddressValidation?.validateAddress?.postalCode.replace(/\s+/g, '').toUpperCase() || shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: shipFromAddressValidation?.validateAddress?.country || shipFrom?.country,
                shipFromName: shipFrom?.name,
                shipFromPhone: shipFrom?.phone,
                shipToAddress: shipToAddressValidation?.validateAddress?.address || shipTo?.displayAddress?.text,
                shipToAddressTwo: shipToAddressValidation?.validateAddress?.address2 || shipTo?.address2,
                shipToCity: shipToAddressValidation?.validateAddress?.city || shipTo?.city,
                shipToPostalCode: shipToAddressValidation?.validateAddress?.postalCode.replace(/\s+/g, '').toUpperCase() || shipTo?.postalCode?.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: shipToAddressValidation?.validateAddress?.province || shipTo?.province?.code,
                shipToCountry: shipToAddressValidation?.validateAddress?.country || shipTo?.country,
                shipToName: shipTo?.name,
                shipToPhone: shipTo?.phone,
                packageData: packageData,
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            const rateData = result?.data?.result;
            console.log('rate data', rateData);

            if (rateData?.length > 0) {

                const updatedRateData = rateData.map((each) => ({
                    ...each,
                    errorAccountBase: selectedAccountBase?.toUpperCase()
                }));

                // setRate(prevState => [...prevState, ...rateData]);
                setLoading(false);
                // setIsGetRate(true);
                // setRate(prevState => [...prevState, ...rateData]);

                console.log('[getShippingPackageRate] updatedRateData', updatedRateData);

                const filteredRateData = filterRateList(updatedRateData);

                console.log('[getShippingPackageRate] filteredRateData', filteredRateData);

                dispatch(updateCreateLabelShippingPackageRateList(filteredRateData));

                history.push('/shipping-package/create-label/rate-list');

                setErrorMessage('');
            }
        } catch (e) {
            setLoading(false);
            console.log('error', e.response);
            if (e.response.status === 403) {
                setErrorMessage(`${e.response.data.error}. Please contact op@uucargo.ca.`);
            } else {
                setErrorMessage('Fail to get rate.')
            }
            handleErrorMessage();
        } finally {
            dispatch(isCallAllPackageCarrier(selectedAccountBase));
        }
    }

    const addAddressShipFrom = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/sender`;
        let data = {
            address: shipFrom?.displayAddress?.text,
            address_2: shipFrom?.address2,
            city: shipFrom?.city,
            email: shipFrom?.email,
            province: shipFrom?.province?.code,
            postalCode: shipFrom?.postalCode,
            country: shipFrom?.country,
            phone: shipFrom?.phone,
            name: shipFrom?.name,
            addressEmail: shipFrom?.email,
            companyName: shipFrom?.company,
            senderOrReceiver: "SENDER",
            ownerRole: "1"
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            console.log(result);
            console.log('successfully add ship from address')
        } catch (e) {
            console.log(e);
        }
    }

    const addAddressShipTo = async () => {
        let requestURL = `${NODE_ROUTE_URI}/addressBookEndUser/endUser/addressbooks/deliver`;
        let data = {
            address: shipTo?.displayAddress?.text,
            address_2: shipTo?.address2,
            city: shipTo?.city,
            email: shipTo?.email,
            province: shipTo?.province?.code,
            postalCode: shipTo?.postalCode,
            country: shipTo?.country,
            phone: shipTo?.phone,
            name: shipTo?.name,
            addressEmail: shipTo?.email,
            companyName: shipTo?.company,
            senderOrReceiver: "RECEIVER",
            ownerRole: "1"
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            console.log(result);
            console.log('successfully add ship to address')
        } catch (e) {
            console.log(e);
        }
    }

    const verifyAddress = async (address, address2, city, province, country, postalCode) => {
        setLoading(true);
        let requestURL = `${NODE_ROUTE_URI}/verifyAddress/validateAddressByGoogleMapsAndFedExValidation`;
        let data = {
            address,
            address2,
            city,
            province,
            postalCode,
            country,
            validationProvider: VALIDATION_PROVIDER_ALL
        }
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            console.log('[verifyAddress] data', result?.data);
            return result?.data
        } catch (e) {
            console.log('error', e?.response);
            return {
                validationCode: 404,
                validateAddress: null,
                originalAddress: null
            }
        } finally {
            setLoading(false);
        }
    }

    const validateCityProvinceAndCountry = async (city, province, country) => {
        if (!city || !province || !country) {
            return {
                location: [],
                validation: false
            }
        }
        let requestURL = `${NODE_ROUTE_URI}/maps_apis/validateCityProvinceCountry`;
        let data = {
            city: city,
            province: province,
            country: country
        }
        console.log('[validateCityProvinceAndCountry] city', city);
        console.log('[validateCityProvinceAndCountry] province', province);
        console.log('[validateCityProvinceAndCountry] country', country);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data,
            });
            console.log('[validateCityProvinceAndCountry] result', result);
            const validationResult = result?.data;
            return validationResult;
        } catch (e) {
            console.log('error', e?.response);
            return true;
        }
    }

    const validate = async () => {
        setErrorMessage('');
        setToastOpen(false);

        // const validateShippingFromCityProvinceAndCountry = await validateCityProvinceAndCountry(shipFrom?.city, shipFrom?.province?.code, shipFrom?.country);
        // const validateShippingToCityProvinceAndCountry = await validateCityProvinceAndCountry(shipTo?.city, shipTo?.province?.code, shipTo?.country);
        // console.log('[validate] validateShippingFromCityProvinceAndCountry', validateShippingFromCityProvinceAndCountry);
        // console.log('[validate] validateShippingToCityProvinceAndCountry', validateShippingToCityProvinceAndCountry);

        const shipFromMissing = getMissingKeysForCreateLabelLocation({
            name: shipFrom?.name,
            phone: shipFrom?.phone,
            address: shipFrom?.address,
            city: shipFrom?.city,
            province: shipFrom?.province?.code,
            country: shipFrom?.country,
            postalCode: shipFrom?.postalCode
        });
        const shipToMissing = getMissingKeysForCreateLabelLocation({
            name: shipTo?.name,
            phone: shipTo?.phone,
            address: shipTo?.address,
            city: shipTo?.city,
            province: shipTo?.province?.code,
            country: shipTo?.country,
            postalCode: shipTo?.postalCode
        });
        const hasMissingValues = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = packageListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        // const hasMissingValuesCrossBorder = CrossBorderHasMissingValues();
        const shipFromPhoneValidate = isValidatePhone(shipFrom?.phone);
        const shipToPhoneValidate = isValidatePhone(shipTo?.phone);
        const hasOverweightObject = packageListRedux.some(obj => {
            if (obj.weightUnit === 'kg') {
                const weightInLbs = obj.weight * 2.20462; // Convert kg to lbs
                return weightInLbs > 150;
            } else {
                return obj.weight > 150;
            }
        });

        const validation = {
            shipFromMissing,
            shipToMissing,
            shipFromPhone: shipFromPhoneValidate,
            shipToPhone: shipToPhoneValidate,
            // validateShippingFromCityProvinceAndCountry,
            // validateShippingToCityProvinceAndCountry
        }
        console.log('validation', validation);
        dispatch(updateCreateLabelShippingPackageValidation(validation));

        packageListRedux?.length === 0 && setErrorMessage(prevState => prevState + ' Your package list is empty.');
        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Ship From is missing one or more required fields.');
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Ship To is missing one or more required fields.');
        !shipFromPhoneValidate && setErrorMessage(prevState => prevState + ' Ship From phone number is invalid.');
        !shipToPhoneValidate && setErrorMessage(prevState => prevState + ' Ship To phone number is invalid.');
        hasMissingValues && setErrorMessage(prevState => prevState + ' Your package list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + ' Your package list has one or more invalid fields.');
        // hasMissingValuesCrossBorder && setErrorMessage(prevState => prevState + ' Your package list has missing one or more required fields in cross border section.');
        hasOverweightObject && setErrorMessage(prevState => prevState + ' In your package list, there is at least one package that weighs over 150 pounds.')
        // !validateShippingFromCityProvinceAndCountry?.validation && setErrorMessage(prevState => prevState + ' Shipping from city, province and country are not matched or having invalid value.');
        // !validateShippingToCityProvinceAndCountry?.validation && setErrorMessage(prevState => prevState + ' Shipping to city, province and country are not matched or having invalid value.');

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            packageListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            // !hasMissingValuesCrossBorder &&
            shipFromPhoneValidate &&
            shipToPhoneValidate &&
            !hasOverweightObject
            // validateShippingFromCityProvinceAndCountry?.validation &&
            // validateShippingToCityProvinceAndCountry?.validation
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const handleAddressVerification = async () => {
        const verifyAddressResultShiFrom = await verifyAddress(shipFrom?.address, shipFrom?.address2, shipFrom?.city, shipFrom?.province?.code, shipFrom?.country, shipFrom?.postalCode);
        const verifyAddressResultShiTo = await verifyAddress(shipTo?.address, shipTo?.address2, shipTo?.city, shipTo?.province?.code, shipTo?.country, shipTo?.postalCode);

        console.log('[handleAddressVerification] verifyAddressResultShiFrom', verifyAddressResultShiFrom);
        console.log('[handleAddressVerification] verifyAddressResultShiTo', verifyAddressResultShiTo);

        setShipFromAddressValidation(verifyAddressResultShiFrom);
        setShipToAddressValidation(verifyAddressResultShiTo);

        if (verifyAddressResultShiFrom?.validationCode === 200 && verifyAddressResultShiTo?.validationCode === 200) {
            console.log('call api');
            getRating();
        }
    }

    const getRating = async () => {
        dispatch(clearCreateLabelShippingPackageRateList());
        // dispatch(clearCallAllPackageCarrier());
        try {
            // if (shipFrom?.country === shipTo?.country) {
            //     console.log('same')

            const availableService = await getAllPackageAccountBaseList();

            await handleCallCarrier(availableService);

            // getShippingPackageRate('canpar');
            // getShippingPackageRate('ups');
            // getShippingPackageRate('fedex');
            // getShippingPackageRate('canadapost');

            // } else {
            //     console.log('diff')
            //     getShippingPackageRate('ups');
            // }
        } catch (e) {
            console.log('error ', e.response);
        } finally {
            // console.log('save address');
            // console.log('ship from', shipFrom);
            if (shipFrom.saveAddress) {
                addAddressShipFrom();
            }
            if (shipTo.saveAddress) {
                addAddressShipTo();
            }
        }
    }

    const handleRecommendEmail = () => {
        const missingKeys = getMissingKeysFromObject({
            shipFromEmail: shipFrom?.email,
            shipToEmail: shipTo?.email
        })
        setRecommendEmail(missingKeys);
        return missingKeys?.length !== 0;
    }

    const continueGetRate = () => {
        handleDialogClose();
        dispatch(clearCreateLabelShippingPackageRateList());
        setShipFromOriginal(shipFrom);
        setShipToOriginal(shipTo);
        handleAddressVerification();
    }

    const handleGetRate = async () => {
        setAddressVerifyDialog(false);
        const validationResult = await validate();
        const recommendedEmailResult = handleRecommendEmail();
        if (validationResult) {
            if (recommendedEmailResult) {
                handleDialogOpen();
            } else {
                continueGetRate();
            }
        }
    }

    const handleRecommendAddress = async () => {
        dispatch(clearCreateLabelShippingPackageRateList());
        dispatch(updateCreateLabelShippingPackageShipFrom({
            postalCode: shipFromAddressValidation?.validateAddress?.postalCode || shipFrom?.postalCode,
            province: {
                code: shipFromAddressValidation?.validateAddress?.province || shipFrom?.province?.code,
                name: shipFromAddressValidation?.validateAddress?.province || shipFrom?.province?.code,
            },
            city: shipFromAddressValidation?.validateAddress?.city || shipFrom?.city,
            address: shipFromAddressValidation?.validateAddress?.address || shipFrom?.address,
            address2: shipFromAddressValidation?.validateAddress?.address2 || shipFrom?.address2,
            country: shipFromAddressValidation?.validateAddress?.country || shipFrom?.country,
            displayAddress: {
                description: null,
                placeId: null,
                text: shipFromAddressValidation?.validateAddress?.address || shipFrom?.address
            },
            name: shipFrom?.name,
            phone: shipFrom?.phone,
            phoneExt: shipFrom?.phoneExt,
            email: shipFrom?.email,
            company: shipFrom?.company,
            saveAddress: shipFrom?.saveAddress,
            addressList: {
                name: shipFrom?.name,
                phone: shipFrom?.phone,
                email: null,
                address_email: shipFrom?.email,
                company_name: shipFrom?.company,
                city: shipFromAddressValidation?.validateAddress?.city || shipFrom?.city,
                province: shipFromAddressValidation?.validateAddress?.province || shipFrom?.province?.code,
                country: shipFromAddressValidation?.validateAddress?.country || shipFrom?.country
            }
        }));
        dispatch(updateCreateLabelShippingPackageShipTo({
            postalCode: shipToAddressValidation?.validateAddress?.postalCode || shipTo?.postalCode,
            province: {
                code: shipToAddressValidation?.validateAddress?.province || shipTo?.province?.code,
                name: shipToAddressValidation?.validateAddress?.province || shipTo?.province?.code
            },
            city: shipToAddressValidation?.validateAddress?.city || shipTo?.city,
            address: shipToAddressValidation?.validateAddress?.address || shipTo?.address,
            address2: shipToAddressValidation?.validateAddress?.address2 || shipTo?.address2,
            country: shipToAddressValidation?.validateAddress?.country || shipTo?.country,
            displayAddress: {
                description: null,
                placeId: null,
                text: shipToAddressValidation?.validateAddress?.address || shipTo?.address
            },
            name: shipTo?.name,
            phone: shipTo?.phone,
            phoneExt: shipTo?.phoneExt,
            email: shipTo?.email,
            company: shipTo?.company,
            saveAddress: shipTo?.saveAddress,
            addressList: {
                name: shipTo?.name,
                phone: shipTo?.phone,
                email: null,
                address_email: shipTo?.email,
                company_name: shipTo?.company,
                city: shipToAddressValidation?.validateAddress?.city || shipTo?.city,
                province: shipToAddressValidation?.validateAddress?.province || shipTo?.province?.code,
                country: shipToAddressValidation?.validateAddress?.country || shipTo?.country
            }
        }));
        try {
            // if (shipFrom?.country === shipTo?.country) {
            //     console.log('same')

            const availableService = await getAllPackageAccountBaseList();

            await handleCallCarrierRecommend(availableService);

            // getShippingPackageRateRecommend('canpar');
            // getShippingPackageRateRecommend('ups');
            // getShippingPackageRateRecommend('fedex');
            // getShippingPackageRateRecommend('canadapost');


            // } else {
            //     console.log('diff')
            //     getShippingPackageRateRecommend('ups');
            // }
        } catch (e) {
            console.log('error ', e.response);
        } finally {
            // console.log('save address');
            // console.log('ship from', shipFrom);
            if (shipFrom.saveAddress) {
                addAddressShipFrom();
            }
            if (shipTo.saveAddress) {
                addAddressShipTo();
            }
        }
    }

    const handleSwap = () => {
        setSwapShipFrom(shipTo);
        setSwapShipTo(shipFrom);
    }

    useEffect(() => {
        if (shipFromAddressValidation !== null && shipToAddressValidation !== null && (shipFromAddressValidation?.validationCode !== 200 || shipToAddressValidation?.validationCode !== 200)) {
            setAddressVerifyDialog(true);
        }
    }, [shipFromAddressValidation, shipToAddressValidation])

    useEffect(() => {
        setReferenceOrderNumber(referenceOrderNumberRedux);
        setReturnLabel(returnLabelRedux);
    }, []);

    useEffect(() => {
        dispatch(updateCreateLabelShippingPackageCrossBorder(crossBorder))
    }, [crossBorder]);

    // useEffect(() => {
    //     dispatch(isCallAllPackageCarrier(isCalledAllCarrier));
    // }, [isCalledAllCarrier])

    // console.log('[ShippingPackageCreateLabel] recommendEmail', recommendEmail);
    console.log('[ShippingPackageCreateLabel] ship from', shipFrom);
    console.log('[ShippingPackageCreateLabel] ship to', shipTo);
    console.log('[ShippingPackageCreateLabel] swap ship from', swapShipFrom);
    console.log('[ShippingPackageCreateLabel] swap ship to', swapShipTo);
    // console.log('cross border', crossBorder);
    // console.log('[ShippingPackageCreateLabel] isCalledAllCarrier', isCalledAllCarrier);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
            >
                <DialogTitle>
                    Recommended Email
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography>
                            It is recommended to provide the following:
                        </Typography>
                        {
                            recommendEmail?.map((email) => (
                                <Typography sx={{fontWeight: '600'}}>
                                    {
                                        email === 'shipFromEmail' ? "Shipper's Email" : "Consignee's Email"
                                    }
                                </Typography>
                            ))
                        }
                        <Typography>
                            Click 'Ignore' to bypass this message and continue getting the rate, or click 'No' to
                            provide the email.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="outlined" autoFocus type="button">
                        {
                            intl.get('CROSS_BORDER.RECOMMENDATION.NO')
                        }
                    </Button>
                    <Button onClick={continueGetRate} variant="outlined" type="button">
                        {
                            intl.get('CROSS_BORDER.RECOMMENDATION.IGNORE')
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            <Stepper alternativeLabel activeStep={0} connector={<QontoConnector/>}>
                {
                    (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        // steps.map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 0 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box style={styles.ShippingPackageCreateLabelRoot}>
                <Box sx={{
                    padding: '15px',
                    border: '2px solid #FF8911',
                    borderRadius: '5px',
                    backgroundColor: light_orange,
                    display: 'flex',
                    gap: '10px'
                }}>
                    <WarningIcon sx={{color: orange}}/>
                    <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                        <span
                            style={{fontWeight: '600'}}>{intl.get('WARNING.BOND_TEXT')}</span> {intl.get('WARNING.CONTENT_TEXT')}
                    </Typography>
                </Box>
                <ShippingPackageCreateLabelShipFrom shipFromHistory={shipFromHistory} swapShipFrom={swapShipFrom}/>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    padding: 0
                }}>
                    <IconButton
                        onClick={handleSwap}
                    >
                        <SwapVertIcon sx={{fontSize: '30px', color: green}}/>
                    </IconButton>
                </Box>
                <ShippingPackageCreateLabelShipTo shipToHistory={shipToHistory} swapShipTo={swapShipTo}/>
                <ShippingPackageCreateLabelPackage packageListRedux={packageListRedux}
                                                   packageListHistory={packageListHistory}/>
                <ShippingPackageCreateLabelShippingOptions batteryDetailsHistory={batteryDetailsHistory}
                                                           signatureOptionsHistory={signatureOptionsHistory}/>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box sx={styles.ShippingPackageCreateLabelContainer}>
                            <Box sx={{
                                display: 'flex',
                                gap: '10px',
                                padding: '20px 20px 0 20px'
                            }}>
                                {/*<SchedulePickupIcon width={25} height={25}/>*/}
                                <Typography style={styles.ShippingPackageCreateLabelSubHeading}>
                                    Reference Order Number
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={styles.ShippingPackageCreateLabelContent}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                            <InputLabel>Reference Order Number</InputLabel>
                                            <TextField
                                                value={referenceOrderNumber}
                                                fullWidth
                                                variant='outlined'
                                                onInput={handleReferenceOrderNumber}
                                                size='small'
                                                error={refError}
                                                helperText={refError && "Max 30 characters. Only the following characters are allowed: letters (A-Z, a-z), digits (0-9), spaces (' '), hyphens ('-'), parentheses ('(', ')'), dots ('.'), and underscores ('_')."}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={styles.ShippingPackageCreateLabelContainer}>
                            <Box sx={{
                                display: 'flex',
                                gap: '10px',
                                padding: '20px 20px 0 20px'
                            }}>
                                <Typography style={styles.ShippingPackageCreateLabelSubHeading}>
                                    Return Label
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={styles.ShippingPackageCreateLabelContent}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Box sx={styles.ShippingPackageCreateLabelSectionBox}>
                                            <InputLabel>Return labels are for North American shipments
                                                only.</InputLabel>
                                            <FormControlLabel
                                                value="start"
                                                control={<Checkbox
                                                    checked={returnLabel}
                                                    onChange={handleReturnLabel}
                                                    color="success"
                                                />}
                                                label={
                                                    <Typography style={{textAlign: 'left'}}>
                                                        Return Label
                                                    </Typography>
                                                }
                                                labelPlacement="end"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                {
                    addressVerifyDialog ? <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography style={{
                                        fontWeight: '600',
                                        color: '#1D8B45',
                                        textAlign: 'center'
                                    }}>
                                        Verify Your Shipping Address
                                    </Typography>
                                </Grid>
                                {
                                    (shipFromAddressValidation?.validationCode === 404 ||
                                        shipFromAddressValidation?.validationCode === 406 ||
                                        shipToAddressValidation?.validationCode === 404 ||
                                        shipToAddressValidation?.validationCode === 406) ?
                                        <>
                                            <Grid item xs={12}>
                                                <Typography style={{textAlign: 'center'}}>
                                                    While your address did not fully match with Canada Post AddressComplete,
                                                    it’s possible your shipment can still proceed normally.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    gap: '10px',
                                                }}>
                                                    <LoadingButton
                                                        onClick={getRating}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus
                                                        type="button"
                                                        loading={loading}
                                                    >
                                                        Continue Your Address
                                                    </LoadingButton>
                                                </Box>
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <Grid item xs={12}>
                                                <Typography style={{textAlign: 'center'}}>
                                                    Based on Canada Post AddressComplete, we've detected some discrepancies
                                                    in the address you provided. For accurate shipping rates and timely
                                                    delivery, consider our recommended address.
                                                </Typography>
                                            </Grid>
                                            {
                                                shipFromAddressValidation?.validationCode === 400 &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <Typography style={{
                                                                fontWeight: '600',
                                                                textAlign: 'center'
                                                            }}>
                                                                Ship From Address
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                Original Address
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFromAddressValidation?.originalAddress?.address2 && `${shipFromAddressValidation?.originalAddress?.address2} - `}{shipFromAddressValidation?.originalAddress?.address}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFromAddressValidation?.originalAddress?.city} {shipFromAddressValidation?.originalAddress?.province}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFromAddressValidation?.originalAddress?.country} {shipFromAddressValidation?.originalAddress?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                Recommend Address
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFromAddressValidation?.validateAddress?.address2 && `${shipFromAddressValidation?.validateAddress?.address2} - `}{shipFromAddressValidation?.validateAddress?.address}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFromAddressValidation?.validateAddress?.city} {shipFromAddressValidation?.validateAddress?.province}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipFromAddressValidation?.validateAddress?.country} {shipFromAddressValidation?.validateAddress?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider/>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                shipToAddressValidation?.validationCode === 400 &&
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <Typography style={{
                                                                fontWeight: '600',
                                                                textAlign: 'center'
                                                            }}>
                                                                Ship To Address
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                Original Address
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipToAddressValidation?.originalAddress?.address2 && `${shipToAddressValidation?.originalAddress?.address2} - `}{shipToAddressValidation?.originalAddress?.address}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipToAddressValidation?.originalAddress?.city} {shipToAddressValidation?.originalAddress?.province}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipToAddressValidation?.originalAddress?.country} {shipToAddressValidation?.originalAddress?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                Recommend Address
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipToAddressValidation?.validateAddress?.address2 && `${shipToAddressValidation?.validateAddress?.address2} - `}{shipToAddressValidation?.validateAddress?.address}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipToAddressValidation?.validateAddress?.city} {shipToAddressValidation?.validateAddress?.province}
                                                            </Typography>
                                                            <Typography style={{textAlign: 'center'}}>
                                                                {shipToAddressValidation?.validateAddress?.country} {shipToAddressValidation?.validateAddress?.postalCode}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    gap: '10px'
                                                }}>
                                                    <LoadingButton
                                                        onClick={getRating}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus
                                                        type="button"
                                                        loading={loading}
                                                    >
                                                        Continue Your Address
                                                    </LoadingButton>
                                                    <LoadingButton
                                                        onClick={handleRecommendAddress}
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        autoFocus type="button"
                                                        loading={loading}
                                                    >
                                                        Use Recommend Address
                                                    </LoadingButton>
                                                </Box>
                                            </Grid>
                                        </>

                                }
                            </Grid>
                        </>
                        :
                        <Box sx={styles.ShippingPackageCreateLabelRowRight}>
                            <LoadingButton
                                variant='contained'
                                color='success'
                                size='large'
                                loading={loading}
                                onClick={handleGetRate}
                            >
                                Get Shipping Rates
                            </LoadingButton>
                        </Box>
                }
            </Box>
        </Container>
    )
}