import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Divider,
    Snackbar, Step, StepLabel, Stepper,
    useMediaQuery
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {mapCarrierLogo} from "../../../Utils/Helper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Fragment, useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {deleteToken, getAccessToken} from "../../../Utils/doToken";
import MuiAlert from "@mui/material/Alert";
import * as React from "react";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CircularProgress from "@mui/material/CircularProgress";
import {
    clearShippingPackageCreateLabelPackageList,
    clearShippingPackageCrossBorderItemList, clearShippingPackageQuickQuotePackageList,
    updateShipFromPackageCreateLabelPackage,
    updateShippingPackageInternationalShipmentShipFrom,
    updateShippingPackageInternationalShipmentShipTo,
    updateShippingPackageReturnLabelStatus,
    updateShipToPackageCreateLabelPackage
} from "../../../actions/BusinessShippingPackageAction";
import intl from "react-intl-universal";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../../Utils/CustomComponent";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import {ReactComponent as ShippingFromAndToIcon} from "../../../images/NewDesignIcon/ShippingLocation.svg";
import {ReactComponent as PackageDetailIcon} from "../../../images/NewDesignIcon/Package.svg";
import {ReactComponent as PackageTypeIcon} from "../../../images/NewDesignIcon/AdditionalDetails.svg";
import {ReactComponent as RateDetailsIcon} from "../../../images/NewDesignIcon/RateDetails.svg";
import {selectCreateLabelShippingPackageRate} from "../../../actions/shippingPackageAction";
import {defaultText, light_orange, orange} from "../../../Utils/styleConfig";
import WarningIcon from "@mui/icons-material/Warning";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

export const BusinessShippingPackageCreateLabelOrderDetails = () => {

    const dispatch = useDispatch();
    const token = getAccessToken("access_token");
    const isMobile = useMediaQuery('(max-width: 600px)');
    const history = useHistory();

    let declarationDutyFreeEntry = useSelector(state => state?.businessShippingPackage?.declarationDutyFreeEntry);
    let returnLabel = useSelector(state => state?.businessShippingPackage?.returnLabel);
    let signatureOptions = useSelector(state => state?.businessShippingPackage?.createLabelSignatureOptions);
    let batteryDetails = useSelector(state => state?.businessShippingPackage?.createLabelBatteryDetails);
    let selectRate = useSelector(state => state?.businessShippingPackage?.createLabelRateSelect);
    let packageListRedux = useSelector(state => state?.businessShippingPackage?.createLabelPackageList);
    let shipFrom = useSelector(state => state?.businessShippingPackage?.createLabelShipFrom);
    let shipTo = useSelector(state => state?.businessShippingPackage?.createLabelShipTo);
    let referenceOrderNumber = useSelector(state => state?.businessShippingPackage?.createLabelReferenceOrderNumber);
    let crossBorder = useSelector(state => state?.businessShippingPackage?.createLabelCrossBorder);
    let crossBorderRate = useSelector(state => state?.businessShippingPackage?.crossBorderRate);
    let crossBorderItemListRedux = useSelector(state => state?.businessShippingPackage?.crossBorderItemList);
    const userInfo = useSelector((state) => state.user);

    const [loadingLabel, setLoadingLabel] = useState(false);
    const [loadingOneClick, setLoadingOneClick] = useState(false);
    const [loadingPlaceOrder, setLoadingPlaceOrder] = useState(false);
    const [labelImage, setLabelImage] = useState();
    const [buyShippingStatus, setBuyShippingStatus] = useState(false);

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [confirmationProceed, setConfirmationProceed] = useState(false);

    // console.log('select rate', selectRate);
    // console.log('package list', packageListRedux);
    // console.log('ship from', shipFrom);
    // console.log('user info', userInfo);
    // console.log('cross border rate', crossBorderRate);
    // console.log('reference order number', referenceOrderNumber);
    // console.log('cross border item list', crossBorderItemListRedux);
    // console.log('cross border', crossBorder);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const partnerGetLabel = async () => {
        setLoadingLabel(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/getSampleLabelByAccountBaseAndService`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            accountBase: selectRate?.accountBase,
            serviceType: selectRate?.serviceType,
            shipFromName: shipFrom?.name,
            shipFromCompanyName: shipFrom?.company,
            shipFromPhone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipFromEmail: shipFrom?.email,
            shipFromAddress: shipFrom?.displayAddress?.text,
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromCountry: shipFrom?.country,
            shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
            shipToName: shipTo?.name,
            shipToCompanyName: shipTo?.company,
            shipToPhone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.displayAddress?.text,
            shipToAddressTwo: shipTo?.address2,
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToPostalCode: shipTo?.postalCode?.replace(/\s+/g, '').toUpperCase(),
            shipToCountry: shipTo?.country,
            cargoControlNumber: "",
            shipmentSpecialServices: {
                returnShipment: returnLabel
            },
            refOrderNumber: referenceOrderNumber,
            packageData: packageData
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            // console.log('result', result?.data?.result);
            setLabelImage(result?.data?.result);
            setLoadingLabel(false);
            setSuccessMessage('Successful Get Sample Label.');
            setErrorMessage("");
            handleSuccessMessage();

        } catch (e) {
            console.log('error', e);
            setLoadingLabel(false);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setErrorMessage('Fail To Get Sample Label.');
            }
            handleErrorMessage();
        }
    }

    const partnerBuyShipping = async () => {
        setLoadingPlaceOrder(true);
        setBuyShippingStatus(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/shipping`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            account_base_combine_name: selectRate?.accountBaseCombineName,
            accountId: selectRate?.accountId,
            service_type: selectRate?.serviceType,
            user_email: userInfo?.email,
            pickup_address: shipFrom?.displayAddress?.text,
            pickup_area: shipFrom?.city,
            deliver_address: shipTo?.displayAddress?.text,
            deliver_area: shipTo?.city,
            cargo_control_no: "",
            ref_order_no: referenceOrderNumber,
            deliver_address_two: shipTo?.address2,
            schedule_pickup: "2021-09-27 09:29",
            pickup_address_obj: {
                address: shipFrom?.displayAddress?.text,
                addressLineTwo: shipFrom?.address2,
                city: shipFrom?.city,
                postal_code: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                province: shipFrom?.province?.code,
                companyName: shipFrom?.company,
                country: shipFrom?.country,
                name: shipFrom?.name,
                email: shipFrom?.email
            },
            pickup_phone: shipFrom?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            pickup_email: shipFrom?.email,
            deliver_address_obj: {
                address: shipTo?.displayAddress?.text,
                city: shipTo?.city,
                addressLineTwo: shipTo?.address2,
                postal_code: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
                province: shipTo?.province?.code,
                companyName: shipTo?.company,
                country: shipTo?.country,
                name: shipTo?.name,
                email: shipTo?.email
            },
            delivery_phone: shipTo?.phone?.replace(/[^a-zA-Z0-9]/g, ''),
            deliver_email: shipTo?.email,
            shipmentType: shipFrom.country === shipTo.country ? null : crossBorder?.shipmentType,
            transMode: shipFrom.country === shipTo.country ? null : crossBorder?.tranMode,
            shipmentSpecialServices: {
                returnShipment: returnLabel // true | false
            },
            package_data: packageData,
            packingList: selectRate?.isCrossBoarder ? crossBorderItemListRedux : []
        }

        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('result', result.data);

            if (result?.data?.shippingResData[0]?.tranStatus === 1) {
                setErrorMessage('');
                handleSuccessMessage();
                setSuccessMessage('Successfully place order.');

                dispatch(updateShipFromPackageCreateLabelPackage({
                    postalCode: null,
                    province: null,
                    city: null,
                    address: null,
                    address2: null,
                    country: null,
                    displayAddress: {
                        description: null,
                        placeId: null,
                        text: null
                    },
                    name: null,
                    phone: null,
                    email: null,
                    company: null,
                    saveAddress: false,
                }))
                dispatch(updateShipToPackageCreateLabelPackage({
                    postalCode: null,
                    province: null,
                    city: null,
                    address: null,
                    address2: null,
                    country: null,
                    displayAddress: {
                        description: null,
                        placeId: null,
                        text: null
                    },
                    name: null,
                    phone: null,
                    email: null,
                    company: null,
                    saveAddress: false,
                }))
                dispatch(clearShippingPackageCreateLabelPackageList());
                dispatch(clearShippingPackageQuickQuotePackageList());
                dispatch(clearShippingPackageCrossBorderItemList());
                dispatch(updateShippingPackageInternationalShipmentShipFrom(null));
                dispatch(updateShippingPackageInternationalShipmentShipTo(null));
                setTimeout(() => {
                    // history.push(`/business/package-order-dashboard/${result?.data?.shippingOrderData?.orderId}`);
                    history.push(`/business/shippingOrderDetails/${result?.data?.shippingOrderData?.uuc_order_id}`);

                }, 2000);
            } else {
                handleErrorMessage();
                setErrorMessage(`Fail to place order.${result?.data?.shippingResData[0]?.tranMessage}`);
            }

        } catch (e) {
            console.log(e.response);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setErrorMessage(`Fail to place order. ${e.response?.data?.message || 'Please try again later.'}`);
            }
            handleErrorMessage();
        } finally {
            setLoadingPlaceOrder(false);
        }

    }

    const initBackendOrder = async () => {
        setLoadingPlaceOrder(true);
        setBuyShippingStatus(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/init3PLOrder`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            accountBaseCombinedName: selectRate?.accountBaseCombineName,
            accountId: selectRate?.accountId,
            serviceType: selectRate?.serviceType,
            shipFromName: shipFrom?.name,
            shipFromCompanyName: shipFrom?.company,
            shipFromPhone: shipFrom?.phone?.replace(/\D/g, ''),
            shipFromPhoneExtension: shipFrom?.phoneExt?.replace(/\D/g, ''),
            shipFromEmail: shipFrom?.email,
            shipFromAddress: shipFrom?.displayAddress?.text,
            shipFromAddressTwo: shipFrom?.address2,
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromCountry: shipFrom?.country,
            shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
            shipToName: shipTo?.name,
            shipToCompanyName: shipTo?.company,
            shipToPhone: shipTo?.phone?.replace(/\D/g, ''),
            shipToPhoneExtension: shipTo?.phoneExt?.replace(/\D/g, ''),
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.displayAddress?.text,
            shipToAddressTwo: shipTo?.address2,
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToCountry: shipTo?.country,
            shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
            refOrderNumber: referenceOrderNumber,
            shipmentType: shipFrom.country === shipTo.country ? null : crossBorder?.shipmentType,
            transMode: shipFrom.country === shipTo.country ? null : crossBorder?.tranMode,
            shipmentSpecialServices: {
                returnShipment: returnLabel // true | false
            },
            packageData: packageData,
            packingList: selectRate?.isCrossBoarder ? crossBorderItemListRedux : [],
            declarationDutyFreeEntry: declarationDutyFreeEntry
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('[initBackendOrder] result', result.data);
            setErrorMessage('');
            dispatch(updateShipFromPackageCreateLabelPackage({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(updateShipToPackageCreateLabelPackage({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(clearShippingPackageCreateLabelPackageList());
            dispatch(clearShippingPackageQuickQuotePackageList());
            dispatch(clearShippingPackageCrossBorderItemList());
            dispatch(updateShippingPackageInternationalShipmentShipFrom(null));
            dispatch(updateShippingPackageInternationalShipmentShipTo(null));
            dispatch(updateShippingPackageReturnLabelStatus(false));
            setTimeout(() => {
                history.push(`/business/shippingOrderDetails/${result?.data?.id}`);
            }, 2000);

        } catch (e) {
            console.log('[initBackendOrder] error', e.response);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 2000);
            } else {
                setErrorMessage(`Fail to place order. ${e.response?.data?.message || 'Please try again later.'}`);
            }
            handleErrorMessage();
        } finally {
            setLoadingPlaceOrder(false);
        }
    }

    const oneClickOrder =async () => {
        setLoadingOneClick(true);
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/init3PLOrderAndPayWithCreditCard`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            accountBaseCombinedName: selectRate?.accountBaseCombineName,
            accountId: selectRate?.accountId,
            serviceType: selectRate?.serviceType,
            shipFromName: shipFrom?.name,
            shipFromCompanyName: shipFrom?.company,
            shipFromPhone: shipFrom?.phone?.replace(/\D/g, ''),
            shipFromPhoneExtension: shipFrom?.phoneExt?.replace(/\D/g, ''),
            shipFromEmail: shipFrom?.email,
            shipFromAddress: shipFrom?.displayAddress?.text,
            shipFromAddressTwo: shipFrom?.address2,
            shipFromCity: shipFrom?.city,
            shipFromProvince: shipFrom?.province?.code,
            shipFromCountry: shipFrom?.country,
            shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
            shipToName: shipTo?.name,
            shipToCompanyName: shipTo?.company,
            shipToPhone: shipTo?.phone?.replace(/\D/g, ''),
            shipToPhoneExtension: shipTo?.phoneExt?.replace(/\D/g, ''),
            shipToEmail: shipTo?.email,
            shipToAddress: shipTo?.displayAddress?.text,
            shipToAddressTwo: shipTo?.address2,
            shipToCity: shipTo?.city,
            shipToProvince: shipTo?.province?.code,
            shipToCountry: shipTo?.country,
            shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
            refOrderNumber: referenceOrderNumber,
            shipmentType: shipFrom.country === shipTo.country ? null : crossBorder?.shipmentType,
            transMode: shipFrom.country === shipTo.country ? null : crossBorder?.tranMode,
            shipmentSpecialServices: {
                returnShipment: returnLabel // true | false
            },
            packageData: packageData,
            packingList: selectRate?.isCrossBoarder ? crossBorderItemListRedux : [],
            declarationDutyFreeEntry: declarationDutyFreeEntry
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            })

            console.log('[initBackendOrder] result', result.data);
            setErrorMessage('');
            dispatch(updateShipFromPackageCreateLabelPackage({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(updateShipToPackageCreateLabelPackage({
                postalCode: null,
                province: null,
                city: null,
                address: null,
                address2: null,
                country: null,
                displayAddress: {
                    description: null,
                    placeId: null,
                    text: null
                },
                name: null,
                phone: null,
                email: null,
                company: null,
                saveAddress: false,
            }))
            dispatch(clearShippingPackageCreateLabelPackageList());
            dispatch(clearShippingPackageQuickQuotePackageList());
            dispatch(clearShippingPackageCrossBorderItemList());
            dispatch(updateShippingPackageInternationalShipmentShipFrom(null));
            dispatch(updateShippingPackageInternationalShipmentShipTo(null));
            dispatch(updateShippingPackageReturnLabelStatus(false));
            setTimeout(() => {
                history.push(`/business/shippingOrderDetails/${result?.data?.id}`);
            }, 1000);

        } catch (e) {
            console.log('[initBackendOrder] error', e.response);
            if (e.response.status === 440) {
                setErrorMessage('The authentication token has expired.');
                deleteToken("access_token");
                setTimeout(() => window.location.reload(), 1000);
            } else {
                setErrorMessage(`Fail to place order. ${e.response?.data?.message || 'Please try again later.'}`);
            }
            handleErrorMessage();
        } finally {
            setLoadingOneClick(false);
        }
    }


    const northAmericaShipment = () => {
        if ((shipFrom?.country?.toLowerCase() === 'ca' || shipFrom?.country?.toLowerCase() === 'us') && (shipTo?.country?.toLowerCase() === 'ca' || shipTo?.country?.toLowerCase() === 'us')) {
            return true
        } else {
            return false
        }
    }

    const handleGetLabel = () => {
        setLabelImage(null);
        partnerGetLabel();
    }

    const handleBackToShippingInfo = () => {
        history?.push('/business/shipping-package/create-label');
    }

    const handleBackToChooseCarrier = () => {
        history.push('/business/shipping-package/create-label/rate-list');
    }

    const handleStartOver = () => {
        history.push('/business/shipping-package/create-label');
    }

    const handleContinueProceed = () => {
        setConfirmationProceed(true);
    }

    const handleCancelProceed = () => {
        setConfirmationProceed(false);
    }

    // const handleQuoteList = () => {
    //     const backToRateList = northAmericaShipment();
    //     if (backToRateList) {
    //         history.push('/business/shipping-package/create-label/rate-list');
    //     } else {
    //         history.push('/business/shipping-package/international-shipment/rate-list');
    //     }
    // }

    const handleBack = () => {
        // history.push('/shipping-package/create-label/rate-list');
        if (shipFrom?.country === shipTo?.country) {
            history.push('/business/shipping-package/create-label/rate-list');
        } else {
            history.push('/business/shipping-package/cross-border-details');
        }
    }

    const handlePlaceOrder = () => {
        // partnerBuyShipping();
        initBackendOrder();
    }

    const handleOneClickOrder = () => {
        oneClickOrder();
    }

    const handleBeforeUnload = (event) => {

        console.log('reload');
        // Customize the confirmation message
        const message = 'Are you sure you want to leave? Your unsaved changes may be lost.';

        // Set the confirmation message for modern browsers
        event.returnValue = message;

        // For older browsers
        return message;
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []); // Empty dependency array means this effect runs once when the component mounts

    if (!shipFrom?.country && !buyShippingStatus) {
        return (
            <Container maxWidth='xl'>
                <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                    <Box sx={{
                        display: 'flex',
                        gap: '10px',
                        padding: '20px 20px 0 20px'
                    }}>
                        <ShipmentOverviewIcon width={25} height={25}/>
                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                            Shipment Overview
                        </Typography>
                    </Box>
                    <Divider/>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        padding: '0 40px',
                        gap: '40px',
                        display: 'flex',
                    }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{color: 'red', textAlign: 'left', fontSize: '16px'}}>
                                    You've lost all the data. Please return to the home page and create a new shipment.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '20px'}}>
                    <Button
                        variant='contained'
                        size='large'
                        onClick={() => history.push('/business/home')}
                        sx={{
                            backgroundColor: '#F2BE22',
                            "&:hover": {
                                backgroundColor: '#F2BE22',
                                filter: 'brightness(0.9)'
                            }
                        }}
                    >
                        <Typography style={{textTransform: 'none'}}>
                            Go Back Home
                        </Typography>
                    </Button>
                </Box>
            </Container>
        )
    }

    // console.log('[BusinessShippingPackageCreateLabelOrderDetails] declarationDutyFreeEntry', declarationDutyFreeEntry);
    // console.log('[BusinessShippingPackageCreateLabelOrderDetails] ship from', shipFrom);
    // console.log('[BusinessShippingPackageCreateLabelOrderDetails] ship to', shipTo);
    console.log('[BusinessShippingPackageCreateLabelOrderDetails] user info', userInfo);

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Stepper alternativeLabel activeStep={shipFrom?.country === shipTo?.country ? 2 : 3}
                     connector={<QontoConnector/>}>
                {
                    (shipFrom?.country === shipTo?.country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton onClick={handleStep(index)}>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 2 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ShipmentOverviewIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                        Shipment Overview
                    </Typography>
                </Box>
                <Divider/>
                <Box sx={{
                    backgroundColor: '#FFFFFF',
                    padding: '0 40px',
                    gap: '40px',
                    display: 'flex',
                }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ShippingFromAndToIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            {intl.get('SHIPPING_INFORMATION.SHIP_FROM')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.name}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.address2 && `${shipFrom?.address2} - `}{shipFrom?.address}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.city}, {shipFrom?.province?.code}, {shipFrom?.country}, {shipFrom?.postalCode}
                                        </Typography>
                                        {
                                            shipFrom?.email &&
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {shipFrom?.email}
                                            </Typography>
                                        }
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipFrom?.phone} {shipFrom?.phoneExt && `ext. ${shipFrom?.phoneExt}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ShippingFromAndToIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            {intl.get('SHIPPING_INFORMATION.SHIP_TO')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.name}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.address2 && `${shipTo?.address2} - `}{shipTo?.address}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.city}, {shipTo?.province?.code}, {shipTo?.country}, {shipTo?.postalCode}
                                        </Typography>
                                        {
                                            shipTo?.email &&
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {shipTo?.email}
                                            </Typography>
                                        }
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {shipTo?.phone} {shipTo?.phoneExt && `ext. ${shipTo?.phoneExt}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <PackageDetailIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Package Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        packageListRedux.length > 0 && packageListRedux?.map((each, index) => (
                                            <Fragment key={index}>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {`#${index + 1}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {each?.length} x {each?.width} x {each?.height} {each?.lengthUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {each?.weight} {each?.weightUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                        x {each?.quantity}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <PackageTypeIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Additional Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToShippingInfo}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        signatureOptions &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Include signature options
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        batteryDetails?.material &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Include lithium batteries
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        returnLabel &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Return Label
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Typography>
                                            Reference Order Number: {referenceOrderNumber || 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <RateDetailsIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Rate Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button onClick={handleBackToChooseCarrier}
                                                sx={{padding: 0, justifyContent: 'right', width: '100%'}}>
                                            <Typography sx={{color: '#1D8B45', textTransform: 'none'}}>
                                                Edit
                                            </Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <img
                                            src={mapCarrierLogo(selectRate?.accountBase)}
                                            alt="logo"
                                            width='60px'/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    {selectRate?.accountBase}
                                                </Typography>
                                                <Typography>
                                                    {selectRate?.shipRange} business day(s)
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    $ {selectRate?.isCrossBoarder ?
                                                    (+selectRate?.finalTotalAmount?.value + +crossBorderRate?.grandTotal).toFixed(2)
                                                    :
                                                    (+selectRate?.finalTotalAmount?.value).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            {
                                                selectRate?.shipmentCharges?.map((each, index) => {
                                                    const cost = +each?.value
                                                    if (cost !== 0) {
                                                        return (
                                                            <Fragment key={index}>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        {each?.description}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Typography
                                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                        $ {(+each?.value)?.toFixed(2)}
                                                                    </Typography>
                                                                </Grid>
                                                            </Fragment>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                +(selectRate?.serviceFee?.value) > 0 &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Service Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            $ {selectRate?.serviceFee?.value}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Subtotal
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    $ {((+selectRate?.totalCharge?.value) + selectRate?.serviceFee?.value).toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Tax
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    $ {((+selectRate?.serviceFeeTax?.value) + selectRate?.taxes?.reduce((total, item) => total + (+item?.value), 0))?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            {selectRate?.isCrossBoarder &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Total Brokerage Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            $ {crossBorderRate?.totalBrokerageFees?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Total Duty and Tax
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            $ {crossBorderRate?.totalDutyandTax?.toFixed(2)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    Total
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    $ {selectRate?.isCrossBoarder ?
                                                    (+selectRate?.finalTotalAmount?.value + crossBorderRate?.grandTotal).toFixed(2)
                                                    :
                                                    (+selectRate?.finalTotalAmount?.value).toFixed(2)
                                                }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {/*<Grid item xs={12}>*/}
                {/*    <Box sx={styles.ShippingPackageCreateLabelOrderDetailsSection}>*/}
                {/*        <Grid container spacing={1}>*/}
                {/*            <Grid item xs={12}>*/}
                {/*                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>*/}
                {/*                    {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.SAMPLE_LABEL')}*/}
                {/*                </Typography>*/}
                {/*            </Grid>*/}
                {/*            {!labelImage &&*/}
                {/*                <Grid item xs={12}>*/}
                {/*                    <Box sx={{*/}
                {/*                        display: 'flex',*/}
                {/*                        flexDirection: 'column',*/}
                {/*                        alignItems: 'center'*/}
                {/*                    }}>*/}
                {/*                        <IconButton*/}
                {/*                            sx={{color: '#609966'}}*/}
                {/*                            onClick={handleGetLabel}*/}
                {/*                            disabled={loadingLabel}*/}
                {/*                        >*/}
                {/*                            {*/}
                {/*                                loadingLabel ?*/}
                {/*                                    <CircularProgress size={24}/>*/}
                {/*                                    :*/}
                {/*                                    <AddBoxIcon/>*/}
                {/*                            }*/}
                {/*                        </IconButton>*/}
                {/*                        <Typography style={{fontSize: '12px', fontWeight: '600', color: '#609966'}}>*/}
                {/*                            {intl.get('SHIPPING_PACKAGE_CREATE_LABEL.GET_LABEL')}*/}
                {/*                        </Typography>*/}
                {/*                    </Box>*/}
                {/*                </Grid>*/}
                {/*            }*/}
                {/*            <Grid item xs={12}>*/}
                {/*                {*/}
                {/*                    labelImage &&*/}
                {/*                    <Box sx={{display: 'flex', justifyContent: 'center', margin: '40px 0'}}>*/}
                {/*                        <img*/}
                {/*                            src={`data:image/jpeg;base64,${labelImage}`}*/}
                {/*                            alt="label"*/}
                {/*                            width={isMobile ? 300 : 700}*/}
                {/*                            height="auto"*/}
                {/*                        />*/}
                {/*                    </Box>*/}
                {/*                }*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
            </Box>
            <Box sx={{
                marginBottom: '40px'
            }}>
                <Grid container spacing={2}>
                    {
                        selectRate?.accountBase?.toLowerCase() === 'canadapost' &&
                        <Grid item xs={12}>
                            <Box sx={{
                                padding: '15px',
                                border: '2px solid #FF8911',
                                borderRadius: '5px',
                                backgroundColor: light_orange,
                                display: 'flex',
                                gap: '10px'
                            }}>
                                <WarningIcon sx={{color: orange}}/>
                                <Typography sx={{fontSize: defaultText, textAlign: 'left'}}>
                                    <span style={{fontWeight: '600'}}>Service Disruption Notice:</span> Due to
                                    an ongoing strike at Canada Post, services may be delayed or temporarily
                                    unavailable. We apologize for any inconvenience and appreciate your
                                    understanding.
                                </Typography>
                            </Box>
                        </Grid>
                    }
                    {
                        confirmationProceed ?
                            <>
                                <Grid item xs={12}>
                                    <Typography>
                                        Would you like to start over? Please select 'Confirm' to begin again or 'Cancel'
                                        to
                                        continue with your current session.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '20px',
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleCancelProceed}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Cancel
                                            </Typography>
                                        </Button>
                                        <LoadingButton
                                            size='large'
                                            variant='contained'
                                            color='success'
                                            loading={loadingPlaceOrder}
                                            onClick={handleStartOver}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Confirm
                                            </Typography>
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item md={4}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-start',
                                        gap: '20px'
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleContinueProceed}
                                            sx={{
                                                backgroundColor: '#454545',
                                                "&:hover": {
                                                    backgroundColor: '#454545',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Start Over
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item md={8}>
                                    <Box sx={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'flex-end',
                                        gap: '20px',
                                    }}>
                                        <Button
                                            size='large'
                                            variant='contained'
                                            onClick={handleBack}
                                            sx={{
                                                backgroundColor: '#F2BE22',
                                                "&:hover": {
                                                    backgroundColor: '#F2BE22',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Back
                                            </Typography>
                                        </Button>
                                        <LoadingButton
                                            size='large'
                                            variant='contained'
                                            color='success'
                                            loading={loadingPlaceOrder}
                                            onClick={handlePlaceOrder}
                                        >
                                            <Typography sx={{textTransform: 'none'}}>
                                                Check Out
                                            </Typography>
                                        </LoadingButton>
                                        {
                                            +userInfo?.oneClickStatus === 1 &&
                                            <LoadingButton
                                                size='large'
                                                variant='contained'
                                                color='success'
                                                loading={loadingOneClick}
                                                onClick={handleOneClickOrder}
                                            >
                                                <Typography sx={{textTransform: 'none'}}>
                                                    One Click Order
                                                </Typography>
                                            </LoadingButton>
                                        }
                                    </Box>
                                </Grid>
                            </>
                    }
                </Grid>
            </Box>
        </Container>
    )
}