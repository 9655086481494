import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
import {LABEL_DOWNLOAD_URL_PREFIX, LABEL_DOWNLOAD_URL_PREFIX_ALTERNATE, PARTNER_URI} from "../../../Utils/apiUrl";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {saveAs} from 'file-saver';
import {CSVLink} from "react-csv";
import {useEffect, useRef, useState} from "react";
import {separateTaxes} from "../../../Utils/calculateTax";
import {getAccessToken} from "../../../Utils/doToken";
import {triggerBase64Download} from "common-base64-downloader-react";
import CircularProgress from "@mui/material/CircularProgress";
import {Link} from "@mui/material";
import intl from "react-intl-universal";

const style = {
    PackageOrderDashboardDocumentsRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const BusinessPackageOrderDashboardDocuments = ({orderData}) => {

    const {order_id: orderId} = useParams();

    const {firstname, id} = useSelector((state) => state.user);
    const [invoiceDetail, setInvoiceDetail] = useState(null);
    const [invoiceLoading, setInvoiceLoading] = useState(true);
    const [trackings, setTrackings] = useState([
        // ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Order Total Amount", "Transaction Amount", "Currency", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
        ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
    ])
    const [transactions, setTransactions] = useState([
        [
            "Order ID",
            "Cargo Control Number",
            "Ref Order No",
            "Tran ID",
            "Account Base",
            "Client Number",
            "Product ID",
            "Exclude Tax",
            "Include Tax",
            "GST",
            "HST",
            "QST",
            "Total Brokerage Charge",
            "Total Duties Charge",
            "Total Duties Tax Charge",
            "Service Fee Exclude Tax",
            "Service Fee Include Tax",
            "Shipping Total Tax",

            "Freight Charge",
            "SurCharge",
            "Fuel Charge",
            "Collect Charge",
            "Residential Charge",
            "Insurance Charge",
            "Rural Charge",
            "Extended Area Charge",
            "Extra Care Charge",
            "Handling Charge",
            "Over Size Charge",
            "Over Weight Charge",
            "COD Charge",
            "COS Charge",
            "Dangerous Charge",
            "Surcharge Details",
            "Date",
            "Tracking #",
            "Labels",

            "Send From Name",
            // "Send From Company",
            "Send From Address",
            "Send From City",
            "Send From Province",
            "Send From Country",
            "Send From Postal Code",

            "Send To Name",
            // "Send To Company",
            "Send To Phone",
            "Send To Email",
            "Send To Address",
            "Send To City",
            "Send To Province",
            "Send To Country",
            "Send To Postal Code"
        ],
    ]);

    const csvLink = useRef();
    const csvTrackingLink = useRef();

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    }

    const downloadLabelIntoZip = async () => {
        try {
            const accessToken = getAccessToken("access_token");
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getLabelZipByOrderId?partner_id=${id}&order_id=${orderId}`, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const blobData = new Blob([data], {
                type: "application/zip"
            });
            saveAs(blobData, `${id}-${orderId}.zip`);
            // console.log("labels: ", labels);
            // saveZip("my_project_files_to_download.zip", labels);
        } catch (e) {
            console.log(e);
        }
    }

    const getTransactionData = async () => {
        try {
            const {data} = await axios.get(`${PARTNER_URI}/shipping/list/getTransactionsByOrderId?partner_id=${id}&order_id=${orderId}&account_base=${orderData.account_base}`);
            const result = data.result;
            const resultArray = [
                [
                    "Order ID",
                    "Cargo Control Number",
                    "Ref Order No",
                    "Tran ID",
                    "Account Base",
                    "Client Number",
                    "Product ID",
                    "Exclude Tax",
                    "Include Tax",
                    "GST",
                    "HST",
                    "QST",
                    "Total Brokerage Charge",
                    "Total Duties Charge",
                    "Total Duties Tax Charge",
                    "Service Fee Exclude Tax",
                    "Service Fee Include Tax",
                    "Shipping Total Tax",

                    "Freight Charge",
                    "SurCharge",
                    "Fuel Charge",
                    "Collect Charge",
                    "Residential Charge",
                    "Insurance Charge",
                    "Rural Charge",
                    "Extended Area Charge",
                    "Extra Care Charge",
                    "Handling Charge",
                    "Over Size Charge",
                    "Over Weight Charge",
                    "COD Charge",
                    "COS Charge",
                    "Dangerous Charge",
                    "Surcharge Details",
                    "Date",
                    "Tracking #",
                    "Labels",

                    "Send From Name",
                    // "Send From Company",
                    "Send From Address",
                    "Send From City",
                    "Send From Province",
                    "Send From Country",
                    "Send From Postal Code",

                    "Send To Name",
                    // "Send To Company",
                    "Send To Phone",
                    "Send To Email",
                    "Send To Address",
                    "Send To City",
                    "Send To Province",
                    "Send To Country",
                    "Send To Postal Code"
                ],
            ];
            for (const column of result) {
                const columnData = [];
                columnData.push(column.order_id);
                columnData.push(column.cargo_control_no);
                columnData.push(column.ref_order_no);
                columnData.push(column.tran_id);
                columnData.push(column.account_base);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                // Swap Price
                columnData.push(column.amount_gross);
                columnData.push(column.amount);

                const taxesObject = separateTaxes({
                    excludeTaxAmount: column.amount_gross,
                    includeTaxAmount: column.amount
                })
                // // GST
                // columnData.push(taxesObject.gstTax);
                // // PST
                // columnData.push(taxesObject.pstTax);
                // columnData.push(taxesObject["GST/HST"]);
                columnData.push(taxesObject.gstTax);
                columnData.push(taxesObject.hstTax);
                columnData.push(taxesObject.qstTax);

                columnData.push(column.total_brokerage_charge);
                columnData.push(column.total_duties_charge);
                columnData.push(column.total_duties_taxes_charge);
                columnData.push(column.service_fee_exclude_tax);
                columnData.push(column.service_fee_include_gst);
                columnData.push(column.shipping_total_tax);

                columnData.push(column.freight_charge);
                columnData.push(column.sur_charge);
                columnData.push(column.fuel_surcharge);
                columnData.push(column.collect_charge);
                columnData.push(column.residential_charge);
                columnData.push(column.insurance_charge);
                columnData.push(column.rural_charge);
                columnData.push(column.extended_area_charge);
                columnData.push(column.extra_care_charge);
                columnData.push(column.handling_charge);
                columnData.push(column.over_size_charge);
                columnData.push(column.over_weight_charge);
                columnData.push(column.cod_charge);
                columnData.push(column.cos_charge);
                columnData.push(column.dangerous_charge);
                columnData.push(column?.surcharge_details || "");

                columnData.push(column.tranacted_at);
                columnData.push(column.tran_tracking_nos);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");

                // Send From Info
                columnData.push(column.send_from_name);
                columnData.push(column.send_from_addr);
                columnData.push(column.send_from_city);
                columnData.push(column.send_from_province);
                columnData.push(column.send_from_country);
                columnData.push(column.send_from_postal_code);

                // Send To Info
                columnData.push(column.send_to_name);
                columnData.push(column.send_to_tel);
                columnData.push(column.send_to_email);
                columnData.push(column.send_to_addr);
                columnData.push(column.send_to_city);
                columnData.push(column.send_to_province);
                columnData.push(column.send_to_country);
                columnData.push(column.send_to_postal_code);

                resultArray.push(columnData);
            }
            setTransactions(resultArray);
            console.log(resultArray);
            csvLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getTrackingsData = async () => {
        try {
            const {data} = await axios.post(`${PARTNER_URI}/shipping/list/order/trackings`, {
                partnerId: id,
                orderId: orderId,
            });
            const result = data.result;
            const resultArray = [
                ["Order ID", "Ref Order No", "Cargo Control Number", "Account Base", "Tracking Number", "Transaction ID", "Ref Client No", "Ref Product No", "Labels", "Label URI", "Date"]
            ];
            // const resultArray = [];
            for (const column of result) {
                const columnData = [];
                columnData.push(orderId);
                columnData.push(orderData.ref_order_no);
                columnData.push(orderData.cargo_control_no);
                columnData.push(orderData.account_base);
                columnData.push(column.tracking_no);
                // columnData.push(column.total_amount);
                // columnData.push(column.amount);
                // columnData.push(column.currency_code);
                columnData.push(column.tran_id);
                // Client ID
                columnData.push(column.ref_other_no);
                // Product ID
                columnData.push(column.ref_product_no);
                columnData.push(getLabelURL(column.label_pdf_uri, column.label_zip_uri) ? `https://storage.googleapis.com/${getLabelURL(column.label_pdf_uri, column.label_zip_uri)}` : "N/A");
                columnData.push(column.label_uri ? `https://storage.googleapis.com/${column.label_uri}` : "N/A");
                columnData.push(column.tranacted_at);

                resultArray.push(columnData);
            }
            // setTrackings(v => {
            //     const temp = [...v];
            //     temp.push(...resultArray);
            //     return temp;
            // });
            setTrackings(resultArray);
            console.log(resultArray);
            csvTrackingLink.current.link.click()
        } catch (e) {
            console.log(e);
        }
    }

    const getInvoiceNumber = async () => {
        setInvoiceLoading(true);
        const storedToken = getAccessToken("access_token");
        const requestURI = `${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderData?.crm_invoice_no
                }
            })
            console.log('result', result);
            setInvoiceDetail(result?.data);
        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setInvoiceLoading(false);
        }
    }

    const downloadPDFByInvoiceId = async () => {
        try {
            const storedToken = getAccessToken("access_token");
            const requestURL = `${PARTNER_URI}/billingCenter/downloadInvoice`;
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderData?.crm_invoice_no
                }
            })
            const content = data.content;
            const contentType = data["content-type"];
            const pdfContent = `data:${contentType};base64,${content}`;
            const fileName = data.filename?.length > 4 ? data.filename.slice(0, data.filename.length - 4) : data.filename;
            triggerBase64Download(pdfContent, fileName);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleDownInvoice = () => {
        downloadPDFByInvoiceId();
    }

    const handleDownUploadedFile = () => {
        const fileURL = `${LABEL_DOWNLOAD_URL_PREFIX}${orderData?.origi_shipping_item_file}`;
        // const fileURL = 'https://storage.googleapis.com/uucargo-resource/tmp/upload/shipping/1714-shipping-package-list-sample_13_.xlsx';
        saveAs(fileURL, 'downloaded-file.xlsx');
    }

    useEffect(() => {
        orderData?.crm_invoice_no ? getInvoiceNumber() : setInvoiceLoading(false);
    }, [orderData]);

    if (invoiceLoading) {
        return (
            <Box sx={style.PackageOrderDashboardDocumentsRoot}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '10px',
                            borderBottom: '1px solid #B2B2B2'
                        }}>
                            <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                                Shipping Documents
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <CircularProgress/>
                    </Grid>
                </Grid>
            </Box>
        )
    }

// console.log('invoice details', invoiceDetail);
// console.log('order data', orderData);

    return (
        <Box sx={style.PackageOrderDashboardDocumentsRoot}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.SHIPPING_DOCUMENTS')}
                        </Typography>
                    </Box>
                </Grid>
                {orderData?.origi_shipping_item_file &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.UPLOADED_FILE')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleDownUploadedFile}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={getTransactionData}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45',
                        }}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                        </Typography>
                    </Button>
                    <CSVLink
                        data={transactions}
                        filename={`${orderId}-${orderData.ref_order_no}-transactions.csv`}
                        className='hidden'
                        ref={csvLink}
                        target='_blank'
                    />
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRACKING_DETAILS')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={getTrackingsData}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45',
                        }}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                        </Typography>
                    </Button>
                    <CSVLink
                        data={trackings}
                        filename={`${orderId}-${orderData.ref_order_no}-trackings.csv`}
                        className='hidden'
                        ref={csvTrackingLink}
                        target='_blank'
                    />
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.SHIPPING_LABEL')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant='outlined'
                        sx={{
                            border: '2px solid #1D8B45',
                            "&:hover": {
                                border: '2px solid #1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        onClick={downloadLabelIntoZip}
                    >
                        <Typography style={{
                            textTransform: 'none',
                            fontSize: '14px',
                            color: '#1D8B45',
                        }}>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                        </Typography>
                    </Button>
                </Grid>
                {/*{*/}
                {/*    invoiceDetail &&*/}
                {/*    <>*/}
                {/*        <Grid item xs={7}>*/}
                {/*            <Box sx={{*/}
                {/*                display: 'flex',*/}
                {/*                alignItems: 'center',*/}
                {/*                height: '100%',*/}
                {/*                justifyContent: 'flex-start',*/}
                {/*                padding: '0 10px'*/}
                {/*            }}>*/}
                {/*                <Typography>*/}
                {/*                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_NO')}*/}
                {/*                </Typography>*/}
                {/*            </Box>*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={5}>*/}
                {/*            <Typography style={{*/}
                {/*                textTransform: 'none',*/}
                {/*                color: '#B454545',*/}
                {/*            }}>*/}
                {/*                {invoiceDetail?.invoiceRefNumber}*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*    </>*/}
                {/*}*/}
                {/*<Grid item xs={7}>*/}
                {/*    <Box sx={{*/}
                {/*        display: 'flex',*/}
                {/*        alignItems: 'center',*/}
                {/*        height: '100%',*/}
                {/*        justifyContent: 'flex-start',*/}
                {/*        padding: '0 10px'*/}
                {/*    }}>*/}
                {/*        <Typography>*/}
                {/*            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_STATUS')}*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={5}>*/}
                {/*    <Typography style={{*/}
                {/*        textTransform: 'none',*/}
                {/*        color: !orderData?.crm_invoice_no ? '#454545' : invoiceDetail?.status === "2" ? '#1D8B45' : '#FF0303',*/}
                {/*    }}>*/}
                {/*        {*/}
                {/*            !orderData?.crm_invoice_no ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.NOT_BILLED') : invoiceDetail?.status === "2" ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.PAID') : intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.UNPAID')*/}
                {/*        }*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
                {/*{*/}
                {/*    invoiceDetail &&*/}
                {/*    <>*/}
                {/*        <Grid item xs={7}>*/}
                {/*            <Box sx={{*/}
                {/*                display: 'flex',*/}
                {/*                alignItems: 'center',*/}
                {/*                height: '100%',*/}
                {/*                justifyContent: 'flex-start',*/}
                {/*                padding: '0 10px'*/}
                {/*            }}>*/}
                {/*                <Typography>*/}
                {/*                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_DETAILS')}*/}
                {/*                </Typography>*/}
                {/*            </Box>*/}
                {/*        </Grid>*/}
                {/*        <Grid item xs={5}>*/}
                {/*            <Button*/}
                {/*                variant='outlined'*/}
                {/*                sx={{*/}
                {/*                    border: '2px solid #1D8B45',*/}
                {/*                    "&:hover": {*/}
                {/*                        border: '2px solid #1D8B45',*/}
                {/*                        filter: 'brightness(0.9)'*/}
                {/*                    }*/}
                {/*                }}*/}
                {/*                onClick={handleDownInvoice}*/}
                {/*            >*/}
                {/*                <Typography style={{*/}
                {/*                    textTransform: 'none',*/}
                {/*                    fontSize: '14px',*/}
                {/*                    color: '#1D8B45',*/}
                {/*                }}>*/}
                {/*                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}*/}
                {/*                </Typography>*/}
                {/*            </Button>*/}
                {/*        </Grid>*/}
                {/*    </>*/}
                {/*}*/}
            </Grid>
        </Box>
    )
}