import { PublicClientApplication } from "@azure/msal-browser";

import { MSAL_CONFIG } from "./azure-authentication-config";

export class AzureAuthenticationContext {
  constructor() {
    this.account = null;
    this.setRequestObjects();
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.isAuthenticationConfigured = false;
    if (MSAL_CONFIG?.auth?.clientId) {
      this.isAuthenticationConfigured = true;
    }
  }

  setRequestObjects() {
    this.loginRequest = {
      scopes: [],
      prompt: "select_account",
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };
  }

  login(signInType, setUser) {
    if (signInType === "loginPopup") {
      this.myMSALObj
        .loginPopup(this.loginRequest)
        .then((resp) => {
          this.handleResponse(resp, setUser);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (signInType === "loginRedirect") {
      this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }
  }

  logout(account) {
    const logOutRequest = {
      account,
    };

    this.myMSALObj.logout(logOutRequest);
  }
  handleResponse(response, incomingFunction) {
    if (response !== null && response.account !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      // incomingFunction(this.account);
      incomingFunction({
        email: this.account.username,
        accessToken: response.idToken,
      });
    }
  }
  getAccount() {
    console.log(`loadAuthModule`);
    const currentAccounts = this.myMSALObj.getAllAccounts();
    if (currentAccounts === null) {
      console.log("No accounts detected");
      return undefined;
    }

    if (currentAccounts.length > 1) {
      console.log(
        "Multiple accounts detected, need to add choose account code."
      );
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  }
}

export default AzureAuthenticationContext;
