import React, {useState, useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import WidgetsIcon from "@material-ui/icons/Widgets";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import MenuIcon from "@material-ui/icons/Menu";
import "../../styles/styles.css";
import axios from "axios";
import {getAccessToken, deleteToken} from "../../Utils/doToken";
import {useHistory} from "react-router-dom";
import {deleteUserInfo, updateUserInfo} from "../../slices";
import {redirectHome, redirectOrderList} from "../../Utils/redirect";
import Calculator from "../Images/icons/calculator";
import {clearLandingEstimateTypeService} from "../../actions/landingEstimateAction";
import {BACKEND_ME_API, NODE_ROUTE_URI} from "../../Utils/apiUrl";
import CloseIcon from "@material-ui/icons/Close";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider, IconButton,
    Link, Menu,
    MenuItem,
    Select
} from "@mui/material";
import {ReactComponent as AccountLogo} from "../../images/homepageIcon/Account.svg";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {alpha} from "@mui/material/styles";
import {styled} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import intl, {getInitOptions} from "react-intl-universal";
import enUS from '../../../src/locales/en-US.json';
import zhCN from '../../../src/locales/zh-CN.json';
import Cookies from 'js-cookie';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {makeStyles} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    logo: {
        flexGrow: 1,
    },
    button: {
        borderRadius: 20,
        borderColor: "green",
        color: "green",
        fontSize: "14pt",
        flexGrow: 1,
    },
    userButton: {
        borderRadius: 25,
        borderColor: "green",
        color: "#FFFFFF",
        backgroundColor: 'green',
        fontSize: "14pt",
        marginRight: "2vw",
        padding: '5px 20px',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
    userButton2: {
        borderRadius: 25,
        borderColor: "green",
        color: "#000000",
        backgroundColor: '#FCBB10',
        fontSize: "14pt",
        marginRight: "2vw",
        padding: '5px 20px',
        '&:hover': {
            backgroundColor: '#FCBB10',
            opacity: '0.8'
        }
    },
    appBarSpacer: {
        height: "70px",
    },
    hideGoBackArrow: {
        display: "none",
    },
    displayGoBackArrow: {
        display: "block",
    },
}));

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(5),
        width: 200,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '10px 0',
        },
        '& .MuiMenuItem-root': {
            padding: '5px 30px',
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

const LOCALES_LIST = [
    {
        label: "English",
        value: "en-US",
    },
    {
        label: "简体中文",
        value: "zh-CN",
    },
];

const LOCALE_DATA = {
    "en-US": enUS,
    "zh-CN": zhCN,
}

const UserNavBar = ({deferredPrompt, setDeferredPrompt, openMenu, cbHandleOpenMenu}) => {

    const currentLocation = useLocation();
    // const forceUpdate = useForceUpdate();
    const ownerRole = useSelector(state => state?.user?.ownerRole);

    const isMobile = useMediaQuery('(max-width: 480px)');

    const [showClassName, setShowClassName] = useState("hideGoBackArrow");
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();

    const location = useLocation();
    const pathName = location.pathname;

    const userInfo = useSelector((state) => state.user);
    const role = useSelector((state) => state.user.role);
    const isLoading = useSelector((state) => state.user.isLoading);

    const userRole = role || "End User";

    const matches = useMediaQuery("(max-width:800px)");

    const storedToken = getAccessToken("access_token");

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [accountAnchorEl, setAccountAnchorEl] = useState(null);
    const [openLanguage, setOpenLanguage] = useState(false);

    // const [deferredPrompt, setDeferredPrompt] = useState(null);

    const handleLanguageDialogClose = () => {
        setOpenLanguage(false);
        // setContactEmail('');
    };

    const handleLanguageDialogOpen = () => {
        setOpenLanguage(true);
        // setContactEmail();
        // setContactEmail(userInfo?.email);
    };

    const openAccount = Boolean(accountAnchorEl);
    const handleClickAccount = (event) => {
        setAccountAnchorEl(event.currentTarget);
    };
    const handleCloseAccount = () => {
        setAccountAnchorEl(null);
    };

    useEffect(() => {

        if (storedToken) {
            // Make an API call to validate the token
            axios.get(BACKEND_ME_API, {
                headers: {Authorization: `Bearer ${storedToken}`},
            })
                .then(() => {
                    // Token is valid, set the isAuthenticated state to true
                    setIsAuthenticated(true);
                })
                .catch((error) => {
                    if (error.response.status !== 401) {
                        deleteToken('access_token')
                    }
                    // Token is not valid, remove it from local storage and redirect to login page
                    setIsAuthenticated(false);
                })
        } else {
            // Token is not present, set the isAuthenticated state to false
            setIsAuthenticated(false);
        }
    }, [currentLocation]);

    useEffect(() => {
        if (
            currentLocation.pathname === "/driver/available_shipment_tasks" ||
            currentLocation.pathname === "/driver/claimed_shipment_tasks" ||
            currentLocation.pathname === "/driver/pickup_shipment_tasks" ||
            currentLocation.pathname === "/driver/deliver_shipment_tasks" ||
            currentLocation.pathname === "/driver" ||
            currentLocation.pathname === "/sign-in" ||
            currentLocation.pathname === "/" ||
            currentLocation.pathname === "/guest-checkout" ||
            currentLocation.pathname === "/guest-order-details"
        ) {
            setShowClassName("hideGoBackArrow");
        } else {
            setShowClassName("displayGoBackArrow");
        }
    }, [currentLocation]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const redirectToProfile = () => {
        handleClose();
        let path = "/profile";
        history.push(path);
    };

    // let login = {
    //     email: null,
    //     password: null
    // }

    const signOut = (e) => {
        e.preventDefault();
        try {
            axios.get(`https://admin.uucargo.ca/api/logout`, {
                headers: {Authorization: `Bearer ${storedToken}`},
            });
            //setHide(true)
        } finally {
            deleteToken("access_token");
            dispatch(deleteUserInfo());
            handleClose();
            dispatch(updateUserInfo({field: "login", value: false}));
            dispatch(updateUserInfo({field: "signIn", value: false}));
            history.push("/");
        }
    };

    const redirectSupport = () => {
        window.open("https://www.uucargo.ca/support/", "_blank");
    };

    const redirectLiveChat = () => {
        window.open(
            "https://chat.uucargo.ca/index.php/chat/startchat/(department)/1/",
            "_blank"
        );
    };

    const handleGoBack = (event) => {
        history.goBack();
    };

    const installApp = () => {
        // Hide the app provided install promotion
        // hideInstallPromotion();
        // Show the install prompt
        if (deferredPrompt) {
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            // const { outcome } = await deferredPrompt.userChoice;
            // Optionally, send analytics event with outcome of user choice
            // console.log(`User response to the install prompt: ${outcome}`);
            // We've used the prompt, and can't use it again, throw it away
            setDeferredPrompt(null);
            handleClose();
        }
    };

    let menu = (
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            style={{width: "500px", textAlign: "center"}}
        >
            {matches && (
                <div>
                    <MenuItem
                        onClick={() => {
                            history.push("/driver/available_shipment_tasks");
                            handleClose();
                        }}
                    >
                        Search Shipping Tasks
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.push("/driver/claimed_shipment_tasks");
                            handleClose();
                        }}
                    >
                        My Shipping Tasks
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.push("/driver/pickup_shipment_tasks");
                            handleClose();
                        }}
                    >
                        Go to Pickup
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.push("/driver/deliver_shipment_tasks");
                            handleClose();
                        }}
                    >
                        Go to Deliver
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            history.push("/driver/bookkeeper");
                            handleClose();
                        }}
                    >
                        My Bookkeeper
                    </MenuItem>
                    <Divider/>
                </div>
            )}
            <MenuItem
                onClick={() => {
                    redirectLiveChat();
                    handleClose();
                }}
            >
                Live Chat
            </MenuItem>
            <MenuItem
                onClick={() => {
                    redirectSupport();
                    handleClose();
                }}
            >
                Support
            </MenuItem>
            <MenuItem onClick={installApp}>Add To Home Screen</MenuItem>
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
        </Menu>
    );

    if (userRole === "End User")
        menu = (
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                style={{width: "500px", textAlign: "center"}}
            >
                {matches && (
                    <MenuItem
                        onClick={() => {
                            history.push("/shipping/start-a-quote");
                            handleClose();
                        }}
                    >
                        Start A Quote
                    </MenuItem>
                )}
                {matches && (
                    <MenuItem
                        onClick={() => {
                            redirectOrderList(history);
                            handleClose();
                        }}
                    >
                        My Orders
                    </MenuItem>
                )}
                {matches && <Divider/>}
                {/* <MenuItem
          onClick={() => {
            handleClose();
            history.push("/settings");
          }}
        >
          Settings
        </MenuItem> */}
                <MenuItem
                    onClick={() => {
                        redirectLiveChat();
                        handleClose();
                    }}
                >
                    Live Chat
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        redirectSupport();
                        handleClose();
                    }}
                >
                    {/* <Typography variant='subtitle2'>
                    <a href="mailto:support@uucargo.ca">Support</a>
                    </Typography> */}
                    Support
                </MenuItem>
                <Divider/>
                <MenuItem onClick={installApp}>Add To Home Screen</MenuItem>
                <MenuItem onClick={signOut}>Sign Out</MenuItem>
            </Menu>
        );

    if (isLoading && storedToken) menu = "";

    if (!isAuthenticated)
        menu = (
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                style={{width: "500px", textAlign: "center"}}
            >
                {(matches && (pathName === '/sign-in' || pathName === '/sign-up')) && (
                    <MenuItem
                        onClick={() => {
                            history.push("/shipping-rate-calculator");
                            dispatch(clearLandingEstimateTypeService());
                            handleClose();
                        }}
                    >
                        Shipping Rate Calculator
                    </MenuItem>
                )}
                {(matches && pathName === '/shipping-rate-calculator') && (
                    <MenuItem
                        onClick={() => {
                            history.push("/sign-up");
                            handleClose();
                        }}
                    >
                        Sign Up
                    </MenuItem>
                )}
                {(matches && pathName === '/shipping-rate-calculator') && (
                    <MenuItem
                        onClick={() => {
                            history.push("/sign-in");
                            handleClose();
                        }}
                    >
                        Sign In
                    </MenuItem>
                )}
                {matches && <Divider/>}
                <MenuItem onClick={installApp}>Add To Home Screen</MenuItem>
                <MenuItem
                    onClick={() => {
                        redirectLiveChat();
                        handleClose();
                    }}
                >
                    Live Chat
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        redirectSupport();
                        handleClose();
                    }}
                >
                    {/* <Typography variant='subtitle2'>
                    <a href="mailto:support@uucargo.ca">Support</a>
                    </Typography> */}
                    Support
                </MenuItem>
            </Menu>
        );

    let linkList = (
        <>
            <IconButton
                aria-label="Search Shipping Tasks"
                aria-haspopup="true"
                onClick={() => history.push("/driver/available_shipment_tasks")}
                color="inherit"
                className={classes.button}
            >
                <SearchIcon/>
                <Typography variant="subtitle2">Search Shipping Tasks</Typography>
            </IconButton>
            <IconButton
                aria-label="Shipments Overview"
                aria-haspopup="true"
                onClick={() => history.push("/driver/claimed_shipment_tasks")}
                color="inherit"
                className={classes.button}
            >
                <WidgetsIcon/>
                <Typography variant="subtitle2">My Shipping Tasks</Typography>
            </IconButton>
            <IconButton
                aria-label="Load Shipments"
                aria-haspopup="true"
                onClick={() => history.push("/driver/pickup_shipment_tasks")}
                color="inherit"
                className={classes.button}
            >
                <LocalShippingIcon/>
                <Typography variant="subtitle2">Go to Pickup</Typography>
            </IconButton>
            <IconButton
                aria-label="Deliver Shipments"
                aria-haspopup="true"
                onClick={() => history.push("/driver/deliver_shipment_tasks")}
                color="inherit"
                className={classes.button}
            >
                <MarkunreadMailboxIcon/>
                <Typography variant="subtitle2">Go to Deliver</Typography>
            </IconButton>
            <IconButton
                aria-label="Bookkeeper"
                aria-haspopup="true"
                onClick={() => history.push("/driver/bookkeeper")}
                color="inherit"
                className={classes.button}
            >
                <Calculator width="26" fill="seagreen"/>
                <Typography variant="subtitle2">My Bookkeeper</Typography>
            </IconButton>
        </>
    );

    if (userRole === "End User")
        linkList = (
            <Box>
                <IconButton
                    aria-label="Deliver Shipments"
                    aria-haspopup="true"
                    onClick={() => history.push("/rating")}
                    color="inherit"
                    className={classes.userButton}
                >
                    <LocalShippingIcon/>
                    <Typography variant="subtitle2">Start a Quote</Typography>
                </IconButton>
                <IconButton
                    aria-label="Load Shipments"
                    aria-haspopup="true"
                    onClick={() => history.push('/my-shipping-orders')}
                    color="inherit"
                    className={classes.userButton}
                >
                    <ListAltIcon/>
                    <Typography variant="subtitle2">My Orders</Typography>
                </IconButton>
                {/* {!hide && (<IconButton
            aria-label="Sign Out"
            aria-haspopup="true"
            onClick={signOut}
            color="inherit"
            className={classes.userButton}
            >

                <Typography variant='subtitle2'>Sign Out</Typography>
            </IconButton>)} */}
            </Box>
        );

    if (isLoading && storedToken) linkList = "";

    if (!isAuthenticated)
        linkList = (
            <Box>
                {pathName === '/shipping-rate-calculator' ?
                    <Box>
                        <IconButton
                            aria-label="Sign Up"
                            aria-haspopup="true"
                            onClick={() => history.push("/sign-up")}
                            color="inherit"
                            className={classes.userButton2}
                        >
                            <Typography variant="subtitle2">SIGN UP</Typography>
                        </IconButton>
                        <IconButton
                            aria-label="Sign In"
                            aria-haspopup="true"
                            onClick={() => history.push("/sign-in")}
                            color="inherit"
                            className={classes.userButton}
                        >
                            <Typography variant="subtitle2">SIGN IN</Typography>
                        </IconButton>
                    </Box> :
                    <IconButton
                        onClick={() => {
                            history.push("/shipping-rate-calculator");
                            dispatch(clearLandingEstimateTypeService());
                        }}
                        className={classes.userButton}
                    >
                        <Typography variant="subtitle2">SHIPPING RATE CALCULATOR</Typography>
                    </IconButton>
                }
                {/* <IconButton
            aria-label="Support"
            aria-haspopup="true"
            color="inherit"
            className={classes.userButton}
            >
                <Typography variant='subtitle2'>
                   <a href="mailto:support@uucargo.ca">Support</a>
                </Typography>
            </IconButton> */}
            </Box>
        );

    const savePreferredLanguage = (language) => {
        const token = getAccessToken("access_token");
        let requestURI = `${NODE_ROUTE_URI}/login_middleware/savePreferredLanguage`;
        const data = {
            preferredLanguage: language
        }
        axios
            .post(requestURI, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(r => console.log('response', r))
            .catch(err => console.log('error', err))
    }

    let selectedLanguage = getInitOptions().currentLocale;

    const onLocaleChange = (e) => {
        Cookies.set('lang', e.target.value, {expires: 30});
        savePreferredLanguage(e?.target?.value);
        handleLanguageDialogOpen();
    }

    useEffect(() => {
        handleCloseAccount()
    }, [currentLocation]);

    if (!isAuthenticated) {
        return <></>
    }

    return (
        // <>
        <AppBar
            position="fixed"
            // className="AppBar"
            style={{background: "#1D8B45", top: 0, margin: 0}}
        >
            <Toolbar style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                {
                    isMobile &&
                    <IconButton
                        onClick={cbHandleOpenMenu}
                    >
                        {
                            openMenu ? <CloseIcon style={{color: '#FFFFFF'}}/>
                                :
                                <MenuIcon style={{color: '#FFFFFF'}}/>
                        }
                    </IconButton>
                }
                <Link className={classes.logo} to="/">
                    <img
                        style={{height: "50px", padding: "5px 5px"}}
                        src='https://storage.googleapis.com/uucargo-resource/image/icon/UUCargoLogo.svg'
                        // src={logo}
                        onClick={() => history.push('/')}
                        alt="useAvatar"
                    />
                </Link>
                <Box sx={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                    <FormControl>
                        {/*<InputLabel sx={{color: '#FFFFFF'}}>{intl.get('LANGUAGE')}</InputLabel>*/}
                        <Select
                            value={selectedLanguage}
                            size='small'
                            sx={{
                                fontSize: 14,
                                // backgroundColor: '#FFFFFF'
                                color: '#FFFFFF'
                            }}
                            onChange={onLocaleChange}
                            // label="Language"
                            // label={intl.get('LANGUAGE')}
                        >
                            <MenuItem value='en-US'>English-EN</MenuItem>
                            <MenuItem value='zh-CN'>中文(简体)-ZH</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        isAuthenticated ?
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: "0.3rem",
                                marginRight: "2rem",
                                position: 'relative'
                            }}>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    // onClick={redirectToProfile}
                                    onClick={handleClickAccount}
                                >
                                    <AccountLogo width="30" height="30"/>
                                    {openAccount ? <ExpandLessIcon sx={{color: '#FFFFFF'}}/> :
                                        <ExpandMoreIcon sx={{color: '#FFFFFF'}}/>}
                                </IconButton>
                                <StyledMenu
                                    anchorEl={accountAnchorEl}
                                    open={openAccount}
                                    onClose={handleCloseAccount}
                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                    PaperProps={{
                                        sx: {
                                            top: '50px'
                                        }
                                    }}
                                >
                                    <Typography style={{
                                        color: '#454545',
                                        fontSize: '16px',
                                        fontWeight: '600',
                                        textAlign: 'left',
                                        padding: '0 30px',

                                    }}>
                                        {userInfo?.firstname} {userInfo?.lastname}
                                    </Typography>
                                    <Typography style={{
                                        color: '#B2B2B2',
                                        fontSize: '14px',
                                        textAlign: 'left',
                                        padding: '0 30px',
                                    }}>
                                        {userInfo?.email}
                                    </Typography>
                                    <Box sx={{
                                        width: '95%',
                                        margin: '10px auto'
                                    }}>
                                        <Divider/>
                                    </Box>
                                    <MenuItem
                                        onClick={() => {
                                            history.push(`${ownerRole === 1 ? '/' : '/business/'}profile`);
                                        }}
                                    >
                                        <Typography style={{
                                            color: '#454545',
                                            fontSize: '14px',
                                            // fontWeight: '600'
                                        }}>
                                            {intl.get('NAVBAR_PROFILE')}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            history.push(`${ownerRole === 1 ? '/' : '/business/'}address-book`);
                                        }}
                                    >
                                        <Typography style={{
                                            color: '#454545',
                                            fontSize: '14px',
                                            // fontWeight: '600'
                                        }}>
                                            {intl.get('NAVBAR_ADDRESS_BOOK')}
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            history.push(`${ownerRole === 1 ? '/' : '/business/'}billing-center`);
                                        }}
                                    >
                                        <Typography style={{
                                            color: '#454545',
                                            fontSize: '14px',
                                            // fontWeight: '600'
                                        }}>
                                            {intl.get('NAVBAR_BILLING')}
                                        </Typography>
                                    </MenuItem>
                                    {/*{*/}
                                    {/*    ownerRole === 1 &&*/}
                                    {/*    <MenuItem onClick={() => history.push('/become-a-business')}>*/}
                                    {/*        <Typography style={{*/}
                                    {/*            color: '#454545',*/}
                                    {/*            fontSize: '14px',*/}
                                    {/*            // fontWeight: '600'*/}
                                    {/*        }}>*/}
                                    {/*            {intl.get('NAVBAR_BECOME_BUSINESS')}*/}
                                    {/*        </Typography>*/}
                                    {/*    </MenuItem>*/}
                                    {/*}*/}
                                    <MenuItem
                                        onClick={installApp}
                                    >
                                        <Typography style={{
                                            color: '#454545',
                                            fontSize: '14px',
                                            // fontWeight: '600'
                                        }}>
                                            Add To Home Screen
                                        </Typography>
                                    </MenuItem>
                                    <Box sx={{
                                        width: '95%',
                                        margin: '10px auto'
                                    }}>
                                        <Divider/>
                                    </Box>
                                    <MenuItem
                                        onClick={signOut}
                                    >
                                        <Typography style={{
                                            color: '#454545',
                                            fontSize: '14px',
                                            // fontWeight: '600'
                                        }}>
                                            {intl.get('NAVBAR_LOGOUT')}
                                        </Typography>
                                    </MenuItem>
                                </StyledMenu>
                            </Box> :
                            <Box display="flex" justifyContent="center" alignItems="center"
                                 sx={{gap: "0.3rem", marginRight: "2rem"}}>
                                {
                                    pathName === '/shipping-rate-calculator' ?
                                        <Box sx={{
                                            display: 'flex',
                                            gap: '10px'
                                        }}>
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    color: '#FFFFFF',
                                                    backgroundColor: '#FDB915',
                                                    borderRadius: '25px',
                                                    '&:hover': {
                                                        backgroundColor: '#FDB915',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={() => history.push('/sign-up')}
                                            >
                                                <Typography style={{fontSize: isMobile ? '10px' : '16px'}}>
                                                    {intl.get('NAVBAR_SIGN_UP')}
                                                </Typography>
                                            </Button>
                                            <Button
                                                variant='contained'
                                                sx={{
                                                    color: '#1D8B45',
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '25px',
                                                    '&:hover': {
                                                        backgroundColor: '#FFFFFF',
                                                        filter: 'brightness(0.9)'
                                                    }
                                                }}
                                                onClick={() => history.push('/sign-in')}
                                            >
                                                <Typography style={{fontSize: isMobile ? '10px' : '16px'}}>
                                                    {intl.get('NAVBAR_SIGN_IN')}
                                                </Typography>
                                            </Button>
                                        </Box>

                                        : <Button
                                            variant='contained'
                                            sx={{
                                                color: '#1D8B45',
                                                backgroundColor: '#FFFFFF',
                                                borderRadius: '25px',
                                                '&:hover': {
                                                    backgroundColor: '#FFFFFF',
                                                    filter: 'brightness(0.9)'
                                                }
                                            }}
                                            onClick={() => {
                                                history.push('/shipping-rate-calculator');
                                                dispatch(clearLandingEstimateTypeService());
                                            }}
                                        >
                                            <Typography style={{fontSize: isMobile ? '10px' : '16px'}}>
                                                {intl.get('NAVBAR_SHIPPING_RATE_CALCULATOR')}
                                            </Typography>
                                        </Button>
                                }
                            </Box>
                    }
                </Box>
            </Toolbar>
            <Dialog
                open={openLanguage}
                onClose={handleLanguageDialogClose}
            >
                <DialogTitle>
                    {intl.get('NAVBAR_LANGUAGE_TITLE')}
                </DialogTitle>
                <DialogContent>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        <Typography>
                            {intl.get('NAVBAR_LANGUAGE_CONTENT')}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleLanguageDialogClose}
                    >
                        {intl.get('NAVBAR_LANGUAGE_NO')}
                    </Button>
                    <Button
                        onClick={() => window.location.reload()}
                    >
                        {intl.get('NAVBAR_LANGUAGE_CONFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
        </AppBar>
    );
};
export default UserNavBar;
