import {GET_PARTNER_INFO_API, NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";
import {useEffect, useState} from "react";
import {useInterval} from "usehooks-ts";
import Box from "@mui/material/Box";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import * as React from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import {Button, MenuItem, Select, Snackbar} from "@mui/material";
import {ShippingOrderDetailsCreditCard} from "../../ShippingOrderDetails/NewDesigin/ShippingOrderDetailsCreditCard";
import {ShippingOrderDetailsWeChat} from "../../ShippingOrderDetails/NewDesigin/ShippingOrderDetailsWeChat";
import {ShippingOrderDetailsAliPay} from "../../ShippingOrderDetails/NewDesigin/ShippingOrderDetailsAliPay";
import {ShippingOrderDetailsPayPal} from "../../ShippingOrderDetails/NewDesigin/ShippingOrderDetailsPayPal";
import {EndUserSchedulePickupCreditCard} from "./EndUserSchedulePickupCreditCard";
import {EndUserSchedulePickupWechat} from "./EndUserSchedulePickupWechat";
import {EndUserSchedulePickupAliPay} from "./EndUserSchedulePickupAliPay";
import MuiAlert from "@mui/material/Alert";
import {mapCarrierLogo} from "../../../Utils/Helper";
import {useHistory} from "react-router-dom";
import {greenButtonContained} from "../../../Utils/styleConfig";
import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import {EndUserSchedulePickupAvailableCredit} from "./EndUserSchedulePickupAvailableCredit";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

export const EndUserSchedulePickupPayment = ({
                                                 pickupDetails,
                                                 handleSchedulePickupRequest,
                                                 handleEdit,
                                                 appointmentId,
                                                 cbSetAppointmentId,
                                                 errorCount,
                                                 handleErrorCount
                                             }) => {

    const accessToken = getAccessToken("access_token");
    const history = useHistory();

    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [delay, setDelay] = useState(2000);
    const [appointmentStatus, setAppointmentStatus] = useState(-1);
    const [selectPayment, setSelectPayment] = useState('card');
    const [appointmentError, setAppointmentError] = useState(null);
    const [activeLoading, setActiveLoading] = useState(false);
    const [availableCredit, setAvailableCredit] = useState(0);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleSelectPayment = (event) => {
        setSelectPayment(event?.target?.value);
        // cbHandlePayment(method);
    }

    const getAvailableCredit = async () => {
        let requestURL = `${NODE_ROUTE_URI}/billingCenter/accountDetail`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit result', result);
            const {data: {absolute_creditnote, current_bill_balance, not_billed_amount, not_paid_amount}} = result;
            setAvailableCredit(current_bill_balance);
            // setAvailableCredit(20); // test
        } catch (e) {
            console.log('[ShippingOrderDetailsPayment] - getAvailableCredit error', e.response);
        }
    }

    const getPickupAppointmentDetailsByAppointmentId = async () => {

        const requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/getPickupAppointmentByAppointmentId`;

        try {

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    appointmentId: pickupDetails?.id
                }
            })

            console.log('[getPickupAppointmentDetailsByAppointmentId] result', result);
            setAppointmentStatus(result?.data?.status);

        } catch (e) {
            console.log('[getPickupAppointmentDetailsByAppointmentId] error', e?.response);
        }
    }

    const activateSchedulePickupAppointmentByAppointmentId = async () => {
        cbSetAppointmentId(pickupDetails?.id);
        setActiveLoading(true);
        const requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/activateSchedulePickupAppointmentByAppointmentId`;
        try {

            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    appointmentId: pickupDetails?.id
                }
            })

            console.log('[activateSchedulePickupAppointmentByAppointmentId] result', result);
            setAppointmentStatus(result?.data?.status);
            setErrorMessage('');
            setSuccessMessage(pickupDetails?.account_base === 'CANPAR' ? 'Successfully Send Pickup Request' : 'Successfully schedule a pickup.');
            handleSuccessMessage();
            setAppointmentError(false);
        } catch (e) {
            console.log('[activateSchedulePickupAppointmentByAppointmentId] error', e?.response);
            const errorMessage = e?.response?.data?.error;
            const errorStatus = e?.response?.data?.status;
            console.log('[activateSchedulePickupAppointmentByAppointmentId] errorStatus', errorStatus);
            setAppointmentError(true);
            setErrorMessage(errorMessage || "");
            handleErrorCount();
            handleErrorMessage();
        } finally {
            setActiveLoading(false);
        }
    }

    const handleSendRequest = async () => {
        await handleSchedulePickupRequest(errorMessage);
    }

    useEffect(() => {
        (async () => await getAvailableCredit()
        )()
    }, []);

    useEffect(() => {
        getPickupAppointmentDetailsByAppointmentId();
    }, [pickupDetails]);

    useEffect(() => {
        if (appointmentStatus === 100) {
            activateSchedulePickupAppointmentByAppointmentId()
        }
    }, [appointmentStatus]);

    useInterval(
        () => {
            if (pickupDetails) {
                getPickupAppointmentDetailsByAppointmentId();
            }
        },
        // Delay in milliseconds or null to stop it
        appointmentStatus === 0 ? delay : null,
    )

    if (appointmentStatus === 1) {
        return (
            <Box sx={{marginBottom: '40px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {
                            pickupDetails?.account_base === 'CANPAR' ?
                                <Typography sx={{textAlign: 'center', fontSize: '18px'}}>
                                    You have successfully scheduled a pickup request. UUCargo will handle your pickup
                                    request for you with Canpar. You will receive the
                                    confirmation email from UUCargo.
                                </Typography>
                                :
                                <Typography sx={{textAlign: 'center', fontSize: '18px'}}>
                                    You have successfully scheduled a pickup.
                                </Typography>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={() => history.push('/MyScheduledPickups')}
                            >
                                <Typography sx={{textTransform: 'none'}}>
                                    Scheduled Pickup List
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    if (appointmentError) {
        return (
            <Box sx={{marginBottom: '40px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{
                            textAlign: 'center', fontSize: '18px', marginBottom: '20px'
                        }}>
                            {
                                errorMessage ? `You have successfully paid for the scheduled pickup, but your request has failed due to the following reason: "${errorMessage}" Please review and modify the pickup information, then try submitting your request again.` :
                                    `You have successfully paid for the scheduled pickup, but your request has failed. Please review and modify the pickup information, then try submitting your request again.`
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}>
                            <Button variant='contained' sx={greenButtonContained} onClick={handleEdit}>
                                <Typography sx={{textTransform: 'none'}}>
                                    Modify Pickup
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                    {
                        errorCount > 1 &&
                        <>
                            <Grid item xs={12}>
                                <Typography sx={{
                                    textAlign: 'center', fontSize: '18px', marginBottom: '20px'
                                }}>
                                    If you continue to experience issues, please press the button below, and UUCargo
                                    will handle
                                    your pickup request. You will receive a confirmation email from UUCargo.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}>
                                    <LoadingButton
                                        variant='contained'
                                        sx={greenButtonContained}
                                        onClick={handleSendRequest}>
                                        <Typography sx={{textTransform: 'none'}}>
                                            Pickup Request
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Grid>
                        </>
                    }
                </Grid>
            </Box>
        )
    }

    if (!activeLoading && appointmentStatus === 100 && pickupDetails?.account_base?.toUpperCase() === 'CANPAR') {
        return (
            <Box sx={{marginBottom: '40px'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{textAlign: 'center', fontSize: '18px'}}>
                            You have successfully paid for the scheduled pickup.
                            UUCargo will handle your pickup request for you with Canpar. You will receive the
                            confirmation email from UUCargo.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    if (activeLoading) {
        return (
            <Box sx={{marginBottom: '40px', display: 'flex', justifyContent: 'center'}}>
                <CircularProgress/>
            </Box>
        )
    }

    // console.log('[EndUserSchedulePickupPayment] appointment status', appointmentStatus);
    // console.log('[EndUserSchedulePickupPayment] pickup details', pickupDetails);
    // console.log('[EndUserSchedulePickupPayment] error count', errorCount);

    return (
        <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Box sx={{
                display: 'flex',
                gap: '10px',
                padding: '20px 20px 0 20px'
            }}>
                <ShipmentOverviewIcon width={25} height={25}/>
                <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                    Payment Methods
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{
                backgroundColor: '#FFFFFF',
                padding: '0 40px',
                gap: '40px',
                display: 'flex',
            }}>
                <Grid container spacing={1}>
                    {
                        (pickupDetails?.id !== appointmentId && appointmentId) &&
                        <Grid item xs={12}>
                            <Typography>
                                We have created a new pickup for you, but were unable to process the update for your
                                previous pickup. You can cancel the previous pickup and receive a refund if payment has
                                been made.
                            </Typography>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: '600'}}>
                            Total charge
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <img src={mapCarrierLogo(pickupDetails?.account_base)} alt="logo" width='45px'/>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <Typography sx={{textAlign: 'center'}}>
                                {pickupDetails?.account_base}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <Box sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}>
                            <Typography sx={{textAlign: 'right', color: '#1D8B45', fontWeight: '600'}}>
                                ${(+pickupDetails?.totalChargeWithTaxes)?.toFixed(2)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{fontWeight: '600'}}>
                            Select your payment method
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Select
                                value={selectPayment}
                                size='small'
                                onChange={handleSelectPayment}
                            >
                                <MenuItem value='credit'>
                                    UUCargo Credit (available credit&nbsp;<span
                                    style={{fontWeight: '600'}}>${(+availableCredit)?.toFixed(2)}</span>)
                                </MenuItem>
                                <MenuItem value='card'>
                                    Credit Card
                                </MenuItem>
                                <MenuItem value='wechatPay'>
                                    WeChat Pay
                                </MenuItem>
                                <MenuItem value='aliPay'>
                                    Ali Pay
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            selectPayment === 'credit' &&
                            <EndUserSchedulePickupAvailableCredit pickupDetails={pickupDetails}
                                                                  activeLoading={activeLoading}
                                                                  availableCredit={availableCredit}/>
                        }
                        {
                            selectPayment === 'card' &&
                            <EndUserSchedulePickupCreditCard pickupDetails={pickupDetails}
                                                             activeLoading={activeLoading}/>
                        }
                        {
                            selectPayment === 'wechatPay' &&
                            <EndUserSchedulePickupWechat pickupDetails={pickupDetails}/>
                        }
                        {
                            selectPayment === 'aliPay' &&
                            <EndUserSchedulePickupAliPay pickupDetails={pickupDetails}/>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
