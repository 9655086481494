import Box from "@mui/material/Box";
import {Button, Tab, Tabs} from "@mui/material";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {EstimatePackage} from "./EstimatePackage";
import {EstimatePallet} from "./EstimatePallet";
import {useDispatch, useSelector} from "react-redux";
import {
    getLocation, getMissingKeysForLandingBox,
    getMissingKeysForLandingPackage,
    getMissingKeysForLandingPallet, isValidateEmail, isValidPostalCode, isValidZipCode, loadAsyncScript
} from "../../Utils/Helper";
import {
    clearLandingEstimateBoxCost,
    clearLandingEstimatePackageCost,
    clearLandingEstimatePalletCost,
    updateLandingClickEstimate,
    updateLandingEstimateBoxCost,
    updateLandingEstimateBoxMissing,
    updateLandingEstimateCostLoading,
    updateLandingEstimateDeliverPostalCodeValid,
    updateLandingEstimatePackageCost,
    updateLandingEstimatePackageMissing,
    updateLandingEstimatePalletCost,
    updateLandingEstimatePalletMissing,
    updateLandingEstimatePickupPostalCodeValid,
    updateLandingEstimateTypeService
} from "../../actions/landingEstimateAction";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import getProvinceCodeByName from "../../Utils/getProvinceCode";
import axios from "axios";
import {updateLoadingShipRate, updateRates} from "../../actions/ratingAction";
import {EstimateCost} from "./EstimateCost";
import LoadingContainer from "../shared/loadingContainer";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import {EstimateBox} from "./EstimateBox";
import Grid from "@mui/material/Grid";
import {Link} from "react-router-dom";

const style = {
    EstimateRoot: {
        display: 'flex',
        flexDirection: 'column',
        // maxWidth: '500px',
        // width: '500px',
        width: '50%',
        margin: '0 auto',
        gap: '10px',
        padding: '30px',
        '@media (max-width: 1024px)': {
            width: '100%', // for tablets and above
        },
        '@media (max-width: 480px)': {
            width: '100%',
            padding: 0// for phone and above
        },
    },
    EstimateContent: {
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '30px',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    },
    EstimateContentTabs: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    EstimateContentTabEach: {
        width: '100%'
    },
    EstimateContentButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px'
    },
    EstimateButton: {
        color: '#FFFFFF',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
            opacity: '0.8'
        }
    },
    EstimateWarningText: {
        fontSize: '12px',
        color: '#FF0303'
    },
    EstimateText: {
        textAlign: 'left',
        fontSize: '14px',
    },
    EstimateIntro: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    EstimateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left',
    },
}

export const Estimate = ({handleConfirm}) => {

    const dispatch = useDispatch();

    const [shipmentType, setShipmentType] = useState('package');
    const [estimateType, setEstimateType] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [currentLocation, setCurrentLocation] = useState();
    const [carrierOptionsForPackage, setCarrierOptionsForPackage] = useState([]);

    const handleTabChange = (event, newValue) => {
        setShipmentType(newValue);
        setEmail('');
        setIsValidEmail(true);
        setIsEmailEmpty(false);
        setShowEmail(false);
    }

    let packageData = useSelector(state => state?.landingEstimate?.landingEstimatePackage);
    let palletData = useSelector(state => state?.landingEstimate?.landingEstimatePallet);
    let boxData = useSelector(state => state?.landingEstimate?.landingEstimateBox);

    const validatePickupPostalCode = () => {
        if (packageData?.pickupCountry?.code === 'CA') {
            const result = isValidPostalCode(packageData?.pickupPostalCode);
            console.log('pick up postal code', result);
            dispatch(updateLandingEstimatePickupPostalCodeValid(result));
            return result;

        } else {
            const result = isValidZipCode(packageData?.pickupPostalCode);
            console.log('pick up postal code', result);
            dispatch(updateLandingEstimatePickupPostalCodeValid(result));
            return result;

        }
    }

    const validateDeliverPostalCode = () => {
        if (packageData?.deliverCountry?.code === 'CA') {
            const result = isValidPostalCode(packageData?.deliverPostalCode);
            console.log('deliver postal code', result);
            dispatch(updateLandingEstimateDeliverPostalCodeValid(result));
            return result;

        } else {
            const result = isValidZipCode(packageData?.deliverPostalCode);
            console.log('deliver postal code', result);
            dispatch(updateLandingEstimateDeliverPostalCodeValid(result));
            return result;

        }
    }

    const validatePostalCode = () => {
        const pickUpPostalCode = validatePickupPostalCode();
        const deliverPostalCode = validateDeliverPostalCode();
        return (pickUpPostalCode && deliverPostalCode);
    }

    const validateEmailAddress = async (email) => {
        let requestURL = `${NODE_ROUTE_URI}/validateEmail`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: {
                    email: email,
                    validateRegex: true,
                    validateMx: true,
                    validateTypo: false,
                    validateDisposable: false,
                    validateSMTP: false
                }
            })
            console.log('[validateEmailAddress] result - ', result);
            return result?.data?.valid;
        } catch (e) {
            console.log(e.response)
        }
    }

    const validateEmail = async () => {
        if (email) {
            setIsEmailEmpty(false);
            // const isValid = isValidateEmail(email);
            const isValid = await validateEmailAddress(email);
            if (isValid) {
                setIsValidEmail(true);
                return true;
            } else {
                setIsValidEmail(false);
                return false;
            }
        } else {
            setIsEmailEmpty(true);
            return false
        }
    }

    const addEmail = () => {
        let requestURL = `${NODE_ROUTE_URI}/collectEmail/addEmail`;
        axios.request({
            method: 'post',
            url: requestURL,
            data: {
                senderEmail: email
            }
        }).then((response) => {
            console.log(JSON.stringify(response.data))
        }).catch((error) => {
            console.log(error)
        })
    }

    const endUserGetRatingByAccountBaseWithoutToken = async (accountBase) => {
        setIsLoading(true);
        dispatch(updateLandingEstimateCostLoading(true));
        let requestURL = `${PARTNER_URI}/loose-item/3rd-party/partner/rates/landing`;
        const data = {
            rateData: {
                shipFromAddress: "",
                shipFromAddressTwo: "",
                shipFromCity: "",
                shipFromProvince: packageData?.pickupProvince?.code,
                shipFromPostalCode: packageData?.pickupPostalCode.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: packageData?.pickupCountry?.code,
                shipFromName: "",
                shipFromPhone: "",
                shipToAddress: "",
                shipToAddressTwo: "",
                shipToCity: "",
                shipToPostalCode: packageData?.deliverPostalCode.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: packageData?.deliverProvince?.code,
                shipToCountry: packageData?.deliverCountry?.code,
                shipToName: "",
                shipToPhone: "",
                packageData: [
                    {
                        length: packageData?.length,
                        width: packageData?.width,
                        height: packageData?.height,
                        weight: packageData?.weight,
                        quantity: packageData?.quantity,
                        lengthUnit: packageData?.dimensionUnit,
                        weightUnit: packageData?.weightUnit
                    }
                ]
            },
            selectedAccountBase: accountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })

            const ratingResult = result.data.result;
            console.log(`package rate ${accountBase}`, result.data.result);
            result.data.result.length > 0 && dispatch(updateLandingEstimatePackageCost(ratingResult));
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        }
    }

    const endUserGetRatingByPallet = async () => {
        setIsLoading(true);
        dispatch(updateLandingEstimateCostLoading(true));
        let requestURL = `${PARTNER_URI}/shippingLTL/LTLShippingRatingForLanding`;
        const data = {
            shipperAddress: "",
            shipperAddressType: "warehouse",
            shipperCity: palletData?.pickupCity,
            shipperProvince: palletData?.pickupProvince?.code,
            shipperPostalCode: palletData?.pickupPostalCode,
            shipperCountry: palletData?.pickupProvince?.countryCode,
            consigneeAddress: "",
            consigneeAddressType: "warehouse",
            consigneeCity: palletData?.deliverCity,
            consigneeProvince: palletData?.deliverProvince?.code,
            consigneePostalCode: palletData?.deliverPostalCode,
            consigneeCountry: palletData?.deliverProvince?.countryCode,
            packageData: [
                {
                    length: palletData?.packagingType?.length,
                    height: palletData?.packagingType?.height,
                    width: palletData?.packagingType?.width,
                    quantity: palletData?.quantity,
                    weight: "400",
                    description: "",
                    lengthUnit: "in",
                    weightUnit: "lb",
                    hscode: "",
                    originCountryCode: "",
                    currency: "",
                    priceEach: "",
                    UOM: ""
                }
            ]
        }
        console.log('api data', data)

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            setEstimateType('pallet');
            // const lowestCostElement = result?.data?.reduce((minObj, obj) => {
            //     return obj.finalTotal < minObj.finalTotal ? obj : minObj;
            // }, {finalTotal: Infinity})

            dispatch(updateLandingEstimatePalletCost(result?.data));
            setError(false);
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        } catch (e) {
            console.log(e.response);
            setError(true);
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        }
    }

    const endUserGetRatingByBox = async () => {
        setIsLoading(true);
        dispatch(updateLandingEstimateCostLoading(true));
        let requestURL = `${NODE_ROUTE_URI}/movingExpressService/landing/movingExpressGetRating`;
        const data = {
            items: [{
                description: 'Heavy Box',
                cateId: boxData?.cateId,
                length: boxData?.length,
                width: boxData.width,
                height: boxData.height,
                weight: boxData.weight,
                lengthUnit: boxData.lengthUnit,
                weightUnit: boxData.weightUnit,
                quantity: boxData.quantity
            }],
            pickupWarehouseId: boxData?.pickupWarehouse?.id || 0,
            pickupType: boxData?.pickupOption,
            pickupCity: boxData?.pickupCity,
            pickupProvince: boxData?.pickupProvince?.code,
            pickupCountry: boxData?.pickupCountry?.code,
            pickupPostalCode: boxData?.pickupPostalCode,
            deliverWarehouseId: boxData?.deliverWarehouse?.id || 0,
            deliverType: boxData?.deliverOption,
            deliverCity: boxData?.deliverCity,
            deliverProvince: boxData?.deliverProvince?.code,
            deliverCountry: boxData?.deliverCountry?.code,
            deliverPostalCode: boxData?.deliverPostalCode,
            specialServices: []
        }
        console.log('api data', data)

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                data: data
            })
            setEstimateType('box');
            console.log(result.data)
            dispatch(updateLandingEstimateBoxCost(result.data));
            setError(false);
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        } catch (e) {
            console.log(e.response);
            setError(true);
            setIsLoading(false);
            dispatch(updateLandingEstimateCostLoading(false));
        }
    }

    const handleCallCarrier = (availableService) => {
        if (availableService?.length > 0) {
            for (const service of availableService) {
                endUserGetRatingByAccountBaseWithoutToken(service?.account_base);
            }
        }
    }

    const getPackageEstimateCost = async () => {
        const emailValidation = await validateEmail();
        const postalCodeValidation = validatePostalCode();
        const missingKeys = getMissingKeysForLandingPackage(packageData);
        dispatch(updateLandingEstimatePackageMissing(missingKeys));
        if (missingKeys.length === 0 && emailValidation && postalCodeValidation) {
            addEmail();
            setEstimateType('package');
            dispatch(updateLandingEstimateTypeService('package'));
            dispatch(clearLandingEstimatePackageCost());

            const availableService = await getAllPackageAccountBaseList();

            await handleCallCarrier(availableService);

            // if (packageData?.pickupCountry.code === packageData?.deliverCountry.code) {
            //     endUserGetRatingByAccountBaseWithoutToken('canadapost');
            //     endUserGetRatingByAccountBaseWithoutToken('ups');
            //     endUserGetRatingByAccountBaseWithoutToken('fedex');
            //     endUserGetRatingByAccountBaseWithoutToken('canpar');
            // } else {
            //     endUserGetRatingByAccountBaseWithoutToken('ups');
            // }
            handleConfirm();
        } else {
            console.log('show error')
        }
    }

    const getPalletEstimateCost = async () => {
        const emailValidation = await validateEmail();
        const missingKeys = getMissingKeysForLandingPallet(palletData);
        dispatch(updateLandingEstimatePalletMissing(missingKeys));
        if (missingKeys.length === 0 && emailValidation) {
            addEmail();
            dispatch(updateLandingEstimateTypeService('pallet'));
            dispatch(clearLandingEstimatePalletCost());
            endUserGetRatingByPallet();
            handleConfirm();
        } else {
            console.log('show error')
        }

    }

    const getBoxEstimateCost = async () => {
        const emailValidation = await validateEmail();
        const missingKeys = getMissingKeysForLandingBox(boxData);
        dispatch(updateLandingEstimateBoxMissing(missingKeys));
        if (missingKeys.length === 0 && emailValidation) {
            addEmail();
            dispatch(updateLandingEstimateTypeService('box'));
            dispatch(clearLandingEstimateBoxCost());
            endUserGetRatingByBox();
            handleConfirm();
        } else {
            console.log('error');
        }
    }

    const handleEstimateButton = () => {
        if (shipmentType === 'package') {
            getPackageEstimateCost()
        } else if (shipmentType === 'pallet') {
            getPalletEstimateCost()
        } else {
            getBoxEstimateCost()
        }
    }

    // const estimateSection = useRef(null);
    //
    // const scrollDown = (ref) => {
    //     // console.log(ref.current.offsetTop)
    //     window.scrollTo({
    //         top: ref.current.offsetTop - 100,
    //         behavior: 'smooth',
    //     });
    // };

    // useEffect(() => {
    //     scrollDown(estimateSection)
    // }, [isLoading])

    const getAllPackageAccountBaseList = async () => {
        let requestURL = `${PARTNER_URI}/looseItemRoute/admin/getAllPackageAccountBaseList`;

        try {
            const {data} = await axios({
                method: 'get',
                url: requestURL,
            })

            // console.log('[getAllPackageAccountBaseList] result', result);

            setCarrierOptionsForPackage(data.map(v => v.account_base))

            return data;

        } catch (e) {
            console.log('error', e.response);
        }
    }

    useEffect(() => {
        const missingKeys = getMissingKeysForLandingPackage(packageData);
        if (shipmentType === 'package') {
            missingKeys.length === 0 ? setShowEmail(true) : setShowEmail(false);
        }
    }, [packageData])

    useEffect(() => {
        const missingKeys = getMissingKeysForLandingPallet(palletData);
        if (shipmentType === 'pallet') {
            missingKeys.length === 0 ? setShowEmail(true) : setShowEmail(false);
        }
    }, [palletData])

    useEffect(() => {
        const missingKeys = getMissingKeysForLandingBox(boxData);
        if (shipmentType === 'box') {
            missingKeys.length === 0 ? setShowEmail(true) : setShowEmail(false);
        }
    }, [boxData])

    useEffect(() => {
        if (email) {
            setIsEmailEmpty(false);
            const isValid = isValidateEmail(email);
            setIsValidEmail(isValid);
        } else {
            // setIsEmailEmpty(true);
            setIsValidEmail(true);
        }
    }, [email]);

    console.log('[Estimate] carrierOptionsForPackage', carrierOptionsForPackage);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={style.EstimateHeading}>
                    {intl.get('LANDING_PAGE.CALCULATOR.CALCULATOR')}
                </Typography>
                <Typography style={{textAlign: 'left', color: '#454545', fontSize: '14px'}}>
                    {intl.get('LANDING_PAGE.SIGN_IN.LOGIN_TEXT')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Box sx={style.EstimateContent}>
                    <Box sx={style.EstimateContentTabs}>
                        <Box sx={style.EstimateContentTabEach}>
                            <Tabs
                                value={shipmentType}
                                onChange={handleTabChange}
                                centered
                                variant="fullWidth"
                            >
                                <Tab value="package" label={intl.get('LANDING_PAGE.CALCULATOR.PARCELS')}
                                     sx={{width: '100%'}}/>
                                <Tab value="pallet" label={intl.get('LANDING_PAGE.CALCULATOR.PALLET')}
                                     sx={{width: '100%'}}/>
                                <Tab value="box" label={intl.get('LANDING_PAGE.CALCULATOR.BOX')}
                                     sx={{width: '100%'}}/>
                            </Tabs>
                        </Box>
                    </Box>
                    {
                        shipmentType === 'package' ?
                            <EstimatePackage curentLocation={currentLocation}/> :
                            shipmentType === 'pallet' ?
                                <EstimatePallet/> :
                                <EstimateBox/>
                    }
                    {showEmail &&
                        <Box>
                            <TextField
                                value={email}
                                label={intl.get('LANDING_PAGE.CALCULATOR.EMAIL')}
                                required
                                fullWidth
                                variant="standard"
                                onInput={e => setEmail(e.target.value)}
                                error={isEmailEmpty || !isValidEmail}
                                helperText={isEmailEmpty ? intl.get('LANDING_PAGE.CALCULATOR.REQUIRED') : !isValidEmail && intl.get('LANDING_PAGE.CALCULATOR.INVALID_EMAIL')}
                            />
                        </Box>
                    }
                    <Box sx={style.EstimateContentButton}>
                        {isLoading ?
                            <LoadingContainer/>
                            :
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        handleEstimateButton();
                                        dispatch(updateLandingClickEstimate(true));
                                    }}
                                    sx={style.EstimateButton}
                                >
                                    <Typography sx={{textTransform: 'none'}}>
                                        {intl.get('LANDING_PAGE.CALCULATOR.ESTIMATE')}
                                    </Typography>
                                </Button>
                                <Box sx={{
                                    position: 'absolute',
                                    right: '0%',
                                    top: '50%',
                                    transform: 'translateY(-50%)'
                                }}>
                                    <Link to="/sign-in" style={{
                                        color: '#1D8B45'
                                    }}>
                                        <Typography sx={{
                                            fontSize: '16px',
                                            fontWeight: '600',
                                            textAlign: 'center',
                                            color: '#1D8B45'
                                        }}>
                                            Sign In
                                        </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}