import Grid from "@mui/material/Grid";
import {ShippingPackageQuickQuotePackage} from "./ShippingPackageQuickQuotePackage";
import {useDispatch, useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {useEffect, useState} from "react";
import {Button, Snackbar} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {filterRateList, getMissingKeysForQuickQuoteLocation, isValidPostalCode} from "../../Utils/Helper";
import {getAccessToken} from "../../Utils/doToken";
import {NODE_ROUTE_URI, PARTNER_URI} from "../../Utils/apiUrl";
import axios from "axios";
import {
    toggleQuickQuoteShippingPackageIsGetRate,
    updateQuickQuoteShippingPackageRateList,
    updateQuickQuoteShippingPackageShipFromMissing,
    updateQuickQuoteShippingPackageShipToMissing
} from "../../actions/shippingPackageAction";
import {updateMovingServiceBasicRate} from "../../actions/movingServiceAction";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const requiredProperties = ["length", "width", "height", "quantity", "weight", "lengthUnit", "weightUnit"];
const validateRequiredProperties = ["length", "width", "height", "weight", "quantity"];

export const ShippingPackageQuickQuoteGetRate = ({getIsCallAllCarrier}) => {

    const token = getAccessToken("access_token");

    const dispatch = useDispatch();

    let packageListRedux = useSelector(state => state?.shippingPackage?.quickQuotePackageList);
    let shipFrom = useSelector(state => state?.shippingPackage?.quickQuoteShipFrom);
    let shipTo = useSelector(state => state?.shippingPackage?.quickQuoteShipTo);
    let batteryDetails = useSelector(state => state?.shippingPackage?.quickQuoteBatteryDetails);
    let signatureOptions = useSelector(state => state?.shippingPackage?.quickQuoteSignatureOptions);

    const [isGetRate, setIsGetRate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isCallAllCarrier, setIsCallAllCarrier] = useState(0);
    const [isCallAllCarrierLoading, setIsCallAllCarrierLoading] = useState(false);
    const [calledServiceList, setCalledServiceList] = useState([]);

    const [rate, setRate] = useState([]);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getShippingPackageRate = async (selectedAccountBase) => {
        setLoading(true);

        let requestURL = `${NODE_ROUTE_URI}/looseItem/ratesByAccountBase`;

        let packageData = packageListRedux.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                batteryDetails: batteryDetails,
            };
            return updatedElement;
        });

        packageData = packageData.map(element => {
            const updatedElement = {...element};
            updatedElement.packageSpecialServices = {
                ...updatedElement.packageSpecialServices,
                signatureRequired: signatureOptions
            }
            return updatedElement;
        })

        let data = {
            rateData: {
                shipFromAddress: shipFrom?.displayAddress?.text,
                shipFromAddressTwo: "",
                shipFromCity: shipFrom?.city || shipFrom?.region,
                shipFromProvince: shipFrom?.province?.code,
                shipFromPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipFromCountry: shipFrom?.country,
                shipFromName: "",
                shipFromPhone: "",
                shipToAddress: shipTo?.displayAddress?.text,
                shipToAddressTwo: "",
                shipToCity: shipTo?.city || shipTo?.region,
                shipToPostalCode: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
                shipToProvince: shipTo?.province?.code,
                shipToCountry: shipTo?.country,
                shipToName: "",
                shipToPhone: "",
                packageData: packageData,
            },
            selectedAccountBase: selectedAccountBase
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: data
            });
            const rateData = result?.data?.result;

            const updatedRateData = rateData.map((each) => ({
                ...each,
                errorAccountBase: selectedAccountBase?.toUpperCase()
            }));

            console.log('[getShippingPackageRate] updatedRateData', updatedRateData);

            const filteredRateData = filterRateList(updatedRateData);

            console.log('[getShippingPackageRate] filteredRateData', filteredRateData);

            setRate(prevState => [...prevState, ...filteredRateData]);

            setLoading(false);
            setIsGetRate(true);
            setErrorMessage('');
        } catch (e) {
            setLoading(false);
            console.log(e.response.status)
            if (e.response.status === 403) {
                setErrorMessage(`${e.response.data.error}. Please Please contact op@uucargo.ca.`);
            } else {
                setErrorMessage('Fail to get rate.')
            }
            handleErrorMessage();
        } finally {
            setIsCallAllCarrier(prevState => prevState + 1);
        }
    }

    const getBoxExpressRating = async () => {
        setLoading(true);

        let requestURL = `${NODE_ROUTE_URI}/movingExpressService/movingExpressGetRating`;
        const updatedItemList = packageListRedux?.map(item => ({
            description: item.description,
            length: item.length,
            width: item.width,
            height: item.height,
            weight: item.weight,
            lengthUnit: item.lengthUnit,
            weightUnit: item.weightUnit,
            quantity: item.quantity
        }));

        const data = {
            items: updatedItemList,
            pickupWarehouseId: 0,
            pickupType: "schedule",
            pickupCity: shipFrom?.city || shipFrom?.region,
            pickupProvince: shipFrom?.province?.code,
            pickupCountry: shipFrom?.country?.code,
            pickupPostalCode: shipFrom?.postalCode.replace(/\s+/g, '').toUpperCase(),
            deliverWarehouseId: 0,
            deliverType: "schedule",
            deliverCity: shipTo?.city || shipTo?.region,
            deliverProvince: shipTo?.province?.code,
            deliverCountry: shipTo?.country?.code,
            deliverPostalCode: shipTo?.postalCode.replace(/\s+/g, '').toUpperCase(),
            specialServices: []
        }

        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${token}`},
                data: data
            })
            console.log('rating', result);
            dispatch(updateMovingServiceBasicRate(result?.data));
            setLoading(false);
        } catch (e) {
            console.log(e.response);
            const errorMessage = e?.response?.data?.error;
            setErrorMessage(errorMessage);
            setLoading(false);
        } finally {
            setIsCallAllCarrier(prevState => prevState + 1);
        }
    }

    const handleEdit = () => {
        setIsGetRate(false);
        setIsCallAllCarrier(0);
    }

    const validate = () => {
        setErrorMessage('');
        setToastOpen(false);
        const shipFromMissing = getMissingKeysForQuickQuoteLocation({
            city: shipFrom?.city,
            province: shipFrom?.province,
            country: shipFrom?.country,
            postalCode: shipFrom?.postalCode
        });
        const shipToMissing = getMissingKeysForQuickQuoteLocation({
            city: shipTo?.city,
            province: shipTo?.province,
            country: shipTo?.country,
            postalCode: shipTo?.postalCode
        });
        const shipFromPostalCodeValidation = shipFrom?.country === 'CA' ? isValidPostalCode(shipFrom?.postalCode) : true;
        const shipToPostalCodeValidation = shipTo?.country === 'CA' ? isValidPostalCode(shipTo?.postalCode) : true;

        const hasMissingValues = packageListRedux.some(object => requiredProperties.some((property) => !object[property]));
        const hasValidateValues = packageListRedux.some(object => validateRequiredProperties.some((property) => (object[property]) <= 0));
        const hasOverweightObject = packageListRedux.some(obj => {
            if (obj.weightUnit === 'kg') {
                const weightInLbs = obj.weight * 2.20462; // Convert kg to lbs
                return weightInLbs > 150;
            } else {
                return obj.weight > 150;
            }
        });

        dispatch(updateQuickQuoteShippingPackageShipFromMissing(shipFromMissing));
        dispatch(updateQuickQuoteShippingPackageShipToMissing(shipToMissing));

        packageListRedux?.length === 0 && setErrorMessage(prevState => prevState + 'Your pallet list is empty.');
        shipFromMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Ship from address is missing one or more required fields.');
        shipToMissing?.length !== 0 && setErrorMessage(prevState => prevState + ' Ship to address is missing one or more required fields.');
        hasMissingValues && setErrorMessage(prevState => prevState + ' You package list has missing one or more required fields.');
        hasValidateValues && setErrorMessage(prevState => prevState + ' Your package list has one or more invalid fields.');
        hasOverweightObject && setErrorMessage(prevState => prevState + ' In your package list, there is at least one package that weighs over 150 pounds.');
        !shipFromPostalCodeValidation && setErrorMessage(prevState => prevState + 'Shipping from postal code is invalid.');
        !shipToPostalCodeValidation && setErrorMessage(prevState => prevState + 'Shipping to postal code is invalid.');

        if (shipFromMissing.length === 0 &&
            shipToMissing.length === 0 &&
            packageListRedux.length !== 0 &&
            !hasMissingValues &&
            !hasValidateValues &&
            !hasOverweightObject &&
            shipFromPostalCodeValidation &&
            shipToPostalCodeValidation
        ) {
            return true
        } else {
            handleErrorMessage();
            return false
        }
    }

    const getAllPackageAccountBaseList = async () => {
        let requestURL = `${NODE_ROUTE_URI}/looseItem/getAllPackageAccountBaseList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            console.log('[getAllPackageAccountBaseList] result', result);

            setCalledServiceList(result?.data);

            return result?.data;

        } catch (e) {
            console.log('error', e.response);
        }
    }

    const handleCallCarrier = (availableService) => {
        if (availableService?.length > 0) {
            for (const service of availableService) {
                getShippingPackageRate(service?.account_base);
            }
        }
    }

    const handleGetRate = async () => {
        const validationResult = validate();
        if (validationResult) {
            setRate([]);
            dispatch(updateMovingServiceBasicRate(null));
            try {
                // if (shipFrom?.country?.code === shipTo?.country?.code) {
                //     console.log('same')

                const availableService = await getAllPackageAccountBaseList();

                await handleCallCarrier(availableService);

                // await getShippingPackageRate('canpar');
                // await getShippingPackageRate('ups');
                // await getShippingPackageRate('fedex');
                // await getShippingPackageRate('canadapost');
                // await getBoxExpressRating();

                // } else {
                //     console.log('diff')
                //     await getShippingPackageRate('ups');
                // }

            } catch (e) {
                console.log('error ', e.response);
            } finally {
                console.log('call rating api finished');
                setIsGetRate(true);
            }
        }
    }

    useEffect(() => {

        // const filteredRate = [...rate].filter(element => !element.hasOwnProperty("errorMessage"));

        // Step 1: Sort the array based on 'total'
        const sortedRate = [...rate].sort((a, b) => (a?.finalTotalAmount?.value || Infinity) - (b?.finalTotalAmount?.value || Infinity));
        // console.log('sorted rate', sortedRate);

        // Step 2: Find the 'FedEx Ground' object
        const fedexIndex = sortedRate.findIndex((item) => item.serviceName === 'Fedex Ground');

        if (fedexIndex !== -1 && fedexIndex !== 0) {
            // Step 4: Calculate the new 'total' for 'lowest'
            const lowestTotal = sortedRate[0]?.finalTotalAmount?.value * 1.1;
            const fedexTotal = sortedRate[fedexIndex]?.finalTotalAmount?.value;

            // Step 4: Check if 'FedEx Ground' is smaller than the lowest object
            if (fedexTotal < lowestTotal) {
                // Step 5: Swap positions of 'FedEx Ground' and the lowest object
                const updatedData = [...sortedRate];
                const temp = updatedData[fedexIndex];
                updatedData.splice(fedexIndex, 1);
                updatedData.unshift(temp);
                dispatch(updateQuickQuoteShippingPackageRateList(updatedData));
            } else {
                dispatch(updateQuickQuoteShippingPackageRateList(sortedRate));
            }
        } else {
            dispatch(updateQuickQuoteShippingPackageRateList(sortedRate));
        }
    }, [rate]);

    useEffect(() => {
        dispatch(toggleQuickQuoteShippingPackageIsGetRate(isGetRate));
    }, [isGetRate]);

    useEffect(() => {
        getIsCallAllCarrier(isCallAllCarrier);
        if (shipFrom?.country?.code === shipTo?.country?.code) {
            if (isCallAllCarrier === (+(calledServiceList?.length))) {
                setIsCallAllCarrierLoading(false);
            } else {
                setIsCallAllCarrierLoading(true);
            }
            // } else {
            //     if (isCallAllCarrier === 1) {
            //         setIsCallAllCarrierLoading(false);
            //     } else {
            //         setIsCallAllCarrierLoading(true);
            //     }
        }
    }, [isCallAllCarrier]);

    console.log('signature options', signatureOptions);

    return (
        <Grid container spacing={2}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            {/*<Grid item xs={12}>*/}
            {/*    <ShippingPackageQuickQuotePackage packageListRedux={packageListRedux}/>*/}
            {/*</Grid>*/}
            {/*<Grid item xs={12} md={6}>*/}
            {/*    <Box sx={{display: 'flex', alignItems: 'center'}}>*/}
            {/*        <Typography style={{color: 'red'}}>*/}
            {/*            **/}
            {/*        </Typography>*/}
            {/*        <Typography style={{textAlign: 'left', marginTop: '15px', fontSize: '12px'}}>*/}
            {/*            Rates are determined by the dimensions and weight of the package. It is advisable to*/}
            {/*            provide accurate weight and dimensions to avoid incurring adjustment charges.*/}
            {/*        </Typography>*/}
            {/*    </Box>*/}
            {/*</Grid>*/}
            <Grid item xs={12} md={12}>
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                    {
                        isGetRate ?
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#F2BE22',
                                    "&:hover": {
                                        backgroundColor: '#F2BE22',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                size='large'
                                onClick={handleEdit}
                                loading={isCallAllCarrierLoading}
                            >
                                Start Over
                            </LoadingButton>
                            :
                            <LoadingButton
                                variant='contained'
                                color='success'
                                size='large'
                                loading={loading}
                                onClick={handleGetRate}
                                disabled={packageListRedux.length === 0 ? true : false}
                            >
                                Get rates
                            </LoadingButton>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}