import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {getAccessToken} from "../../../Utils/doToken";
import {useState} from "react";
import {NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import Grid from "@mui/material/Grid";
import {Snackbar} from "@mui/material";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const EndUserSchedulePickupAvailableCredit = ({pickupDetails, activeLoading, availableCredit}) => {
    const storedToken = getAccessToken("access_token");

    const [loading, setLoading] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const PayViaUUCargoCredit = async () => {
        const requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/schedulePickupAppointmentAndPayCreditByAppointmentId`;
        setLoading(true);
        try {
            const result = await axios({
                method: 'post',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
                data: {
                    appointmentId: pickupDetails?.id,
                }
            })
            console.log('result', result);
            setErrorMessage('');
        } catch (e) {
            console.log('error', e.response);
            setErrorMessage('The payment has not been paid successfully.');
            handleErrorMessage();
        } finally {
            setLoading(false);
        }
    }

    const handleUUCargoCreditCard = () => {
        PayViaUUCargoCredit();
    }

    return (
        <Grid container spacing={1}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            {
                (+availableCredit < +pickupDetails?.totalChargeWithTaxes) ?
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <WarningIcon style={{color: '#F2BE22', fontSize: '18px'}}/>&nbsp;
                            <Typography sx={{color: '#454545'}}>
                                <span style={{fontWeight: '600'}}>Insufficient Credits.</span> Please top up your
                                account, or select another payment method.
                            </Typography>
                        </Box>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <Box sx={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                            <LoadingButton
                                variant='contained'
                                sx={{
                                    backgroundColor: '#1D8B45',
                                    "&:hover": {
                                        backgroundColor: '#1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleUUCargoCreditCard}
                                loading={loading}
                            >
                                <Typography style={{textTransform: 'none', fontSize: '16px'}}>
                                    Schedule Pickup
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Grid>

            }
        </Grid>
    )
}