import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {selectShippingPackageRateQuickQuote} from "../../../actions/BusinessShippingPackageAction";
import {Fragment, useEffect, useState} from "react";
import {costWithCanadianCurrencyCode, mapCarrierLogo} from "../../../Utils/Helper";
import {BusinessShippingPackageQuickQuotePlaceOrder} from "./BusinessShippingPackageQuickQuotePlaceOrder";
import {ButtonBase, ClickAwayListener, Tooltip, tooltipClasses, useMediaQuery} from "@mui/material";
import intl from "react-intl-universal";
import {styled} from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import {
    initMovingServiceItemList, updateMovingServiceMetricUnit,
    updateMovingServiceMoveFromAddress,
    updateMovingServiceMoveToAddress
} from "../../../actions/movingServiceAction";
import {useHistory} from "react-router-dom";
import {Divider} from "@material-ui/core";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {getAccessToken} from "../../../Utils/doToken";

const styles = {
    ShippingPackageQuickQuoteRateListTitle: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteRateListHeading: {
        fontSize: '14px',
        textAlign: 'left'
    },
    ShippingPackageQuickQuoteRateListSubHeading: {
        fontSize: '16px',
        fontWeight: '600',
        paddingLeft: '10px',
        margin: "5px"
    },

    ShippingPackageQuickQuoteRateListBox: {
        border: '1px solid #609966',
        padding: '20px 0',
        borderRadius: '5px'
    },

    ShippingPackageQuickQuoteRateListText: {
        textAlign: 'left',
        fontSize: '16px'
    },

    ShippingPackageQuickQuoteRateListTextMobile: {
        textAlign: 'center',
        fontSize: '16px',
        width: '100%'
    },

    ShippingPackageQuickQuoteRateListImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },

    ShippingPackageQuickQuoteRateListOtherBox: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
    ShippingPackageQuickQuoteRateListOtherBoxMobile: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingLeft: '20px',
        justifyContent: 'center',
        height: '100%',
        flexDirection: 'column'
    },
}

const LightTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const BusinessShippingPackageQuickQuoteRateList = ({isCallAllCarrier}) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const token = getAccessToken("access_token");

    let rate = useSelector(state => state?.businessShippingPackage?.quickQuoteRate);
    let shipFrom = useSelector(state => state?.businessShippingPackage?.quickQuoteShipFrom);
    let shipTo = useSelector(state => state?.businessShippingPackage?.quickQuoteShipTo);

    const isMobile = useMediaQuery('(max-width: 600px)');

    const [selectedRate, setSelectedRate] = useState(null);
    const [open, setOpen] = useState(-1);
    const [loading, setLoading] = useState(true);
    const [calledServiceList, setCalledServiceList] = useState([]);
    const [loadingCallServiceList, setLoadingCallServiceList] = useState(true);

    const handleClose = (index) => {
        index === open && setOpen(-1);
    };

    const handleOpen = (index) => {
        setOpen(index);
    };

    const handleSelect = (rate) => {
        setSelectedRate(rate);
    }

    const getAllPackageAccountBaseList = async () => {
        setLoadingCallServiceList(true);
        let requestURL = `${PARTNER_URI}/looseItemRoute/getAllPackageAccountBaseList`;

        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            console.log('[getAllPackageAccountBaseList] result', result);

            setCalledServiceList(result?.data);

            return result?.data;

        } catch (e) {
            console.log('error', e.response);
        } finally {
            setLoadingCallServiceList(false);
        }
    }

    useEffect(() => {
        (
            async () => {
                await getAllPackageAccountBaseList();
            }
        )()
    }, [])

    useEffect(() => {
        dispatch(selectShippingPackageRateQuickQuote(selectedRate));
    }, [selectedRate])

    useEffect(() => {
        if (loadingCallServiceList) {
            setLoading(true);
        } else if (isCallAllCarrier === calledServiceList?.length) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [isCallAllCarrier]);

    useEffect(() => {
        rate[0]?.finalTotalAmount && setSelectedRate(rate[0]);
    }, [rate])

    // console.log('rate', rate);
    // console.log('[BusinessShippingPackageQuickQuoteRateList] packageList', packageList);
    // console.log('[BusinessShippingPackageQuickQuoteRateList] boxRateList', boxRateList);
    // console.log('select rate', selectedRate);
    console.log('[BusinessShippingPackageQuickQuoteRateList] isCallAllCarrier', isCallAllCarrier);
    console.log('[BusinessShippingPackageQuickQuoteRateList] calledServiceList?.length', calledServiceList?.length);

    if (rate?.length === 0) {
        return (
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography style={styles.ShippingPackageQuickQuoteRateListTitle}>
                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.CARRIER_TITLE')}
                    </Typography>
                    <Typography style={styles.ShippingPackageQuickQuoteRateListHeading}>
                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.CARRIER_TEXT')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {
                        loading ?
                            <CircularProgress/>
                            :
                            <Typography style={{color: 'red', textAlign: 'left', fontSize: '14px'}}>
                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.ERROR_MESSAGE')}
                            </Typography>

                    }
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Typography style={styles.ShippingPackageQuickQuoteRateListTitle}>
                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.CARRIER_TITLE')}
                </Typography>
                <Typography style={styles.ShippingPackageQuickQuoteRateListHeading}>
                    {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.CARRIER_TEXT')}
                </Typography>
            </Grid>
            {
                rate?.length > 0 &&
                <>
                    {
                        !isMobile && <>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                                <Box sx={styles.ShippingPackageQuickQuoteRateListOtherBox}>
                                    <Typography style={styles.ShippingPackageQuickQuoteRateListSubHeading}>
                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.CARRIER')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={styles.ShippingPackageQuickQuoteRateListOtherBox}>
                                    <Typography style={styles.ShippingPackageQuickQuoteRateListSubHeading}>
                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.TIME')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={3}>
                                <Box sx={styles.ShippingPackageQuickQuoteRateListOtherBox}>
                                    <Typography style={styles.ShippingPackageQuickQuoteRateListSubHeading}>
                                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.PRICE')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                        </>
                    }
                    {
                        rate?.map((each, index) => {
                            return (
                                <>
                                    {
                                        !each?.errorMessage ? <Grid item key={index} xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '10px',
                                                    marginBottom: index === 0 && "20px"
                                                }}>
                                                    {
                                                        index === 0 && <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-start',
                                                        }}>
                                                            <Typography sx={{
                                                                backgroundColor: '#1D8B45',
                                                                color: '#FFFFFF',
                                                                fontSize: '14px',
                                                                padding: '5px 10px',
                                                                borderRadius: '5px'
                                                            }}>
                                                                Recommendation
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    <ButtonBase sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        backgroundColor: index === 0 ? "#F3FDE8" : "#FFFFFF",
                                                        border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                        padding: '20px 0',
                                                        borderRadius: '5px'
                                                    }}
                                                                onClick={() => handleSelect(each)}
                                                                disabled={loading}
                                                    >
                                                        <Box sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            height: '100%'
                                                        }}>
                                                            <Grid container spacing={4}>
                                                                <Grid item md={2} xs={3}>
                                                                    <Box
                                                                        sx={styles?.ShippingPackageQuickQuoteRateListImageBox}>
                                                                        <img src={mapCarrierLogo(each?.accountBase)}
                                                                             alt="logo"
                                                                             width='60px'/>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={2} xs={9}>
                                                                    <Box
                                                                        sx={styles?.ShippingPackageQuickQuoteRateListOtherBox}>
                                                                        <Typography
                                                                            style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                            {each?.serviceName}
                                                                        </Typography>
                                                                        {
                                                                            !each?.appointPickup &&
                                                                            <Typography>
                                                                                (Drop-Off Only)
                                                                            </Typography>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={3} xs={12}>
                                                                    <Box sx={
                                                                        isMobile ?
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBox
                                                                    }>
                                                                        <Typography
                                                                            style={styles.ShippingPackageQuickQuoteRateListText}>
                                                                            {each?.shipRange} business day(s)
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={3} xs={12}>
                                                                    <Box sx={
                                                                        isMobile ?
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBox
                                                                    }>
                                                                        <Box sx={{
                                                                            display: 'flex',
                                                                            gap: '5px',
                                                                            alignItems: 'center'
                                                                        }}>
                                                                            <Typography style={{
                                                                                fontSize: '16px',
                                                                                fontWeight: '600',
                                                                            }}>
                                                                                {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+each?.totalCharge?.value) + (+each?.serviceFee?.value))}
                                                                            </Typography>
                                                                            <ClickAwayListener
                                                                                onClickAway={() => handleClose(index)}>
                                                                                <div>
                                                                                    <LightTooltip
                                                                                        open={open === index}
                                                                                        PopperProps={{
                                                                                            disablePortal: true,
                                                                                        }}
                                                                                        disableFocusListener
                                                                                        disableHoverListener
                                                                                        disableTouchListener
                                                                                        onClose={() => handleClose(index)}
                                                                                        sx={{
                                                                                            padding: '0',
                                                                                            margin: '0'
                                                                                        }}
                                                                                        placement="right-start"
                                                                                        title={
                                                                                            <Box sx={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                                // width: '300px',
                                                                                                padding: '10px',
                                                                                                gap: '15px'
                                                                                            }}>
                                                                                                <Grid container
                                                                                                      spacing={1}>
                                                                                                    {
                                                                                                        each?.shipmentCharges?.map((each, index) => {
                                                                                                            const cost = +each?.value
                                                                                                            if (cost !== 0) {
                                                                                                                return (
                                                                                                                    <Fragment
                                                                                                                        key={index}>
                                                                                                                        <Grid
                                                                                                                            item
                                                                                                                            xs={8}>
                                                                                                                            <Typography
                                                                                                                                style={{
                                                                                                                                    fontSize: '12px',
                                                                                                                                    textAlign: 'left'
                                                                                                                                }}>
                                                                                                                                {each?.description}
                                                                                                                            </Typography>
                                                                                                                        </Grid>
                                                                                                                        <Grid
                                                                                                                            item
                                                                                                                            xs={4}>
                                                                                                                            <Typography
                                                                                                                                style={{
                                                                                                                                    fontSize: '12px',
                                                                                                                                    textAlign: 'right'
                                                                                                                                }}>
                                                                                                                                {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +each?.value)}
                                                                                                                            </Typography>
                                                                                                                        </Grid>
                                                                                                                    </Fragment>
                                                                                                                )
                                                                                                            }
                                                                                                        })
                                                                                                    }
                                                                                                    {
                                                                                                        (+each?.serviceFee?.value) > 0 &&
                                                                                                        <>
                                                                                                            <Grid item
                                                                                                                  xs={8}>
                                                                                                                <Typography
                                                                                                                    style={{
                                                                                                                        fontSize: '12px',
                                                                                                                        textAlign: 'left'
                                                                                                                    }}>
                                                                                                                    Service
                                                                                                                    Fee
                                                                                                                </Typography>
                                                                                                            </Grid>
                                                                                                            <Grid item
                                                                                                                  xs={4}>
                                                                                                                <Typography
                                                                                                                    style={{
                                                                                                                        fontSize: '12px',
                                                                                                                        textAlign: 'right'
                                                                                                                    }}>
                                                                                                                    {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, +each?.serviceFee?.value)}
                                                                                                                </Typography>
                                                                                                            </Grid>
                                                                                                        </>
                                                                                                    }
                                                                                                    <Grid item xs={12}>
                                                                                                        <Divider/>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={8}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'left',
                                                                                                                // color: '#1D8B45',
                                                                                                                fontWeight: '600'
                                                                                                            }}>
                                                                                                            Subtotal
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'right',
                                                                                                                // color: '#1D8B45',
                                                                                                                fontWeight: '600'
                                                                                                            }}>
                                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+each?.totalCharge?.value) + (+each?.serviceFee?.value))}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={8}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'left'
                                                                                                            }}>
                                                                                                            Tax
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'right',
                                                                                                            }}>
                                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+each?.taxes?.reduce((total, item) => total + (+item?.value), 0)) + (+each?.serviceFeeTax?.value))}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={8}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'left',
                                                                                                                color: '#1D8B45',
                                                                                                                fontWeight: '600'
                                                                                                            }}>
                                                                                                            Total
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                    <Grid item xs={4}>
                                                                                                        <Typography
                                                                                                            style={{
                                                                                                                fontSize: '12px',
                                                                                                                textAlign: 'right',
                                                                                                                color: '#1D8B45',
                                                                                                                fontWeight: '600'
                                                                                                            }}>
                                                                                                            {costWithCanadianCurrencyCode(shipFrom?.country, shipTo?.country, (+each?.finalTotalAmount?.value))}
                                                                                                        </Typography>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                                {/*<Typography*/}
                                                                                                {/*    style={{*/}
                                                                                                {/*        fontSize: '12px',*/}
                                                                                                {/*        textAlign: 'left'*/}
                                                                                                {/*    }}>*/}
                                                                                                {/*    For domestic*/}
                                                                                                {/*    shipments, there are*/}
                                                                                                {/*    no*/}
                                                                                                {/*    tax and duty to be*/}
                                                                                                {/*    paid*/}
                                                                                                {/*</Typography>*/}
                                                                                            </Box>
                                                                                        }
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() => handleOpen(index)}>
                                                                                            <HelpIcon
                                                                                                sx={{color: '#1D8B45'}}/>
                                                                                        </IconButton>
                                                                                    </LightTooltip>
                                                                                </div>
                                                                            </ClickAwayListener>
                                                                        </Box>
                                                                        {each?.regularPriceExcludeTax?.value > ((+each?.totalCharge?.value) + (+each?.serviceFee?.value)) &&
                                                                            <Typography style={{
                                                                                fontSize: '14px',
                                                                                textDecoration: 'line-through',
                                                                            }}>
                                                                                Reg.
                                                                                ${each?.regularPriceExcludeTax?.value}
                                                                            </Typography>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={2} xs={12}>
                                                                    <Box sx={
                                                                        isMobile ?
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBoxMobile :
                                                                            styles?.ShippingPackageQuickQuoteRateListOtherBox
                                                                    }>
                                                                        {selectedRate === each ?
                                                                            <Button
                                                                                variant='contained'
                                                                                size='large'
                                                                                color='success'
                                                                                disabled={loading}
                                                                            >
                                                                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.SELECTED')}
                                                                            </Button>
                                                                            :
                                                                            <Button
                                                                                variant='outlined'
                                                                                size='large'
                                                                                color='success'
                                                                                onClick={() => handleSelect(each)}
                                                                                disabled={loading}
                                                                            >
                                                                                {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.SELECT')}
                                                                            </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </ButtonBase>
                                                </Box>
                                                {
                                                    each === selectedRate &&
                                                    <BusinessShippingPackageQuickQuotePlaceOrder rateInfo={each}
                                                                                                 loadingSelection={loading}/>
                                                }
                                            </Grid>
                                            :
                                            each?.statusCode === 200 &&
                                            <Grid item key={index} xs={12}>
                                                <Box sx={{
                                                    padding: '20px 0',
                                                    borderRadius: '5px',
                                                    border: each === selectedRate ? '3px solid #609966' : '1px solid #000000',
                                                }}>
                                                    <Grid container spacing={4}>
                                                        <Grid item md={2} xs={3}>
                                                            <Box
                                                                sx={styles?.ShippingPackageQuickQuoteRateListImageBox}>
                                                                <img src={mapCarrierLogo(each?.errorAccountBase)}
                                                                     alt="logo"
                                                                     width='60px'/>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={3} xs={9}>
                                                            <Box
                                                                sx={styles?.ShippingPackageQuickQuoteRateListOtherBox}>
                                                                <Typography
                                                                    style={styles?.ShippingPackageQuickQuoteRateListText}>
                                                                    {each?.serviceName}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item md={7} xs={12}>
                                                            <Box
                                                                sx={styles?.ShippingPackageQuickQuoteRateListOtherBox}>
                                                                <Typography
                                                                    style={{textAlign: 'left', color: '#FF0303'}}>
                                                                    {each?.errorMessage}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                    }
                                </>
                            )
                        })
                    }
                </>
            }
            <Grid item xs={12} md={12}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography style={{color: 'red'}}>
                        *
                    </Typography>
                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                        {intl.get('SHIPPING_PACKAGE_QUICK_QUOTE.CHOOSE_CARRIER.MESSAGE')} Rates may be changed for the
                        variable DG class.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}
