import {useSelector} from "react-redux";
import Box from "@mui/material/Box";
import {Fragment, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {mapCarrierLogo, mapLTLServiceName} from "../../Utils/Helper";
import Divider from "@mui/material/Divider";
import {useHistory} from "react-router-dom";
import {Button} from "@mui/material";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import intl from "react-intl-universal";
import * as React from "react";
import {LABEL_DOWNLOAD_URL_PREFIX} from "../../Utils/apiUrl";
import {greenButtonContained} from "../../Utils/styleConfig";

const style = {
    EstimateCostRoot: {
        margin: 'auto'
    },
    EstimateCostPackageRoot: {
        backgroundColor: '#FFFFFF',
        // padding: '20px 60px',
        borderRadius: '10px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    EstimateCostHeading: {
        textAlign: 'left',
        // fontSize: '14px',
        // padding: '20px 0',
        fontWeight: '600',
        '@media (max-width: 480px)': {
            padding: '0 5px',
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostText: {
        textAlign: 'left',
        // fontSize: '14px',
        padding: '5px 0',
        '@media (max-width: 480px)': {
            padding: 0,
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostText2: {
        textAlign: 'left',
        // fontSize: '14px',
        '@media (max-width: 480px)': {
            padding: 0,
            fontSize: '10px'// for phone and above
        },
    },
    EstimateCostPallet: {
        // height: '100px',
        // padding: '20px 60px 60px',
        marginBottom: '50px',
        '@media (max-width: 480px)': {
            padding: 0, // for phone and above
        },
    },
    EstimateCostImageBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // padding: '0 20px',
        height: '100%',
        '@media (max-width: 480px)': {
            padding: '0 5px',
        },
    },
    EstimateCostBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // padding: '5px 0',
        gap: '5px',
        height: '100%',
        '@media (max-width: 480px)': {
            padding: '5px 0',
        },
    },
    EstimateCostLiner: {
        '--LinearProgress-radius': '6px',
        '--LinearProgress-progressThickness': '12px',
        color: 'green',
        '@media (max-width: 480px)': {
            '--LinearProgress-progressThickness': '6px',
        },
        backgroundColor: '#FFFFFF'
    },
    EstimateCostButtonBox: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        // paddingLeft: '20px',
        '@media (max-width: 480px)': {
            paddingLeft: 0,
        },
    },
    EstimateCostButtonText: {
        fontSize: '14px',
        '@media (max-width: 480px)': {
            fontSize: '10px'// for phone and above
        },
        textTransform: 'none'
    },
    EstimateHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left',
    },
}

export const EstimateCost = ({handleCancel}) => {

    let palletData = useSelector(state => state?.landingEstimate?.landingEstimatePallet);
    let packageData = useSelector(state => state?.landingEstimate?.landingEstimatePackage);
    let boxData = useSelector(state => state?.landingEstimate?.landingEstimateBox);
    let estimatePackageCost = useSelector(state => state?.landingEstimate?.landingEstimatePackageCost);
    let estimatePalletCost = useSelector(state => state?.landingEstimate?.landingEstimatePalletCost);
    let estimateBoxCost = useSelector(state => state?.landingEstimate?.landingEstimateBoxCost);
    let estimateType = useSelector(state => state?.landingEstimate?.landingEstimateTypeService);
    let error = useSelector(state => state?.landingEstimate?.landingEstimateCostError);

    const [estimatePackageCostList, setEstimatePackageCostList] = useState([]);
    const [hideOptions, setHideOptions] = useState(false);
    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 480px)');

    console.log('[EstimateCost] package data', packageData);
    console.log('[EstimateCost] estimateType', estimateType);
    console.log('[EstimateCost] estimatePackageCost', estimatePackageCost);
    console.log('[EstimateCost] estimatePalletCost', estimatePalletCost);

    useEffect(() => {
        const sortedEstimateCost = [...estimatePackageCost].sort((current, next) => current?.totalCharge?.value - next?.totalCharge?.value);
        setEstimatePackageCostList(sortedEstimateCost)
        estimatePackageCost.length > 3 ? setHideOptions(true) : setHideOptions(false);
    }, [estimatePackageCost])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography sx={style.EstimateHeading}>
                    {intl.get('LANDING_PAGE.CALCULATOR.CALCULATOR')}
                </Typography>
                <Typography style={{textAlign: 'left', color: '#454545', fontSize: '14px'}}>
                    {intl.get('LANDING_PAGE.SIGN_IN.LOGIN_TEXT')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {estimateType &&
                    <Box>
                        {
                            estimateType === 'box' ? (+estimateBoxCost?.totalChargeWithTaxes > 0 ?
                                    <Box sx={style.EstimateCostPallet}>
                                        <Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Dimension: {boxData?.length} x {boxData?.width} x {boxData?.height} in
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Weight: {boxData?.weight} lb
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Quantity: {boxData?.quantity}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Typography>
                                                        Pickup: {boxData?.pickupCity}, {boxData?.pickupProvince?.code}, {boxData?.pickupPostalCode}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Typography>
                                                        Deliver: {boxData?.deliverCity}, {boxData?.deliverProvince?.code}, {boxData?.deliverPostalCode}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={style.EstimateCostHeading}>
                                                        {intl.get('LANDING_PAGE.CALCULATOR.SERVICE_TYPE')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography sx={style.EstimateCostHeading}>
                                                        {intl.get('LANDING_PAGE.CALCULATOR.TOTAL_COST')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Divider/>
                                        <Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Box sx={style.EstimateCostBox}>
                                                        <Typography sx={style.EstimateCostText}>
                                                            Standard
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box sx={style.EstimateCostBox}>
                                                        <Typography sx={style.EstimateCostText}>
                                                            $ {(+estimateBoxCost?.totalChargeWithTaxes).toFixed(2)}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Divider/>
                                        </Box>
                                    </Box> : <Box>
                                        <Typography>
                                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_1')}
                                        </Typography>
                                        <Typography>
                                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_2')}
                                        </Typography>
                                    </Box>) :
                                estimateType === 'pallet' ? (!error && estimatePalletCost?.length > 0 ?
                                    <Box sx={style.EstimateCostPallet}>
                                        <Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Dimension: 48 x 48 x 60 in
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Weight: 400 lb
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Quantity: 1
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Typography>
                                                        Pickup: {palletData?.pickupCity}, {palletData?.pickupProvince?.code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Typography>
                                                        Deliver: {palletData?.deliverCity}, {palletData?.deliverProvince?.code}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={style.EstimateCostHeading}>
                                                        Carrier
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={style.EstimateCostHeading}>
                                                        Cost
                                                    </Typography>
                                                </Grid>
                                                {!isMobile &&
                                                    <Grid item xs={2}>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                        <Divider/>
                                        <Box>
                                            <Grid container spacing={1}>
                                                {
                                                    estimatePalletCost?.map((pallet, index) => (
                                                        <Fragment key={index}>
                                                            <Grid item xs={2}>
                                                                <img
                                                                    src={`${LABEL_DOWNLOAD_URL_PREFIX}${pallet?.icon_uri}`}
                                                                    alt="logo" width={35}/>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography sx={style.EstimateCostText}>
                                                                    {mapLTLServiceName(pallet?.carrierName)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Typography sx={style.EstimateCostText}>
                                                                    ${(+pallet?.finalTotal)?.toFixed(2)} {pallet?.currency}
                                                                </Typography>
                                                            </Grid>
                                                            {!isMobile &&
                                                                <Grid item xs={2}>
                                                                    <Box sx={style.EstimateCostButtonBox}>
                                                                        <Button
                                                                            variant='contained'
                                                                            sx={greenButtonContained}
                                                                            onClick={() => history.push('/sign-in')}
                                                                        >
                                                                            <Typography
                                                                                sx={style.EstimateCostButtonText}>
                                                                                {intl.get('LANDING_PAGE.CALCULATOR.SHIP')}
                                                                            </Typography>
                                                                        </Button>
                                                                    </Box>
                                                                </Grid>
                                                            }
                                                        </Fragment>
                                                    ))
                                                }
                                            </Grid>
                                            <Divider/>
                                        </Box>
                                    </Box> : <Box>
                                        <Typography>
                                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_1')}
                                        </Typography>
                                        <Typography>
                                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_2')}
                                        </Typography>
                                    </Box>) : (estimatePackageCost.length !== 0 ?
                                    <Box sx={style.EstimateCostPackageRoot}>
                                        <Box>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Dimension: {packageData?.length} x {packageData?.width} x {packageData?.height} {packageData?.dimensionUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Weight: {packageData?.weight} {packageData?.weightUnit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography>
                                                        Quantity: {packageData?.quantity}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Typography>
                                                        Pickup: {packageData?.pickupPostalCode}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6} xs={12}>
                                                    <Typography>
                                                        Deliver: {packageData?.deliverPostalCode}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography sx={style.EstimateCostHeading}>
                                                        {intl.get('LANDING_PAGE.CALCULATOR.CARRIER')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={style.EstimateCostHeading}>
                                                        {intl.get('LANDING_PAGE.CALCULATOR.SERVICE_TYPE')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography sx={style.EstimateCostHeading}>
                                                        {intl.get('LANDING_PAGE.CALCULATOR.DELIVERY_TIME')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography sx={style.EstimateCostHeading}>
                                                        {intl.get('LANDING_PAGE.CALCULATOR.TOTAL_COST')}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Divider/>
                                        </Box>
                                        {
                                            estimatePackageCostList?.map((item, index) => {
                                                return (
                                                    <Box key={index}
                                                         sx={{
                                                             display: `${index > 3 && hideOptions && 'none'}`,
                                                         }}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={2}>
                                                                <Box sx={style.EstimateCostImageBox}>
                                                                    <img src={mapCarrierLogo(item?.accountBase)}
                                                                         width={isMobile ? '20px' : '40px'}/>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Box sx={style.EstimateCostBox}>
                                                                    <Typography sx={style.EstimateCostText}>
                                                                        {item?.serviceName}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Box sx={style.EstimateCostBox}>
                                                                    <Typography sx={style.EstimateCostText2}>
                                                                        {item?.shipRange} business day(s)
                                                                        {/*{item?.serviceType === 'express' ? intl.get('SHIPPING_RATE_CALCULATOR_COST_TEXT_4') : getDeliverTime(item?.expectedDate) === 0 ? intl.get('SHIPPING_RATE_CALCULATOR_COST_TEXT_5') : intl.get('SHIPPING_RATE_CALCULATOR_COST_TEXT_6')}*/}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Box sx={style.EstimateCostBox}>
                                                                    <Typography sx={style.EstimateCostText}>
                                                                        $ {(+item?.totalChargeWithTaxes?.value)?.toFixed(2)}
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider/>
                                                    </Box>
                                                )
                                            })
                                        }
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                            {hideOptions ? <Button onClick={() => setHideOptions(false)}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.MORE')}
                                                </Button> :
                                                <Button onClick={() => setHideOptions(true)}>
                                                    {intl.get('LANDING_PAGE.CALCULATOR.HIDE')}
                                                </Button>
                                            }
                                        </Box>
                                    </Box> : <Box>
                                        <Typography>
                                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_1')}
                                        </Typography>
                                        <Typography>
                                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_2')}
                                        </Typography>
                                    </Box>)}
                        <Typography style={{textAlign: 'right', fontSize: '12px', fontStyle: 'italic'}}>
                            {intl.get('LANDING_PAGE.CALCULATOR.TEXT_3')}
                        </Typography>
                    </Box>
                }
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#1D8B45',
                            "&:hover": {
                                backgroundColor: '#1D8B45',
                                filter: 'brightness(0.9)'
                            }
                        }}
                        size='small'
                        onClick={handleCancel}
                    >
                        <Typography sx={{textTransform: 'none'}}>
                            Back
                        </Typography>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
