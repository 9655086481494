import {Button, Container} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {green, greenButtonContained, mainHeading, textContent} from "../../Utils/styleConfig";
import {mapLTLServiceName} from "../../Utils/Helper";
import dayjs from "dayjs";
import CanadaSuccessImage from "../../images/LTLCanada.png";
import CrossBorderSuccessImage from "../../images/LTLCrossBorder.png";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {useHistory} from "react-router-dom";

export const LTLShipmentSuccess = ({orderDetails}) => {

    const history = useHistory();

    console.log('[LTLShipmentSuccess] orderDetails', orderDetails);

    const handleShippingDashboard = () => {
        history?.push(`/business/ltl-order-dashboard/${orderDetails?.order_id}`);
    }

    const handleCreateNewShipment = () => {
        history?.push('/business/shipping-ltl/create-label');
    }

    const handleCreateCommercialInvoice = () => {
        history?.push(`/business/ltl-cross-border-dashboard/${orderDetails?.order_id}`);
    }

    return (
        <Container maxWidth='xl'>
            <Box sx={{
                maxWidth: '80vw',
                width: '50vw',
                margin: '0 auto 40px'
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography sx={{fontSize: mainHeading, fontWeight: '600', textAlign: 'center'}}>
                            Congratulations. Your shipment has been created!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{textAlign: 'center'}}>
                            {mapLTLServiceName(orderDetails?.account_base_combine_name?.split('-')[0]?.toUpperCase())} will
                            pickup your pallet on <span
                            style={{
                                fontWeight: '600',
                                color: green
                            }}>
                                {dayjs(orderDetails?.bolInfo?.pickup_date)?.format('dddd MMMM D, YYYY')}
                            </span>.
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <img
                                src={orderDetails?.send_from_country === orderDetails?.send_to_country ? CanadaSuccessImage : CrossBorderSuccessImage}
                                alt="package-shipment-success" style={{
                                maxWidth: '450px',
                                width: '300px'
                            }}/>
                        </Box>
                        {
                            orderDetails?.send_from_country === orderDetails?.send_to_country ?
                                <>
                                    <Typography sx={{textAlign: 'center'}}>
                                        You can easily check and download your shipping labels from the Order
                                        Dashboard. <span style={{fontWeight: '600'}}> Before the driver arrives,
                                        please print your bill of lading (BOL).</span> For some carriers,
                                        it may be uploaded later.
                                    </Typography>
                                </>
                                :
                                <>
                                    <Typography sx={{textAlign: 'center'}}>
                                        One last step for international shipments: Before sending your packages, it's
                                        essential to <span style={{fontWeight: '600'}}>create or upload your commercial
                                        invoices</span> for customs brokerage purposes.
                                    </Typography>
                                </>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            {
                                orderDetails?.send_from_country === orderDetails?.send_to_country ?
                                    <Button
                                        variant="contained"
                                        sx={greenButtonContained}
                                        startIcon={<HomeIcon/>}
                                        onClick={handleShippingDashboard}
                                    >
                                        <Typography sx={{
                                            fontWeight: '600',
                                            fontSize: textContent,
                                            textTransform: 'none',
                                        }}>
                                            Shipping Dashboard
                                        </Typography>
                                    </Button>
                                    :
                                    <Button
                                        variant="contained"
                                        sx={greenButtonContained}
                                        startIcon={<ReceiptIcon/>}
                                        onClick={handleCreateCommercialInvoice}
                                    >
                                        <Typography sx={{
                                            fontWeight: '600',
                                            fontSize: textContent,
                                            textTransform: 'none',
                                        }}>
                                            Create Commercial Invoice
                                        </Typography>
                                    </Button>
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button
                                onClick={handleCreateNewShipment}
                            >
                                <Typography sx={{
                                    fontWeight: '600',
                                    fontSize: textContent,
                                    textTransform: 'none',
                                    color: green
                                }}>
                                    Create New Shipment
                                </Typography>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}