import {useHistory} from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import * as React from "react";
import MuiAlert from "@mui/material/Alert";
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Link,
    Snackbar,
    Tooltip, tooltipClasses
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {costWithCanadianCurrencyCode, generateRandomString, mapCarrierLogo} from "../../../Utils/Helper";
import {LABEL_DOWNLOAD_URL_PREFIX, NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import {Fragment} from "react";
import {Dialog} from "@material-ui/core";
import {TrackingItem} from "../../Tracking/TrackingItem";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {styled} from "@mui/material/styles";
import intl from "react-intl-universal";
import {ReactComponent as ReturnLabelIcon} from "../../../images/icons/returnLabel.svg";
import {ReactComponent as PrintLabelIcon} from "../../../images/icons/printLabel.svg";
import {ReactComponent as TrackLabelIcon} from "../../../images/icons/trackLabel.svg";
import {ReactComponent as CloneLabelIcon} from "../../../images/icons/cloneLabel.svg";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import {
    initShippingPackageCreateLabelPackageList,
    initShippingPackageCrossBorderItemList,
    updateCreateLabelShippingPackageShipFrom,
    updateCreateLabelShippingPackageShipTo,
    updateShippingPackageCreateLabelBatteryDetails,
    updateShippingPackageCreateLabelSignatureOptions, updateShippingPackageReturnLabelStatus
} from "../../../actions/shippingPackageAction";
import {useDispatch} from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GreenTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#1D8B45',
        color: '#FFFFFF',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const style = {
    PackageOrderDashboardTransactionDetailsRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0'
    }
}

export const PackageOrderDashboardTransactionDetails = ({orderId, selectTransactionData}) => {

    const history = useHistory();

    const isMobile = useMediaQuery('(max-width: 480px)');
    const dispatch = useDispatch();

    const [cancelLoading, setCancelLoading] = useState(false);
    const [trackingPackageDetailList, setTrackingPackageDetailList] = useState([]);
    const [trackedPackagesList, setTrackedPackagesList] = useState([]);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [trackingInfoDialogIsOpen, setTrackingInfoDialogIsOpen] = useState(false);
    const [cancelTrackingNumberErrorMessage, setCancelTrackingNumberErrorMessage,] = useState("");
    const [isCancelled, setIsCancelled] = useState(false);
    const [declaredValue, setDeclaredValue] = useState(0);
    const [batteryDetails, setBatteryDetails] = useState({});
    const [signatureOptions, setSignatureOptions] = useState(null);

    const handleToastClick = () => {
        setToastOpen(true);
    };

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const getOrderData = async (tranId) => {
        const storedToken = getAccessToken("access_token");
        try {

            const requestURI = `${NODE_ROUTE_URI}/looseItem/getCloneShipmentInfoByTranId`;
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    tranId: tranId
                }
            })

            return result?.data;

        } catch (e) {
            console.log('[ReturnLabel] getOrderData api error', e?.response);
        }
    }

    const handleReturnOrCloneLabel = async (isReturn) => {
        const orderData = await getOrderData(selectTransactionData.tran_id);
        console.log('[handleReturnOrCloneLabel] order data', orderData);
        const packageList = orderData?.packageData?.map(element => {
            const updatedElement = {
                ...element,
                packageInfoType: "customBox",
                packageId: generateRandomString(8)
            };
            return updatedElement;
        })
        const shipFrom = {
            postalCode: orderData?.shipFromPostalCode,
            province: {
                code: orderData?.shipFromProvince,
                name: orderData?.shipFromProvince
            },
            city: orderData?.shipFromCity,
            address: orderData?.shipFromAddress,
            address2: orderData?.shipFromAddressTwo,
            country: orderData?.shipFromCountry,
            displayAddress: {
                description: "",
                placeId: "",
                text: orderData?.shipFromAddress
            },
            name: orderData?.shipFromName,
            phone: orderData?.shipFromPhone,
            email: orderData?.shipFromEmail,
            company: orderData?.shipFromCompanyName,
            saveAddress: false,
            addressList: {
                name: orderData?.shipFromName,
                phone: orderData?.shipFromPhone,
                email: orderData?.shipFromEmail,
                address_email: orderData?.shipFromEmail,
                company_name: orderData?.shipFromCompanyName,
                city: null,
                province: null,
                country: null
            }
        };
        const shipTo = {
            postalCode: orderData?.shipToPostalCode,
            province: {
                code: orderData?.shipToProvince,
                name: orderData?.shipToProvince
            },
            city: orderData?.shipToCity,
            address: orderData?.shipToAddress,
            address2: orderData?.shipToAddressTwo,
            country: orderData?.shipToCountry,
            displayAddress: {
                description: "",
                placeId: "",
                text: orderData?.shipToAddress
            },
            name: orderData?.shipToName,
            phone: orderData?.shipToPhone,
            email: orderData?.shipToEmail,
            company: orderData?.shipToCompanyName,
            saveAddress: false,
            addressList: {
                name: orderData?.shipToName,
                phone: orderData?.shipToPhone,
                email: orderData?.shipToEmail,
                address_email: orderData?.shipToEmail,
                company_name: orderData?.shipToCompanyName,
                city: null,
                province: null,
                country: null
            }
        };
        if (orderData?.packingList?.length > 0) {
            const itemList = orderData?.packingList?.map(item => ({
                description: item?.item_desc,
                sku: item?.item_sku,
                weight: item?.weight,
                weightUnit: item?.weight_unit,
                UOM: item?.uom?.toUpperCase(),
                hscode: item?.hs_code,
                originCountryCode: item?.origi_country,
                currency: item?.currency_code,
                priceEach: item?.unit_price,
                quantity: item?.quantity,
                itemId: generateRandomString(8)
            }));
            dispatch(initShippingPackageCrossBorderItemList(itemList));
        }
        dispatch(initShippingPackageCreateLabelPackageList(packageList));
        dispatch(updateCreateLabelShippingPackageShipFrom(shipFrom));
        dispatch(updateCreateLabelShippingPackageShipTo(shipTo));
        dispatch(updateShippingPackageCreateLabelSignatureOptions(orderData?.packageData?.[0]?.packageSpecialServices?.signatureRequired));
        dispatch(updateShippingPackageCreateLabelBatteryDetails(orderData?.packageData?.[0]?.packageSpecialServices?.batteryDetails));
        dispatch(updateShippingPackageReturnLabelStatus(isReturn));
        history.push('/shipping-package/create-label');
        window.scrollTo(0, 0); // Scroll to the top
    }

    const handleCreateReturnLabel = async () => {
        await handleReturnOrCloneLabel(true);
    };

    //handle tracking information dialog open and close
    const handleTrackingInfo = () => {
        setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
    };

    const handleTrackingInfoClose = (event, reason) => {
        if (reason && reason == "backdropClick") {
            setTrackingInfoDialogIsOpen(!trackingInfoDialogIsOpen);
        }
    }

    const getTrackingPackageDetailList = async () => {
        try {
            const storedToken = getAccessToken("access_token");
            const {data} = await axios({
                method: "GET",
                url: `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/getValidTrackingListByTranId/${selectTransactionData?.tran_id}`,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log('[getTrackingPackageDetailList] api data', data);
            setTrackingPackageDetailList(data);
            const totalDeclaredValue = data?.reduce((accumulator, currentValue) => {
                return accumulator + currentValue?.declared_value
            }, 0);
            setDeclaredValue(totalDeclaredValue);
            setBatteryDetails(prevState => ({
                ...prevState,
                material: data[0]?.battery_material,
                packing: data[0]?.battery_packing
            }));
            setSignatureOptions(data[0]?.signature_option);
        } catch (e) {
            console.log(e);
        }
    }

    const getTrackedPackageList = async () => {
        try {
            const trackingNumbers = selectTransactionData?.tran_tracking_nos?.replace(/\n/g, '');
            const storedToken = getAccessToken("access_token");
            const result = await axios({
                method: "get",
                url: `${NODE_ROUTE_URI}/shippingOrderDetailsDashboard/endUser/multipleTrackingForWeb?trackingNumbers=${trackingNumbers}`,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
            });
            console.log('result --> getTrackingList', result)
            const trackingData = result?.data?.filter((v) => v?.trackingResponse !== null);
            setTrackedPackagesList(trackingData);
        } catch (e) {
            console.log(e.response)
            // setErrorMessage("Failed to Schedule Pickup. Try again another time.");
        }
    };

    const getLabelURL = (pdf, zip) => {
        if (pdf) {
            return pdf;
        }
        if (zip) {
            return zip;
        }
        return "";
    };

    useEffect(() => {
        getTrackedPackageList();
        getTrackingPackageDetailList();
    }, [selectTransactionData]);

    console.log('tracking list', trackedPackagesList);
    console.log('tracking detail list', trackingPackageDetailList);
    console.log('declare value', declaredValue);

    console.log('select transaction data', selectTransactionData);

    return (
        <Box sx={style.PackageOrderDashboardTransactionDetailsRoot}>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Dialog
                open={trackingInfoDialogIsOpen}
                onClose={handleTrackingInfoClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Tracking Details</DialogTitle>
                <DialogContent>
                    {trackedPackagesList?.length === 0 ? <Box>No Tracking Information Found</Box> :
                        trackedPackagesList?.map((trackedPackage, index) => {
                            return (
                                <TrackingItem
                                    key={index}
                                    activity={
                                        trackedPackage?.trackingResponse
                                            ? trackedPackage?.trackingResponse?.activity
                                            : undefined
                                    }
                                    trackingNo={trackedPackage?.trackingNumber}
                                    courier={trackedPackage?.accountBase}
                                    deliverStatus={trackedPackage?.trackingResponse?.status}
                                    transaction={trackedPackage?.transaction}
                                />
                            );
                        })}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleTrackingInfo}
                        variant="outlined"
                        autoFocus
                        type="button"
                    >
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px',
                        borderBottom: '1px solid #B2B2B2',
                        alignItems: 'center'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            Shipping Transactions <span
                            style={{color: '#1D8B45'}}> ID: {selectTransactionData?.tran_id}</span>
                        </Typography>
                        {
                            declaredValue > 0 && <GreenTooltip
                                placement="right-start"
                                title='Carrier Insurance Purchased'
                            >
                                <IconButton>
                                    <VerifiedUserIcon sx={{color: '#1D8B45'}}/>
                                </IconButton>
                            </GreenTooltip>
                        }
                        {
                            +selectTransactionData?.is_return_label === 1 &&
                            <Box sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                                <ReturnLabelIcon width='20' height='20' fill='#1D8B45'/>
                                <Typography sx={{color: '#1D8B45'}}>Return Label</Typography>
                            </Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{padding: '20px'}}>
                        <Grid container spacing={2}>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography><span
                                        style={{fontWeight: '600'}}>Proceed Date: </span>
                                    </Typography>
                                    <Typography>
                                        {dayjs(selectTransactionData?.created_at)?.format('YYYY-MM-DD')}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    {/*<Typography><span*/}
                                    {/*    style={{fontWeight: '600'}}>Carrier: </span>*/}
                                    {/*</Typography>*/}
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}>
                                        <img src={mapCarrierLogo(selectTransactionData?.account_base?.toUpperCase())}
                                             alt="logo"
                                             width='40px'/>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Shipping From
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_from_name}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_from_name !== selectTransactionData?.send_from_company && selectTransactionData?.send_from_company}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_from_addr_line2 && `${selectTransactionData?.send_from_addr_line2} - `}{selectTransactionData?.send_from_addr}, {selectTransactionData?.send_from_city}, {selectTransactionData?.send_from_province}, {selectTransactionData?.send_from_postal_code}, {selectTransactionData?.send_from_country}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_from_tel} {selectTransactionData?.send_from_tel_ext && `ext. ${selectTransactionData?.send_from_tel_ext}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Shipping To
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_to_name}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_to_name !== selectTransactionData?.send_to_company && selectTransactionData?.send_to_company}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_to_addr_line2 && `${selectTransactionData?.send_to_addr_line2} - `}{selectTransactionData?.send_to_addr}, {selectTransactionData?.send_to_city}, {selectTransactionData?.send_to_province}, {selectTransactionData?.send_to_postal_code}, {selectTransactionData?.send_to_country}
                                        </Typography>
                                        <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                            {selectTransactionData?.send_to_tel} {selectTransactionData?.send_to_tel_ext && `ext. ${selectTransactionData?.send_to_tel_ext}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    height: '100%',
                                    gap: '5px'
                                }}>
                                    <Typography><span
                                        style={{fontWeight: '600'}}>Reference Number: </span>
                                    </Typography>
                                    <Typography>
                                        {selectTransactionData?.ref_order_no}
                                    </Typography>
                                </Box>
                            </Grid>
                            {
                                declaredValue > 0 && <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Carrier Insurance
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={0}>
                                                <Grid item xs={3}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Declared Value
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        $ {declaredValue.toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        Insurance Fee
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        $ {(+selectTransactionData?.insurance_charge || 0).toFixed(2)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Packaging Details
                                        </Typography>
                                    </Grid>
                                    {
                                        !isMobile &&
                                        <>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    Name
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    Tracking No
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    Dimension
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    Weight
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                    Status
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        trackingPackageDetailList?.map((element, index) => (
                                            <Fragment key={index}>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && 'Name: '}{element?.item_name}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={4} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && 'Tracking No: '}<Link
                                                        href={element?.label_uri ? `${LABEL_DOWNLOAD_URL_PREFIX}${element?.label_uri}` : '#'}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        underline="hover"
                                                        sx={{
                                                            color: '#1D8B45',
                                                        }}
                                                    >{element?.tracking_no}</Link>
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && 'Dimension: '}{element?.length}x{element?.width}x{element?.height} {element?.dimension_unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && 'Weight: '}{element?.weight} {element?.weight_unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={2} xs={12}>
                                                    <Typography style={{textAlign: 'left', fontSize: '14px'}}>
                                                        {isMobile && 'Status: '}
                                                        {
                                                            (() => {
                                                                if (+element.tn_status === 1 || +element.tn_status === 0) {
                                                                    return "On The Way";
                                                                }
                                                                if (+element.tn_status === 2) {
                                                                    return "Delivered";
                                                                }
                                                                return "Cancelled";
                                                            })()
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                            {
                                (declaredValue > 0 || batteryDetails?.material || signatureOptions) &&
                                <Grid item md={12} xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                                Shipping Options
                                            </Typography>
                                        </Grid>
                                        {
                                            declaredValue > 0 &&
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{
                                                                fontWeight: '600',
                                                                textAlign: 'left',
                                                                fontSize: '12px'
                                                            }}>
                                                            Carrier Insurance
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    Declare Value
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    $ {declaredValue.toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    Insurance Fee
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    $ {(+selectTransactionData?.insurance_charge).toFixed(2)}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            signatureOptions &&
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{
                                                                fontWeight: '600',
                                                                textAlign: 'left',
                                                                fontSize: '14px'
                                                            }}>
                                                            Signature Options
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{textAlign: 'left', fontSize: '12px'}}>
                                                            Signature Required
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            batteryDetails?.material &&
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            style={{
                                                                fontWeight: '600',
                                                                textAlign: 'left',
                                                                fontSize: '14px'
                                                            }}>
                                                            Lithium
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={0}>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    Battery Material
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    {batteryDetails?.material === 'LITHIUM_METAL' ? 'Lithium Metal' : 'Lithium Ion'}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    Battery Packing
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={7}>
                                                                <Typography
                                                                    style={{textAlign: 'left', fontSize: '12px'}}>
                                                                    {batteryDetails?.packing === 'CONTAINED_IN_EQUIPMENT' ? 'Contained in equipment' : 'Packed with equipment'}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xl={9} md={7} xs={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography style={{fontWeight: '600', textAlign: 'left'}}>
                                            Transaction Cost Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                textAlign: 'left'
                                            }}>
                                            Freight Charge
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '14px'
                                        }}>
                                            {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.freight_charge)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                textAlign: 'left'
                                            }}>
                                            Fuel Surcharge
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '14px'
                                        }}>
                                            {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.fuel_surcharge)}
                                        </Typography>
                                    </Grid>
                                    {(+selectTransactionData?.signature_charge) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Signature Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.signature_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.dangerous_charge) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Dangerous Good Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.dangerous_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.residential_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Residential Surcharge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.residential_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.insurance_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Insurance Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.insurance_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.over_size_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Oversize Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.over_size_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.over_weight_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Overweight Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.over_weight_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.sur_charge) > 0 &&
                                        <>
                                            < Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Other Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.sur_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                textAlign: 'left'
                                            }}>
                                            Total Shipping Charge
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '14px'
                                        }}>
                                            {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.total_shipping_charge)}
                                        </Typography>
                                    </Grid>
                                    {(+selectTransactionData?.discount) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Brokerage Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    ({costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.discount)})
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                textAlign: 'left'
                                            }}>
                                            Taxes
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '14px'
                                        }}>
                                            {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.total_tax)}
                                        </Typography>
                                    </Grid>
                                    {(+selectTransactionData?.total_brokerage_charge) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Brokerage Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.total_brokerage_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    {(+selectTransactionData?.total_duties_taxes_charge) > 0 &&
                                        <>
                                            <Grid item xs={7}>
                                                <Typography
                                                    style={{
                                                        fontSize: '14px',
                                                        textAlign: 'left'
                                                    }}>
                                                    Duty Taxes Charge
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Typography style={{
                                                    // color: '#1D8B45',
                                                    textAlign: 'left',
                                                    fontSize: '14px'
                                                }}>
                                                    {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.total_duties_taxes_charge)}
                                                </Typography>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Typography
                                            style={{
                                                fontSize: '14px',
                                                textAlign: 'left'
                                            }}>
                                            Total
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography style={{
                                            // color: '#1D8B45',
                                            textAlign: 'left',
                                            fontSize: '14px'
                                        }}>
                                            {costWithCanadianCurrencyCode(selectTransactionData?.send_from_country, selectTransactionData?.send_to_country, +selectTransactionData?.final_total)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={3} md={5} xs={12}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    height: '100%'
                                }}>
                                    <Grid container spacing={1}>
                                        {
                                            +selectTransactionData?.is_return_label === 0 &&
                                            <Grid item xs={12}>
                                                <Button
                                                    variant='outlined'
                                                    sx={{
                                                        border: '2px solid #1D8B45',
                                                        "&:hover": {
                                                            border: '2px solid #1D8B45',
                                                            filter: 'brightness(0.9)'
                                                        },
                                                        display: 'flex',
                                                        gap: '5px',
                                                        width: '100%'
                                                    }}
                                                    onClick={handleCreateReturnLabel}
                                                >
                                                    <ReturnLabelIcon width='20' height='20' fill='#1D8B45'/>
                                                    <Typography style={{
                                                        textTransform: 'none',
                                                        fontSize: '14px',
                                                        color: '#1D8B45',
                                                    }}>
                                                        Create Return Label
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                        }
                                        {/*{*/}
                                        {/*    +selectTransactionData?.is_return_label === 0 &&*/}
                                        {/*    <Grid item xs={12}>*/}
                                        {/*        <Button*/}
                                        {/*            variant='outlined'*/}
                                        {/*            sx={{*/}
                                        {/*                border: '2px solid #1D8B45',*/}
                                        {/*                "&:hover": {*/}
                                        {/*                    border: '2px solid #1D8B45',*/}
                                        {/*                    filter: 'brightness(0.9)'*/}
                                        {/*                },*/}
                                        {/*                width: '100%',*/}
                                        {/*                display: 'flex',*/}
                                        {/*                gap: '5px'*/}
                                        {/*            }}*/}
                                        {/*            // onClick={handleCreateCloneLabel}*/}
                                        {/*        >*/}
                                        {/*            <CloneLabelIcon width='20' height='20' fill='#1D8B45'/>*/}
                                        {/*            <Typography style={{*/}
                                        {/*                textTransform: 'none',*/}
                                        {/*                fontSize: '14px',*/}
                                        {/*                color: '#1D8B45',*/}
                                        {/*            }}>*/}
                                        {/*                Clone Label*/}
                                        {/*            </Typography>*/}
                                        {/*        </Button>*/}
                                        {/*    </Grid>*/}
                                        {/*}*/}
                                        <Grid item xs={12}>
                                            <Button
                                                variant='outlined'
                                                sx={{
                                                    border: '2px solid #1D8B45',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    },
                                                    display: 'flex',
                                                    gap: '5px',
                                                    width: '100%'
                                                }}
                                                onClick={handleTrackingInfo}
                                            >
                                                <TrackLabelIcon width='20' height='20' fill='#1D8B45'/>
                                                <Typography style={{
                                                    textTransform: 'none',
                                                    fontSize: '14px',
                                                    color: '#1D8B45',
                                                }}>
                                                    Tracking Information
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                variant='outlined'
                                                sx={{
                                                    border: '2px solid #1D8B45',
                                                    "&:hover": {
                                                        border: '2px solid #1D8B45',
                                                        filter: 'brightness(0.9)'
                                                    },
                                                    display: 'flex',
                                                    gap: '5px',
                                                    width: '100%',
                                                }}
                                            >
                                                <PrintLabelIcon width='20' height='20' fill='#1D8B45'/>
                                                <Link
                                                    href={getLabelURL(`${LABEL_DOWNLOAD_URL_PREFIX}${selectTransactionData?.label_pdf_uri}`, `${LABEL_DOWNLOAD_URL_PREFIX}${selectTransactionData?.label_zip_uri}`)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    underline="none"
                                                >
                                                    <Typography style={{
                                                        textTransform: 'none',
                                                        fontSize: '14px',
                                                        color: '#1D8B45',
                                                    }}>
                                                        Print Label
                                                    </Typography>
                                                </Link>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}