import {Button, Container, Snackbar, Step, StepButton, StepLabel, Stepper} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import {getAccessToken} from "../../../Utils/doToken";
import axios from "axios";
import {NODE_ROUTE_URI} from "../../../Utils/apiUrl";
import {Fragment, useEffect, useState} from "react";
import {useInterval} from "usehooks-ts";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Divider from "@mui/material/Divider";
import {costWithCanadianCurrencyCode, mapCarrierLogo} from "../../../Utils/Helper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import CurrencyFormat from "react-currency-format";
import {ShippingOrderDetailsPayment} from "./ShippingOrderDetailsPayment";
import {SchedulePickup} from "../../SchedulePickup/NewDesign/SchedulePickup";
import CircularProgress from "@mui/material/CircularProgress";
import {InternationalSteps, QontoConnector, QontoStepIcon, steps} from "../../../Utils/CustomComponent";
import {ReactComponent as ShipmentOverviewIcon} from "../../../images/NewDesignIcon/ShipmentOverview.svg";
import {ReactComponent as ShippingFromAndToIcon} from "../../../images/NewDesignIcon/ShippingLocation.svg";
import {ReactComponent as PackageDetailIcon} from "../../../images/NewDesignIcon/Package.svg";
import {ReactComponent as PackageTypeIcon} from "../../../images/NewDesignIcon/AdditionalDetails.svg";
import {ReactComponent as SchedulePickupIcon} from "../../../images/NewDesignIcon/SchedulePickup.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import dayjs from "dayjs";
import {ReactComponent as RateDetailsIcon} from "../../../images/NewDesignIcon/RateDetails.svg";
import {updateShippingPackageSchedulePickupRate} from "../../../actions/shippingPackageAction";
import {greenButtonContained, greyButtonContained, sectionHeading} from "../../../Utils/styleConfig";
import MuiAlert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import {ShippingOrderDetailsBoundCreditCard} from "./ShippingOrderDetailsBoundCreditCard";
import {PackageShipmentSuccess} from "../../SuccessShipment/PackageShipmentSuccess";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const styles = {
    ShippingPackageCreateLabelOrderDetailsRoot: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0'
    },
    ShippingPackageCreateLabelOrderDetailsHeading: {
        fontSize: '20px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsSubHeading: {
        fontSize: '18px',
        fontWeight: '600',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTitle: {
        fontSize: '16px',
        textAlign: 'center'
    },
    ShippingPackageCreateLabelOrderDetailsTextLeft: {
        fontSize: '16px',
        textAlign: 'left'
    },
    ShippingPackageCreateLabelOrderDetailsTextRight: {
        fontSize: '16px',
        textAlign: 'right'
    },
    ShippingPackageCreateLabelOrderDetailsTitleRight: {
        fontSize: '18px',
        textAlign: 'right',
        fontWeight: '600'
    },
    ShippingPackageCreateLabelOrderDetailsSection: {
        backgroundColor: '#FFFFFF',
        padding: '20px',
        marginBottom: '40px',
        borderRadius: '10px',
        // boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
        border: '1px solid #A9A9A9',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsSectionPrice: {
        // backgroundColor: '#FFFFFF',
        padding: '20px',
        // borderRadius: '10px 10px 0 0',
        // border: '2px solid #609966',
        gap: '20px',
        display: 'flex',
        flexDirection: 'column',
    },
    ShippingPackageCreateLabelOrderDetailsOtherBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxRight: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsOtherBoxCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    ShippingPackageCreateLabelOrderDetailsImageBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '20px',
        gap: '10px'
    },
}

export const ShippingOrderDetails = () => {

    const storedToken = getAccessToken("access_token");

    const accessToken = getAccessToken("access_token");

    const history = useHistory();

    const {orderId} = useParams();
    // Shipping Order Details
    const [shippingOrderInfo, setShippingOrderInfo] = useState({
        transactions: []
    });

    const [creditCardList, setCreditCardList] = useState([]);
    const [creditCardLoading, setCreditCardLoading] = useState(false);
    const [creditCardError, setCreditCardError] = useState(false);
    const [backendOrderStatus, setBackendOrderStatus] = useState(-1);
    const [delay, setDelay] = useState(2000);
    const [orderDetails, setOrderDetails] = useState(null);
    const [finalTotalAmount, setFinalTotalAmount] = useState(0);
    const [shippingFee, setShippingFee] = useState(0);
    const [serviceFeeTax, setServiceFeeTax] = useState(0);
    const [crossBorderFee, setCrossBorderFee] = useState(0);
    const [packingDetails, setPackingDetails] = useState(null);
    const [pickupInformation, setPickupInformation] = useState(null);
    const [pickupRate, setPickupRate] = useState(null);
    const [isScheduleError, setIsScheduleError] = useState(false);
    const [isSchedule, setIsSchedule] = useState(false);
    const [scheduleLoading, setScheduleLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [isLoadingCancelOrder, setIsLoadingCancelOrder] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loadingSuccess, setLoadingSuccess] = useState(true);

    const handleToastClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setToastOpen(false);
    };

    const handleSuccessMessage = () => {
        setToastOpen(true);
    }

    const handleErrorMessage = () => {
        setToastOpen(true);
    }

    const getValidCardList = async () => {
        const requestURL = `${NODE_ROUTE_URI}/stripe/validCreditCardList`;
        setCreditCardLoading(true);
        try {
            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {Authorization: `Bearer ${storedToken}`},
            })
            setCreditCardList(result?.data);
            setCreditCardError(false);
        } catch (e) {
            console.log('error', e.response);
            setCreditCardError(true);
        } finally {
            setCreditCardLoading(false);
        }
    }

    const getOrderStatus = async () => {
        try {
            const {data} = await axios.get(`${NODE_ROUTE_URI}/looseItem/getBackendOrderStatusByOrderId/${orderId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log(data);
            setBackendOrderStatus(data.orderStatus);
        } catch (e) {
            setBackendOrderStatus(0);
            console.log(e);
        }
    }

    const getShippingOrderInfo = async () => {
        try {
            setLoadingSuccess(true);
            const {data} = await axios.get(`${NODE_ROUTE_URI}/looseItem/getShippingOrderInfoByBackendOrderId/${orderId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            console.log("shipping order info: ", data);
            const result = data.result;
            if (!result) {
                setShippingOrderInfo({
                    transactions: []
                })
            } else {
                setShippingOrderInfo(result);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoadingSuccess(false);
        }
    }

    const getPickupWithBackendOrderId = async () => {
        const requestURL = `${NODE_ROUTE_URI}/packagesPickupAppointment/getInitPickupAppointment`

        try {
            setLoadingSuccess(true);

            const result = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: orderId
                }
            })
            console.log("getPickupWithBackendOrderId result: ", result);
            setPickupInformation(result?.data);
        } catch (e) {
            console.log(e?.response);
            setPickupInformation(e?.response?.data)
        } finally {
            setPageLoading(false);
            setLoadingSuccess(false);
        }
    }

    const getSchedulePickupRate = async () => {
        try {
            let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/getSchedulePickupRate`;
            const pickupDate = pickupInformation?.appoint_date?.replace(/-/g, '');
            const pickupReadyTime = pickupInformation?.appoint_time_start?.replace(/:/g, '').substring(2);
            const pickupCloseTime = pickupInformation?.appoint_time_end?.replace(/:/g, '').substring(2);

            let data = {
                pickupDateInfo: {
                    pickupDate: pickupDate,
                    readyTime: pickupReadyTime,
                    closeTime: pickupCloseTime
                },
                pickupAddress: {
                    address: pickupInformation?.pickup_addr,
                    city: pickupInformation?.pickup_city,
                    province: pickupInformation?.pickup_province,
                    postalCode: pickupInformation?.pickup_postal_code,
                    country: pickupInformation?.pickup_country,
                    isResidential: pickupInformation?.isResidential || false,
                },
                accountBase: pickupInformation?.account_base,
                serviceType: pickupInformation?.serviceType
            }

            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: data
            })
            console.log('[ShippingOrderDetails] getSchedulePickupRate api - result', result);
            setPickupRate(result?.data);
        } catch (e) {
            console.log('error', e);
        }
    }

    const schedulePickupAppointmentByBackendOrderId = async () => {
        setScheduleLoading(true);
        let requestURI = `${NODE_ROUTE_URI}/packagesPickupAppointment/schedulePickupAppointmentByBackendOrderId`;
        try {
            const result = await axios({
                method: 'post',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                data: {
                    backendOrderId: orderId
                }
            })
            console.log('[ShippingOrderDetails] schedulePickupAppointmentByBackendOrderId api - result', result);
            setIsScheduleError(false);
        } catch (e) {
            console.log('error', e.response);
            setIsScheduleError(true);
        } finally {
            setScheduleLoading(false);
        }
    }

    const handleRefund = async () => {
        setIsLoadingCancelOrder(true);
        let requestURL = `${NODE_ROUTE_URI}/looseItem/cancelBackendOrderByOrderId`;
        try {
            await axios({
                method: 'delete',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
                params: {
                    backendOrderId: orderId
                }
            })
            console.log('success');
            setErrorMessage('');
            handleSuccessMessage();
            setSuccessMessage(`Your order has been successfully canceled, and a refund has been processed.`);
            setIsLoadingCancelOrder(false);
            setTimeout(() => getOrderStatus(), 2000);
        } catch (e) {
            setErrorMessage(`Order cancellation failed. Please try again or contact support for assistance`);
            handleErrorMessage();
            setIsLoadingCancelOrder(false);
        }
    }

    useEffect(() => {
        (async () => {
            await getValidCardList();
        })()
    }, [])

    useEffect(() => {
        if (orderId) {
            (async () => {
                const {data} = await axios.get(`${NODE_ROUTE_URI}/looseItem/getBackendOrderWithPackagesByOrderId/${orderId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                // console.log("Order Details: ", data);
                setOrderDetails(data);
                const {prices: {taxes, final_total, service_fee, shipping_fee, residential_charge, total}} = data
                setFinalTotalAmount(final_total);
                setShippingFee(+shipping_fee + +service_fee + +residential_charge);
                setServiceFeeTax(taxes);
                setBackendOrderStatus(data?.status_id);
                setCrossBorderFee(+final_total - +total);
            })();

            (async () => {
                const {data} = await axios.get(`${NODE_ROUTE_URI}/packingList/getPackingListAndPackingListDetailByBackendOrderId?backendOrderId=${orderId}&currency=CAD`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
                console.log('product', data);
                setPackingDetails(data);
            })();
            (async () => {
                await getPickupWithBackendOrderId();
            })();
        }
    }, [orderId])

    useInterval(
        () => {
            if (orderId) {
                getOrderStatus();
            }
        },
        // Delay in milliseconds or null to stop it
        (backendOrderStatus === 0 || backendOrderStatus === 100) ? delay : null,
    )

    /// status === 2

    useEffect(() => {
        if (backendOrderStatus === 1) {
            (async () => {
                await getShippingOrderInfo();
                if (!pickupInformation?.message) {
                    await schedulePickupAppointmentByBackendOrderId();
                }
            })()
        }
    }, [backendOrderStatus]);

    useEffect(() => {
        if (pickupInformation) {
            getSchedulePickupRate();
        }
    }, [pickupInformation]);

    if (+backendOrderStatus === 1) {
        return (
            <PackageShipmentSuccess
                orderDetails={orderDetails}
                shippingOrderInfo={shippingOrderInfo}
                pickupInformation={pickupInformation}
                loadingSuccess={loadingSuccess}
            />
        )
    }

    console.log("[ShippingOrderDetails] Order Details: ", orderDetails);
    console.log('[ShippingOrderDetails] Pickup Information: ', pickupInformation);
    console.log("[ShippingOrderDetails] shippingOrderInfo", shippingOrderInfo);
    // console.log('[ShippingOrderDetails] Pickup Information pickup time: ', pickupInformation?.earliestPickupTime?.format('HHmm'));

    return (
        <Container maxWidth='xl'>
            <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={toastOpen}
                onClose={handleToastClose}
                autoHideDuration={6000}
                message="Submit Transaction"
            >
                {(() => {
                    if (errorMessage !== "") {
                        return (
                            <Alert
                                onClose={handleToastClose}
                                severity="error"
                                sx={{width: "100%"}}
                            >
                                Error!
                                <hr/>
                                {errorMessage}
                            </Alert>
                        );
                    }
                    return (
                        <Alert
                            onClose={handleToastClose}
                            severity="success"
                            sx={{width: "100%"}}
                        >
                            {successMessage}
                        </Alert>
                    );
                })()}
            </Snackbar>
            <Stepper alternativeLabel
                     activeStep={backendOrderStatus === 1 ? 5 : orderDetails?.send_from_country === orderDetails?.send_to_country ? 3 : 4}
                     connector={<QontoConnector/>}>
                {
                    (orderDetails?.send_from_country === orderDetails?.send_to_country ? steps : InternationalSteps).map((label, index) => (
                        <Step key={label}>
                            {/*<StepButton>*/}
                            <StepLabel StepIconComponent={QontoStepIcon}>
                                <Typography sx={{color: index <= 5 ? '#1D8B45' : '#C7C7C7', fontSize: '14px'}}>
                                    {label}
                                </Typography>
                            </StepLabel>
                            {/*</StepButton>*/}
                        </Step>
                    ))
                }
            </Stepper>
            <Box sx={styles.ShippingPackageCreateLabelOrderDetailsRoot}>
                <Box sx={{
                    display: 'flex',
                    gap: '10px',
                    padding: '20px 20px 0 20px'
                }}>
                    <ShipmentOverviewIcon width={25} height={25}/>
                    <Typography style={styles.ShippingPackageCreateLabelOrderDetailsSubHeading}>
                        Place Order
                    </Typography>
                </Box>
                <Divider/>
                {
                    pageLoading ?
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 40px',
                            gap: '40px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <CircularProgress/>
                        </Box>
                        :
                        <Box sx={{
                            backgroundColor: '#FFFFFF',
                            padding: '0 40px',
                            gap: '40px',
                            display: 'flex',
                        }}>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ShippingFromAndToIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Shipping From
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {orderDetails?.pickup_company || orderDetails?.sender}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {orderDetails?.pickup_address_line2 && `${orderDetails?.pickup_address_line2} - `}{orderDetails?.pickup_address}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {orderDetails?.pickup_area}, {orderDetails?.send_from_country}, {orderDetails?.pickup_postal_code}
                                        </Typography>
                                        {
                                            orderDetails?.pickup_email &&
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderDetails?.pickup_email}
                                            </Typography>
                                        }
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {orderDetails?.pickup_phone} {orderDetails?.pickup_phone_ext && `ext. ${orderDetails?.pickup_phone_ext}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <ShippingFromAndToIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Shipping To
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {orderDetails?.deliver_company || orderDetails?.deliver_name}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {orderDetails?.deliver_address_line2 && `${orderDetails?.deliver_address_line2} - `}{orderDetails?.deliver_address}
                                        </Typography>
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {orderDetails?.deliver_area}, {orderDetails?.send_to_country}, {orderDetails?.deliver_postal_code}
                                        </Typography>
                                        {
                                            orderDetails?.deliver_email &&
                                            <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                {orderDetails?.deliver_email}
                                            </Typography>
                                        }
                                        <Typography style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                            {orderDetails?.deliver_phone} {orderDetails?.deliver_phone_ext && `ext. ${orderDetails?.deliver_phone_ext}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <PackageDetailIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Package Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        orderDetails?.packages?.length > 0 && orderDetails?.packages?.map((each, index) => (
                                            <Fragment key={index}>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {`#${index + 1}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {each?.length} x {each?.width} x {each?.height} {each?.dimension_unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextLeft}>
                                                        {each?.weight} {each?.weight_unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                        x {each?.number}
                                                    </Typography>
                                                </Grid>
                                            </Fragment>
                                        ))
                                    }
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <PackageTypeIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Additional Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        orderDetails?.packages?.[0]?.signature_option &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Include signature options
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        orderDetails?.packages?.[0]?.battery_material &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Include lithium batteries
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        orderDetails?.is_return_label === 1 &&
                                        <Grid item xs={12}>
                                            <Typography>
                                                Return Label
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Typography>
                                            Reference Order Number: {orderDetails?.ref_order_no || 'N/A'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{display: 'flex', width: '100%', flexDirection: 'column', gap: '60px'}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <SchedulePickupIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Schedule Pickup
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    {
                                        pickupInformation?.message ?
                                            <Grid item xs={12}>
                                                <Typography>
                                                    Schedule pickup later
                                                </Typography>
                                            </Grid>
                                            :
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        <CheckCircleIcon sx={{color: '#1D8B45'}}/> Pickup Schedule
                                                        on {dayjs(pickupInformation?.appoint_date)?.format('MMMM D, YYYY')}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        {pickupInformation?.pickup_name}, {pickupInformation?.pickup_tel}, {pickupInformation?.pickup_location},
                                                        From {(pickupInformation?.appoint_time_start?.substring(3))} to {(pickupInformation?.appoint_time_end?.substring(3))}
                                                    </Typography>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={1}>
                                        <RateDetailsIcon width={20} height={20}/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography sx={{fontWeight: '600'}}>
                                            Rate Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <img
                                            src={mapCarrierLogo(orderDetails?.account_base_combine_name?.split('-')[0]?.toUpperCase())}
                                            alt="logo"
                                            width='60px'/>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6}>
                                                <Typography>
                                                    {orderDetails?.account_base_combine_name?.split('-')[0]?.toUpperCase()}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, (+orderDetails?.prices?.final_total + (+pickupRate?.totalChargeWithTaxes || 0)))}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Shipping Fee
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                        {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, +orderDetails?.prices?.shipping_fee)}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            {
                                                +pickupRate?.totalCharge > 0 &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Pickup Fee
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            <Typography
                                                                style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                                {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, +pickupRate?.totalCharge)}
                                                            </Typography>
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Subtotal
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    <Typography
                                                        style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                        {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, ((+pickupRate?.totalCharge || 0) + +orderDetails?.prices?.shipping_fee))}
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    Taxes
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                    {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, (+orderDetails?.prices?.taxes + (+pickupRate?.taxCharge || 0)))}
                                                </Typography>
                                            </Grid>
                                            {
                                                (+orderDetails?.prices?.brokerage_fee) > 0 &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Total Brokerage Fee:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, +orderDetails?.prices?.brokerage_fee)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                (+orderDetails?.prices?.duty_tax) > 0 &&
                                                <>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            Total Duty and Tax
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Typography
                                                            style={styles.ShippingPackageCreateLabelOrderDetailsTextRight}>
                                                            {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, +orderDetails?.prices?.duty_tax)}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <Divider/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    Total
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography
                                                    style={styles.ShippingPackageCreateLabelOrderDetailsTitleRight}>
                                                    {costWithCanadianCurrencyCode(orderDetails?.send_from_country, orderDetails?.send_to_country, (+orderDetails?.prices?.final_total + (+pickupRate?.totalChargeWithTaxes || 0)))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                }
            </Box>
            <Box sx={{
                marginBottom: '40px'
            }}>
                {
                    // creditCardList?.length === 0 ? <ShippingOrderDetailsBoundCreditCard getValidCardList={getValidCardList}/> :
                    backendOrderStatus === 0 ?
                        <ShippingOrderDetailsPayment orderDetails={orderDetails}
                                                     orderId={orderId}
                                                     pickupRate={pickupRate}
                                                     creditCardList={creditCardList}
                                                     getValidCardList={getValidCardList}/>
                        :
                        backendOrderStatus === 100 ?
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        fontSize: '20px',
                                        fontWeight: '600',
                                        textAlign: 'center'
                                    }}>
                                        Your payment was successfully processed, and the order is currently
                                        being
                                        processed.
                                    </Typography>
                                </Grid>
                            </Grid>
                            :
                            backendOrderStatus === 1 ?
                                <Grid container spacing={2}>
                                    {
                                        scheduleLoading ?
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                    <CircularProgress/>
                                                </Box>
                                            </Grid>
                                            :
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography sx={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        textAlign: 'center'
                                                    }}>
                                                        Thank you! Your order has been placed.
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography style={{
                                                        fontSize: '20px',
                                                        fontWeight: '600',
                                                        textAlign: 'center'
                                                    }}>
                                                        {
                                                            (orderDetails?.send_from_country !== orderDetails?.send_to_country && pickupInformation?.message) ?
                                                                "The next step is to upload the commercial invoice and schedule a pickup by pressing the respective buttons below." :
                                                                (orderDetails?.send_from_country !== orderDetails?.send_to_country && isScheduleError) ?
                                                                    "The next step is to upload the commercial invoice. UUCargo will handle your pickup request for you. You will receive the confirmation email from op@uucargo.ca." :
                                                                    (orderDetails?.send_from_country !== orderDetails?.send_to_country && !isScheduleError) ?
                                                                        "The next step is to upload the commercial invoice. You have successfully schedule a pickup." :
                                                                        (orderDetails?.send_from_country === orderDetails?.send_to_country && pickupInformation?.message) ?
                                                                            "The next step is to schedule a pickup by pressing the button below." :
                                                                            (orderDetails?.send_from_country === orderDetails?.send_to_country && isScheduleError) ?
                                                                                "UUCargo will handle your pickup request for you. You will receive the confirmation email from op@uucargo.ca." :
                                                                                (orderDetails?.send_from_country === orderDetails?.send_to_country && !isScheduleError) &&
                                                                                "You have successfully schedule a pickup."
                                                        }
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '10px'
                                                    }}>
                                                        {
                                                            orderDetails?.send_from_country !== orderDetails?.send_to_country &&
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    backgroundColor: '#1D8B45',
                                                                    "&:hover": {
                                                                        backgroundColor: '#1D8B45',
                                                                        filter: 'brightness(0.9)'
                                                                    }
                                                                }}
                                                                onClick={() => history.push(`/cross-border-dashboard/${shippingOrderInfo.order_id}`)}
                                                            >
                                                                Upload Commercial Invoice
                                                            </Button>
                                                        }
                                                        {
                                                            pickupInformation?.message &&
                                                            <Button
                                                                variant='contained'
                                                                sx={{
                                                                    backgroundColor: '#1D8B45',
                                                                    "&:hover": {
                                                                        backgroundColor: '#1D8B45',
                                                                        filter: 'brightness(0.9)'
                                                                    }
                                                                }}
                                                                onClick={() => history.push('/schedulepickup')}
                                                            >
                                                                Schedule Pickup
                                                            </Button>
                                                        }
                                                    </Box>
                                                    <Grid item xs={12}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: '10px'
                                                        }}>
                                                            <Button
                                                                onClick={() => history.push(`/package-order-dashboard/${shippingOrderInfo.order_id}`)}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        textTransform: 'none',
                                                                        color: '#1D8B45'
                                                                    }}>
                                                                    See Details of Order
                                                                </Typography>
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                                :
                                backendOrderStatus === 2 ?
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography sx={{
                                                fontSize: '20px',
                                                fontWeight: '600',
                                                textAlign: 'center'
                                            }}>
                                                Your payment was successfully processed, but the order was not
                                                generated. You can cancel the order and request a refund by
                                                clicking the
                                                'Refund' button. Please note that the refund may take up to 5
                                                business
                                                days to be completed.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <LoadingButton
                                                    variant='contained'
                                                    sx={greyButtonContained}
                                                    onClick={handleRefund}
                                                    loading={isLoadingCancelOrder}
                                                >
                                                    <Typography sx={{textTransform: 'none'}}>
                                                        Refund
                                                    </Typography>
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    :
                                    backendOrderStatus === 3 ?
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '600',
                                                    textAlign: 'center'
                                                }}>
                                                    The order has been canceled, and the refund is being
                                                    processed.
                                                    Please note that it may take up to 5 business days for the
                                                    refund to
                                                    be completed.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Button
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        onClick={() => history.push(`/home`)}
                                                    >
                                                        <Typography sx={{textTransform: 'none'}}>
                                                            Back To Home Page
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography sx={{
                                                    fontSize: '20px',
                                                    fontWeight: '600',
                                                    textAlign: 'center'
                                                }}>
                                                    The order has been canceled.
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}>
                                                    <Button
                                                        variant='contained'
                                                        sx={{
                                                            backgroundColor: '#1D8B45',
                                                            "&:hover": {
                                                                backgroundColor: '#1D8B45',
                                                                filter: 'brightness(0.9)'
                                                            }
                                                        }}
                                                        onClick={() => history.push(`/home`)}
                                                    >
                                                        <Typography sx={{textTransform: 'none'}}>
                                                            Back To Home Page
                                                        </Typography>
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                }
            </Box>
        </Container>
    )
}