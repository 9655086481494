import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import intl from "react-intl-universal";
import Button from "@mui/material/Button";
import {Divider} from "@mui/material";
import {Fragment, useEffect, useState} from "react";
import {getAccessToken} from "../../../Utils/doToken";
import {PARTNER_URI} from "../../../Utils/apiUrl";
import axios from "axios";
import {triggerBase64Download} from "common-base64-downloader-react";

const style = {
    PackageOrderDashboardCostRoot: {
        border: '1px solid #B2B2B2',
        borderRadius: '10px',
        padding: '10px 0',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
    }
}

export const BusinessPackageOrderDashboardCost = ({orderData}) => {

    const [invoiceDetail, setInvoiceDetail] = useState(null);
    const [invoiceLoading, setInvoiceLoading] = useState(true);

    const [totalDue, setTotalDue] = useState(0);
    const [subTotal, setSubtotal] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const [transactionFee, setTransactionFee] = useState(0);
    const [payAliPayAmount, setPayAliPayAmount] = useState(0);
    const [payCreditAmount, setPayCreditAmount] = useState(0);
    const [payCreditCardAmount, setPayCreditCardAmount] = useState(0);
    const [payWechatAmount, setPayWechatAmount] = useState(0);

    const getInvoiceNumber = async () => {
        setInvoiceLoading(true);
        const storedToken = getAccessToken("access_token");
        const requestURI = `${PARTNER_URI}/billingCenter/getInvoiceInfoByInvoiceId`;
        try {
            const result = await axios({
                method: 'get',
                url: requestURI,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderData?.crm_invoice_no
                }
            })
            console.log('result', result);
            setInvoiceDetail(result?.data);
        } catch (e) {
            console.log('e', e?.response);
        } finally {
            setInvoiceLoading(false);
        }
    }

    const downloadPDFByInvoiceId = async () => {
        try {
            const storedToken = getAccessToken("access_token");
            const requestURL = `${PARTNER_URI}/billingCenter/downloadInvoice`;
            const {data} = await axios({
                method: 'get',
                url: requestURL,
                headers: {
                    Authorization: `Bearer ${storedToken}`,
                },
                params: {
                    invoiceId: orderData?.crm_invoice_no
                }
            })
            const content = data.content;
            const contentType = data["content-type"];
            const pdfContent = `data:${contentType};base64,${content}`;
            const fileName = data.filename?.length > 4 ? data.filename.slice(0, data.filename.length - 4) : data.filename;
            triggerBase64Download(pdfContent, fileName);
        } catch (e) {
            console.log(e.response);
        }
    }

    const handleDownInvoice = () => {
        downloadPDFByInvoiceId();
    }

    useEffect(() => {
        if (orderData?.backendPayments?.length > 0) {

            setTransactionFee(orderData?.creditCardTransactionFee || 0);
            setPayAliPayAmount(orderData?.payAliPayAmount || 0);
            setPayCreditAmount(orderData?.payCreditAmount || 0);
            setPayCreditCardAmount(orderData?.payCreditCardAmount || 0);
            setPayWechatAmount(orderData?.payWechatAmount || 0);

            const subTotal = +orderData?.final_total - +orderData?.payCreditAmount;
            const totalAmount = subTotal + orderData?.creditCardTransactionFee;
            const totalDue = +subTotal - +orderData?.payAliPayAmount - +orderData?.payCreditCardAmount - +orderData?.payWechatAmount

            setSubtotal(subTotal);
            setTotalAmount(totalAmount);
            setTotalDue(+totalDue <= 0.02 ? 0 : totalDue);
        }

        orderData?.crm_invoice_no ? getInvoiceNumber() : setInvoiceLoading(false);

    }, [orderData]);

    console.log('[BusinessPackageOrderDashboardCost] order data', orderData);

    return (
        <Box sx={style.PackageOrderDashboardCostRoot}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        padding: '10px',
                        borderBottom: '1px solid #B2B2B2'
                    }}>
                        <Typography style={{fontSize: '20px', fontWeight: '600'}}>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.SHIPPING_DOCUMENTS')}*/}
                            Payment Details
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Shipping Fee
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'right',
                        paddingRight: '10px'
                    }}>
                        $ {(+orderData?.total_amount_exclude_tax)?.toFixed(2)}
                    </Typography>
                </Grid>
                {
                    +orderData?.service_fee_exclude_tax > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Service Fee
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'right',
                                paddingRight: '10px'
                            }}>
                                $ {(+orderData?.service_fee_exclude_tax)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </>
                }
                {
                    +orderData?.discount > 0 &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Discount
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'right',
                                paddingRight: '10px'
                            }}>
                                ($ {(+orderData?.discount)?.toFixed(2)})
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Taxes
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'right',
                        paddingRight: '10px'
                    }}>
                        $ {(+orderData?.total_tax)?.toFixed(2)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                            Order Price
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        // color: '#1D8B45',
                        textAlign: 'right',
                        paddingRight: '10px'
                    }}>
                        $ {(+orderData?.final_total)?.toFixed(2)}
                    </Typography>
                </Grid>
                {
                    orderData?.backendPayments?.length > 0 &&
                    <>
                        {
                            +payCreditAmount > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            UUCargo Credit
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+payCreditAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            (+subTotal > 0 && +payCreditAmount > 0) &&
                            <>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Subtotal
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        $ {(+subTotal)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +transactionFee > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Transaction Fee
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        $ {(+transactionFee)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Total Amount
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        // color: '#1D8B45',
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        $ {(+totalAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +payCreditCardAmount > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Credit Card
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+payCreditCardAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +transactionFee > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Transaction Fee
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+transactionFee)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +payAliPayAmount > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            Ali Pay
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+payAliPayAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        {
                            +payWechatAmount > 0 &&
                            <>
                                <Grid item xs={7}>
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        justifyContent: 'flex-start',
                                        padding: '0 10px'
                                    }}>
                                        <Typography>
                                            {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                            WeChat Pay
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography style={{
                                        textAlign: 'right',
                                        paddingRight: '10px'
                                    }}>
                                        - $ {(+payWechatAmount)?.toFixed(2)}
                                    </Typography>
                                </Grid>
                            </>
                        }
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {/*{intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.TRANSACTION_DETAILS')}*/}
                                    Total Due
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                // color: '#1D8B45',
                                textAlign: 'right',
                                paddingRight: '10px'
                            }}>
                                $ {(+totalDue)?.toFixed(2)}
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {
                    invoiceDetail &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_NO')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography style={{
                                textTransform: 'none',
                                color: '#B454545',
                            }}>
                                {invoiceDetail?.invoiceRefNumber}
                            </Typography>
                        </Grid>
                    </>
                }
                <Grid item xs={7}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'flex-start',
                        padding: '0 10px'
                    }}>
                        <Typography>
                            {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_STATUS')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <Typography style={{
                        textTransform: 'none',
                        color: !orderData?.crm_invoice_no ? '#454545' : invoiceDetail?.status === "2" ? '#1D8B45' : '#FF0303',
                    }}>
                        {
                            !orderData?.crm_invoice_no ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.NOT_BILLED') : invoiceDetail?.status === "2" ? intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.PAID') : intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.UNPAID')
                        }
                    </Typography>
                </Grid>
                {
                    invoiceDetail &&
                    <>
                        <Grid item xs={7}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '100%',
                                justifyContent: 'flex-start',
                                padding: '0 10px'
                            }}>
                                <Typography>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.INVOICE_DETAILS')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Button
                                variant='outlined'
                                sx={{
                                    border: '2px solid #1D8B45',
                                    "&:hover": {
                                        border: '2px solid #1D8B45',
                                        filter: 'brightness(0.9)'
                                    }
                                }}
                                onClick={handleDownInvoice}
                            >
                                <Typography style={{
                                    textTransform: 'none',
                                    fontSize: '14px',
                                    color: '#1D8B45',
                                }}>
                                    {intl.get('ORDER_DASHBOARD.SHIPPING_DOCUMENTS.DOWNLOAD')}
                                </Typography>
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
        </Box>
    )
}